import * as React from "react";
import { DayPicker, DateRange } from "react-day-picker";
import * as moment from "moment";
import { Popover } from "@mui/material";
import Button from "components/Button";
import "react-day-picker/src/style.css";
import "./style.css";
import { clsx } from "clsx";
import { useTranslation } from "react-i18next";

interface IPureDateRangePickerProps {
  defaultStartDate?: number;
  defaultEndDate?: number;
  maxPastDays?: number;
  maxFutureDays?: number;
  placeholderText?: string;
  className?: string;
  onBlur: ({ startDate, endDate }: { startDate: number; endDate: number }) => void;
  selectRange?: number;
  maxDay?: number;
}

export function DateRangePicker({
  defaultStartDate,
  defaultEndDate,
  maxPastDays = 365,
  maxFutureDays = 0,
  placeholderText,
  className,
  maxDay,
  onBlur,
  selectRange,
}: IPureDateRangePickerProps): JSX.Element {
  const { t } = useTranslation("translation", {
    keyPrefix: "components.pure_date_range_picker"
  });
  const initialStart = defaultStartDate ? moment(defaultStartDate).toDate() : null;
  const initialEnd = defaultEndDate ? moment(defaultEndDate).toDate() : null;
  const [range, setRange] = React.useState<DateRange>({ from: initialStart, to: initialEnd });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDateChange = (updatedRange: DateRange) => {
    setRange(updatedRange);

    const startDate = updatedRange.from ? moment(updatedRange.from) : null;
    const endDate = updatedRange.to ? moment(updatedRange.to) : null;

    // Adjust endDate based on selectRange if needed
    if (selectRange && startDate && endDate) {
      const dateDifference = endDate.diff(startDate, "days");
      if (dateDifference >= selectRange) {
        const adjustedStart = moment(endDate).subtract(selectRange, "days").toDate();
        setRange({ from: adjustedStart, to: updatedRange.to });
      }
    }

    // Trigger onBlur after selecting both dates
    if (startDate && endDate) {
      onBlur({
        startDate: startDate.startOf("day").valueOf(),
        endDate: endDate.endOf("day").valueOf(),
      });
    }
  };

  const disabledDays = [
    { before: moment().subtract(maxPastDays, "days").toDate() },
    { after: moment().add(maxFutureDays, "days").toDate() },
  ];

  const isOpen = Boolean(anchorEl);

  return (
    <div className={clsx(className)}>
      <Button className="w-full !text-on-surface-medium" onClick={handleOpen} type="secondary" variant="outlined" size="large">
        {range?.from && range?.to
          ? <div className="flex gap-3 text-on-surface-medium">
            <span>{moment(range.from).format("MM/DD/YYYY")}</span> - <span>{moment(range.to).format("MM/DD/YYYY")}</span>
          </div>
          : <span className="text-on-surface-medium">{placeholderText || t("label_select_date_range")}</span> }
      </Button>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{
          marginTop: "4px"
        }}
      >
        <div className="p-4 text-body2">
          <DayPicker
            mode="range"
            selected={range}
            onSelect={handleDateChange}
            disabled={disabledDays}
            numberOfMonths={2}
            max={maxDay}
            styles={{
              
            }}
          />
        </div>
      </Popover>
    </div>
  );
}
