import * as moment from "moment";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { PropsRange } from "react-day-picker";
import { DatePicker } from "./DatePicker";
import { DateAccordion } from "./DateAccordion";

export function Timeline() {
  const { t } = useTranslation("translation", { keyPrefix: "timeline" });
  const [date, setDate] = React.useState<PropsRange["selected"]>({
    from: moment().subtract(2, "days").toDate(),
    to: new Date(),
  });
  const renderedDates = getDatesInbetween(date);

  return (
    <div className="min-h-0 flex-1 overflow-auto [scrollbar-gutter:stable] -mx-[20px] px-4 md:-mx-[35px] -my-[20px] md:px-[35px] py-[20px] basis-0">
      <div className="font-kanit">
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-h5 leading-6">{t("timeline")}</h1>
            <p className="text-caption leading-4 text-on-surface-medium hidden md:block pr-3">
              {t("description")}
            </p>
          </div>

          <div className="flex-shrink-0">
            <DatePicker selected={date} onSelect={setDate} />
          </div>
        </div>

        <div className="mt-6 md:mt-7" key={JSON.stringify(date)}>
          {renderedDates.map((date, index) => (
            <DateAccordion date={date} key={date} defaultOpen={index === 0} />
          ))}
        </div>
      </div>
    </div>
  );
}

function getDatesInbetween(date: PropsRange["selected"]) {
  const inclusiveDates = [];
  let to = moment(date.to);
  while (to.isSameOrAfter(date.from)) {
    inclusiveDates.push(to.toDate());
    to = to.subtract(1, "days");
  }
  return inclusiveDates;
}


