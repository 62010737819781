import * as React from 'react';

import { Card } from '../atoms/Card';
import { CardTitle } from '../molecules/CardTitle';

import { ErrorFallBack } from '../atoms/ErrorFallback';
import { Skeleton } from '@mui/material';

export interface TextCardProps {
  title: string;
  icon: string;
  isError?: boolean;
  isLoading?: boolean;
  className?: string;
  labelType: string;
  valueType: string;
  data: Array<{
    label: string;
    value: string;
  }>;
}

function TextCard({
  title,
  icon,
  labelType,
  valueType,
  data,
  className = '',
  isError,
  isLoading,
}: TextCardProps): JSX.Element {
  return (
    <Card className={className}>
      <CardTitle
        icon={icon}
        title={title}
        className={isError ? '!text-on-surface-disabled' : undefined}
      />
      {isLoading ? (
        <Skeleton
          height={160}
          className="mt-auto"
          style={{ transform: 'scale(1,1)' }}
        />
      ) : isError ? (
        <ErrorFallBack />
      ) : (
        <>
          <div className="grid [grid-template-columns:auto_1fr] gap-x-14 max-w-full mt-[15px]">
            <div className="font-kanit text-overline tracking-[0.25px] font-light text-[#3E3E3C] whitespace-nowrap overflow-hidden text-ellipsis max-w-32 mb-1">
              {labelType}
            </div>
            <div className="font-kanit text-overline tracking-[0.25px] font-light text-[#3E3E3C] whitespace-nowrap">
              {valueType}
            </div>
            {data.slice(0, 6).map((dataPoint) => (
              <>
                <div className="text-body2 whitespace-nowrap font-bold overflow-hidden text-ellipsis max-w-32 mb-1">
                  {dataPoint.label}
                </div>
                <div className="text-body2 whitespace-nowrap">
                  {dataPoint.value}
                </div>
              </>
            ))}
          </div>
        </>
      )}
    </Card>
  );
}

export { TextCard };
