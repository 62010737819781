import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ContractIndex from "./screens/ContractIndex";
import NewContract from "./screens/NewContract";
import ContractId from "./screens/ContractId";
import EditContract from "./screens/EditContract";
import AddLocationToContract from "./screens/AddLocationToContract";
import LocationFee from "./screens/LocationFee";
import EditLocationFee from "./screens/EditLocationFee";
import { ErrorBoundary } from "@sentry/react";
import { Fallback } from "components/Fallback";

export function Contract() {
  return (
    <>
      <div
        style={{
          margin: "-20px -35px",
          flex: "1 1 0",
          minHeight: 0,
        }}
      >
        <Routes>
          <Route
            path="/"
            element={
              <ErrorBoundary fallback={Fallback} key="view-contracts">
                <ContractIndex />
              </ErrorBoundary>
            }
          />

          <Route
            path="/new"
            element={
              <ErrorBoundary fallback={Fallback} key="create-contract">
                <NewContract />
              </ErrorBoundary>
            }
          />

          <Route
            path="/:contractId"
            element={
              <ErrorBoundary fallback={Fallback} key="view-contract">
                <ContractId />
              </ErrorBoundary>
            }
          />

          <Route
            path="/:contractId/edit"
            element={
              <ErrorBoundary fallback={Fallback} key="edit-contract">
                <EditContract />
              </ErrorBoundary>
            }
          />

          <Route
            path="/:contractId/location/add"
            element={
              <ErrorBoundary fallback={Fallback} key="add-new-location">
                <AddLocationToContract />
              </ErrorBoundary>
            }
          />

          <Route
            path="/:contractId/location/:locationId"
            element={
              <ErrorBoundary fallback={Fallback} key="view-location-fee">
                <LocationFee />
              </ErrorBoundary>
            }
          />

          <Route
            path="/:contractId/location/:locationId/edit"
            element={
              <ErrorBoundary fallback={Fallback} key="edit-location-fee">
                <EditLocationFee />
              </ErrorBoundary>
            }
          />

          <Route path="*" element={<Navigate to="/contract" />} />
        </Routes>
      </div>
    </>
  );
}
