import * as React from "react";
import * as moment from "moment";
import { Dialog, DialogContent } from "@mui/material";
import Icon from "common/components/icon/Icon";
import { useTranslation } from "react-i18next";
import { EstimateTimeText } from "./RemainingCell";
import { clsx } from "clsx";
import { useResponsive } from "hooks/useResponsive";

type ConsumptionForecastModalProps = {
  open: boolean;
  onClose: () => void;
  name: string;
  currentLevel: number;
  refillAmount: number;
  capacity: number;
  uom: string;
  tier: number;
  consumptionForecast: {
    days: number;
    hours: number;
    weekdayUsage: number;
    weekendUsage: number;
  };
};

export function ConsumptionForecastModal({
  tier,
  open,
  onClose,
  name,
  currentLevel,
  consumptionForecast,
  uom,
}: ConsumptionForecastModalProps) {
  const { isMobile } = useResponsive();
  const { t } = useTranslation("operation", {
    keyPrefix: "kitting.modal.kitting_table",
  });
  const daysConsumption = useGenerateDaysArray({
    weekdayUsage: consumptionForecast.weekdayUsage,
    weekendUsage: consumptionForecast.weekendUsage,
  });
  const maxConsumption = Math.max(
    ...daysConsumption.map((consumption) => consumption.amount, 20)
  );

  const IsNoConsumption =
    consumptionForecast.weekdayUsage === 0 &&
    consumptionForecast.weekendUsage === 0;
  return (
    <Dialog
      className="font-kanit flex items-center justify-center fixed !outline-none !outline-0"
      open={open}
      sx={{
        flexShrink: 0,
      }}
      onClose={onClose}
      fullWidth={isMobile}
      fullScreen={isMobile}
    >
      <DialogContent
        id="more-filter"
        className="w-screen h-screen md:h-min md:w-full md:max-h-[calc(100vh-6rem)] relative py-3 px-4 overflow-x-auto max-w-full min-h-full box-border flex flex-col rounded-md shadow-sm bg-white"
      >
        <div onClick={onClose}>
          <Icon
            name="Close"
            className="cursor-pointer w-6 h-6 absolute right-4 top-3 opacity-80 hover:opacity-100"
            color="onSurfaceHigh"
          />
        </div>
        <div className="pb-2.5 border-b border-outline">
          <p className="text-primary-600 text-body3 font-medium">{name}</p>
          <p className="text-on-surface-high font-medium">
            <span className="inline-block text-overline w-[140px]">
              {t("label_current_level")}
            </span>
            <span className="font-medium text-body3">
              {currentLevel} {uom}
            </span>
          </p>
          <p className="text-on-surface-high font-medium">
            <span className="inline-block text-overline w-[140px]">
              {t("label_estimate_run_out_time")}
            </span>
            <EstimateTimeText
              className="font-medium text-body3"
              days={consumptionForecast.days}
              hours={consumptionForecast.hours}
              refillFrequencyDays={tier}
              IsNoConsumption={IsNoConsumption}
            />
          </p>
        </div>
        <div className="mt-2.5">
          <p className="text-overline text-primary-600 mb-1">
            {t("label_usage_unit")} ({uom})
          </p>
          <div className="flex overflow-x-auto items-end gap-1.5">
            {daysConsumption.map((dayConsumption) => {
              const barHeight =
                (dayConsumption.amount / maxConsumption) * 50 || 2;
              const dayConsumptionFormattedValue =
                dayConsumption.amount.toFixed(0);
              return (
                <div
                  key={dayConsumption.day}
                  className={clsx("w-9 text-overline text-center")}
                >
                  <div className="h-[50px] flex items-end">
                    <div
                      style={{ height: barHeight }}
                      className={clsx(
                        "w-9 mb-1 relative flex justify-center items-center",
                        dayConsumption.isWeekend
                          ? "bg-primary text-white"
                          : "bg-primary-50 text-primary-600"
                      )}
                    >
                      {barHeight >= 16 && dayConsumptionFormattedValue}
                      {barHeight < 16 && (
                        <div className="absolute text-center top-[-20px] left-1/2 ml-[-18px] w-9 text-primary-600">
                          {dayConsumptionFormattedValue}
                        </div>
                      )}
                    </div>
                  </div>
                  <p className="text-overline">
                    {dayConsumption.day.split(" ")[0]}
                  </p>
                  <p className="text-overline">
                    {dayConsumption.day.split(" ")[1]}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const useGenerateDaysArray = ({
  weekdayUsage,
  weekendUsage,
}: {
  weekdayUsage: number;
  weekendUsage: number;
}) => {
  return Array.from({ length: 13 }, (_, index) => {
    const day = moment().add(index, "days");
    const isWeekend = day.isoWeekday() === 6 || day.isoWeekday() === 7;
    return {
      day: day.format("MMM DD"),
      amount: isWeekend ? weekendUsage : weekdayUsage,
      isWeekend,
    };
  });
};
