import * as React from "react";
import { TextCard } from "./TextCard";
import { useGetDailyMachineSalesQuery } from "gql/generated";

export function BestSellingMachineCards(): JSX.Element {
  const getDailyMachineSalesResult = useGetDailyMachineSalesQuery(undefined, {
    useErrorBoundary: false,
  });
  return (
    <TextCard
      isError={getDailyMachineSalesResult.isError}
      isLoading={getDailyMachineSalesResult.isLoading}
      data={getDailyMachineSalesResult.data?.dailyMachineSales
        .slice(0, 5)
        .map((machine) => ({
          label: machine.machineId,
          value: machine.totalCount.toLocaleString(),
        }))}
      icon="Machine"
      title="Best-Selling Machines"
      labelType="Machine ID"
      valueType="Total Cup Sales"
    />
  );
}
