import * as React from "react";
import Typography from "components/Typography";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "react-use";
import { useErrorBoard } from "./hooks/useErrorBoard";
import Icon from "common/components/icon/Icon";

import * as styles from "./ErrorOverviews.module.scss";
import { clsx } from "clsx";
import { Skeleton } from "@mui/material";
import { ModuleScoreBreakdownModal } from "./ModuleScoreBreakdownModal";
import { InventoryScoreBreakdownModal } from "./InventoryScoreBreakdownModal";

export function ErrorOverviews() {
  const { t } = useTranslation("errorBoard");
  const [isOpen, setIsOpen] = useLocalStorage("errorBoardErrorOverviews", true);
  const { state } = useErrorBoard();
  const errorOverviews = state.data.errorOverviews;

  const isLoading =
    !errorOverviews &&
    (state.status === "loading" || state.status === "refreshing");

  return (
    <>
      <div className={styles.ErrorOverviews}>
        <div className={styles.HeadingAndButtonContainer}>
          <Typography type="subtitle-2" color="onPrimaryHigh">
            {t("errorOverviews")}
          </Typography>

          <button
            onClick={() => setIsOpen(!isOpen)}
            className={styles.ToggleButton}
          >
            <Icon
              name="DropDownChevron"
              style={{
                transition: "transform 0.2s ease-in-out",
                transform: isOpen ? "rotate(180deg)" : null,
                transformOrigin: "center",
              }}
            />
          </button>
        </div>

        <div
          className={clsx(
            "grid overflow-hidden transition-all ease-in-out duration-300",
            isOpen ? "[grid-template-rows:1fr]" : "[grid-template-rows:0fr]"
          )}
        >
          <div
            className={clsx(
              "min-h-0 w-full min-w-0 transition-all",
              isOpen ? "visible" : "invisible"
            )}
          >
            <div className="pb-3 flex gap-4 overflow-auto px-4 scroll-p-4 snap-x hide-scroll-bar pt-[1px]">
              <ModuleScoreCard />
              <InventoryScoreCard />
              <Card
                title={t("cards.inventoryFail")}
                value={errorOverviews?.inventoryFail.amount ?? 0}
                subValue={(
                  errorOverviews?.inventoryFail.percentage ?? 0
                ).toFixed(2)}
                isLoading={isLoading}
                icon={
                  <Icon
                    name="Cart"
                    className="w-[14px] h-[14px]"
                    color="primary500"
                  />
                }
              />
              <Card
                title={t("cards.softwareHardwareFail")}
                value={errorOverviews?.softwareHardwareFail.amount ?? 0}
                subValue={(
                  errorOverviews?.softwareHardwareFail.percentage ?? 0
                ).toFixed(2)}
                isLoading={isLoading}
                icon={
                  <Icon
                    name="HardwareVersion"
                    className="w-[14px] h-[14px]"
                    color="primary500"
                  />
                }
              />
              <Card
                title={t("cards.drinkFail")}
                value={errorOverviews?.drinkFail.amount ?? 0}
                subValue={(errorOverviews?.drinkFail.percentage ?? 0).toFixed(
                  2
                )}
                isLoading={isLoading}
                icon={
                  <Icon
                    name="Coffee"
                    className="w-[14px] h-[14px]"
                    color="primary500"
                  />
                }
              />
              <OverallScore
                score={errorOverviews?.overallScore.amount ?? 0}
                status={errorOverviews?.overallScore.status ?? "NEUTRAL"}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function ModuleScoreCard() {
  const { t } = useTranslation("errorBoard");
  const { state } = useErrorBoard();
  const errorOverviews = state.data.errorOverviews;

  const [isOpen, setIsOpen] = React.useState(false);
  const isLoading =
    (!errorOverviews && state.status === "loading") ||
    state.status === "refreshing";

  return (
    <>
      <Card
        title={t("cards.moduleScore")}
        value={errorOverviews?.moduleScore.amount ?? 0}
        subValue={(errorOverviews?.moduleScore.percentage ?? 0).toFixed(2)}
        onClick={() => {
          setIsOpen(true);
        }}
        isDisabled={!errorOverviews}
        isLoading={isLoading}
        icon={
          <Icon
            name="MaintenanceTest"
            className="w-[14px] h-[14px]"
            color="primary500"
          />
        }
      />
      <ModuleScoreBreakdownModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
}

function InventoryScoreCard() {
  const { t } = useTranslation("errorBoard");
  const [isOpen, setIsOpen] = React.useState(false);

  const { state } = useErrorBoard();
  const errorOverviews = state.data.errorOverviews;
  const isLoading =
    (!errorOverviews && state.status === "loading") ||
    state.status === "refreshing";

  return (
    <>
      <Card
        title={t("cards.inventoryScore")}
        value={errorOverviews?.inventoryScore.amount ?? 0}
        onClick={() => {
          setIsOpen(true);
        }}
        isDisabled={!errorOverviews}
        subValue={(errorOverviews?.inventoryScore.percentage ?? 0).toFixed(2)}
        isLoading={isLoading}
        icon={
          <Icon
            name="Warehouse"
            className="w-[14px] h-[14px]"
            color="primary500"
          />
        }
      />

      <InventoryScoreBreakdownModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
}

function Card({
  title,
  value,
  subValue,
  isLoading,
  icon,
  isDisabled,
  onClick,
}: {
  title: string;
  value: number;
  subValue: string;
  icon?: React.ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
}) {
  return (
    <div
      className={clsx(
        "w-40 box-border py-[10px] px-[14px] bg-white flex flex-col rounded-lg snap-start flex-shrink-0 overflow-hidden",
        !isLoading && !isDisabled && onClick
          ? "hover:cursor-pointer transition-colors hover:bg-primary-50 hover:ring-1 hover:ring-primary-500 select-none"
          : "",
        isDisabled
          ? "hover:cursor-not-allowed hover:bg-primary-50 hover:ring-1 hover:ring-primary-500 select-none"
          : ""
      )}
      onClick={isLoading || isDisabled ? undefined : onClick}
    >
      <div className="w-6 h-6 rounded-full flex items-center justify-center bg-primary-50">
        {icon}
      </div>

      <div className="flex items-start justify-between mt-1">
        <span className="font-kanit text-xs font-medium text-on-surface-medium whitespace-break-spaces mb-1">
          {title}
        </span>
        {onClick ? (
          <Icon
            name="RightCircularChevron"
            color="onSurfaceMedium"
            className="flex-shrink-0"
          />
        ) : null}
      </div>

      <div
        className={clsx(
          "flex items-baseline gap-1 mt-auto",
          isLoading ? "" : "animate-fade-in-move-up"
        )}
      >
        {isLoading ? (
          <span className="text-2xl leading-6 font-medium font-kanit">
            <Skeleton width={50} />
          </span>
        ) : (
          <>
            <span className="text-2xl leading-6 font-medium font-kanit">
              {value}
            </span>
            <span className="font-kanit text-[10px] leading-4 tracking-[0.4px] text-on-surface-medium">{`(${subValue})`}</span>
          </>
        )}
      </div>
    </div>
  );
}

function OverallScore({
  score,
  status,
  isLoading,
}: {
  score: number;
  status: "GOOD" | "NEUTRAL" | "BAD";
  isLoading: boolean;
}) {
  const { t } = useTranslation("errorBoard");
  return (
    <div className="w-40 box-border bg-white flex flex-col rounded-lg snap-start flex-shrink-0 overflow-hidden">
      <div className="px-[22px] font-kanit text-xs font-medium text-surface bg-gradient-to-t from-[35%] from-[#3A3C79] to-[#292B56] py-[6px]">
        {t("cards.overallScore")}
      </div>
      <div className="flex-grow flex-col flex px-[22px] pt-[11px] pb-[14px]">
        <span className="font-kanit text-[10px] leading-4 tracking-[0.4px] text-on-surface-medium">
          {t("cards.points")}
        </span>

        <div
          className={clsx(
            "flex items-center justify-between mt-auto",
            isLoading ? "" : "animate-fade-in-move-up"
          )}
        >
          {isLoading ? (
            <span className="text-2xl leading-6 font-medium font-kanit">
              <Skeleton width={50} />
            </span>
          ) : (
            <>
              <span
                className={clsx(
                  "text-2xl leading-6 font-medium font-kanit",
                  status === "GOOD" && "text-success",
                  status === "NEUTRAL" && "text-warning",
                  status === "BAD" && "text-error"
                )}
              >
                {score}
              </span>
              <StatusIndicator status={status} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function StatusIndicator({ status }: { status: "GOOD" | "NEUTRAL" | "BAD" }) {
  switch (status) {
    case "GOOD":
      return <Icon name="ReactionsSmiled" color="success" />;
    case "NEUTRAL":
      return <Icon name="ReactionsNeutral" color="warning" />;
    case "BAD":
      return <Icon name="ReactionsSad" color="error" />;
    default:
      return null;
  }
}
