import * as React from 'react';
import * as styles from './SpecificError.module.scss';

import { useParams, useNavigate } from 'react-router-dom';
import Button from 'components/Button';
import Icon from 'common/components/icon/Icon';
import Typography from 'components/Typography';

export const errorTypeArray = [];

export type IErrorTypeFromAPI = (typeof errorTypeArray)[number];

const SpecificError = () => {
  const { errortype } = useParams();
  const pageQuery = errortype;
  const navigate = useNavigate();
  const isIncludedErrorType = errorTypeArray.includes(pageQuery);

  return (
    <>
      {!isIncludedErrorType ? (
        <div className={styles.Error}>
          <Icon className={styles.icon} name="Info" color="error" />
          <Typography translate type="headline-5">
            label_page_not_found
          </Typography>
          <Button
            className={styles.button}
            type="primary"
            onClick={() => navigate('/machines/monitor/overview')}
          >
            action_back_monitor
          </Button>
        </div>
      ) : (
        <div className={styles.TableContainer}>
          <div className="">not aviable at the moment</div>
        </div>
      )}
    </>
  );
};

export default SpecificError;
