import * as React from "react";
import 'chart.js/auto';
import { PolarArea } from "react-chartjs-2";

import * as styles from "./PolarChart.module.scss";
import * as colors from "common/styles/colors.scss";
import { adjustCurrencyValue, formatCurrency } from "utils/currency";


interface PolarChartProps {
  className?: string;
  data: Array<PolarChartSegment>;
}

interface PolarChartSegment {
  label: string;
  color: string;
  amount: number;
  percent: number;
}

function PolarChart({ data, className = "" }: PolarChartProps): JSX.Element {
  return (
    <div className={[styles.PolarChart, className].join(" ")}>
      <PolarArea
        data={{
          labels: data.map((segment) => segment.label),
          datasets: [
            {
              data: data.map((segment) => segment.amount < 0 ? 0 : segment.amount),
              borderColor: data.map((segment) => segment.color),
              borderWidth: 1,
              backgroundColor: data.map((segment) => segment.color + "66"),
            },
          ],
        }}
        options={{
          elements: {
            arc: {
              // @ts-ignore: type of this option was not updated when feature was added
              angle: data.map((segment) => segment.percent * 360),
            },
          },
          scales: {
            r: {
              ticks: {
                color: colors.onSurfaceMedium,
                font: {
                  family: "Kanit",
                  size: 6,
                  weight: "normal",
                  lineHeight: 1.5,
                },
                callback: (label) => {
                  return Intl.NumberFormat("en", {
                    // @ts-ignore: to supress error caused by outdated tsconfig
                    notation: "compact",
                  }).format(adjustCurrencyValue(Number(label)));
                },
              },
            },
          },
          plugins: {
            title: {
              color: colors.onSurfaceHigh,
              display: true,
              font: {
                family: "Kanit",
                size: 10,
                weight: "normal",
                lineHeight: 1.5,
              },
              padding: { bottom: 4 },
              text: "Revenue",
            },
            legend: { display: false },
            tooltip: {
              callbacks: {
                label: (context) => formatCurrency({input: Number(context.raw), minimumFractionDigits: 0}),
              },
            },
          },
        }}
      />
    </div>
  );
}

export { PolarChart };