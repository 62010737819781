import * as React from 'react';

import { Card } from '../atoms/Card';
import { CardAggregate } from '../molecules/CardAggregate';
import { CardTitle } from '../molecules/CardTitle';
import { LineGraph } from '../atoms/charts/LineGraph';
import Typography from 'components/Typography';
import Icon from 'common/components/icon/Icon';
import { useGetDashboardMaScoreGradeQuery } from 'gql/generated';
import { ErrorFallBack } from '../atoms/ErrorFallback';
import { Dialog, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

import * as moment from 'moment';
import { Skeleton } from '@mui/material';
import { clsx } from 'clsx';
import { ErrorBoundary } from '@sentry/react';

export function DailyGradeCard(): JSX.Element {
  const getDailyGradeResult = useGetDashboardMaScoreGradeQuery(undefined, {
    useErrorBoundary: false,
  });
  const isEmpty = getDailyGradeResult.data?.dashboardMAScoreGrade.length === 0;
  const isModalDisabled =
    !getDailyGradeResult.data || isEmpty || getDailyGradeResult.isError;

  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <GradeBreakdownModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <Card className="flex flex-col relative overflow-hidden">
        {isEmpty ? (
          <div className="inset-0 bg-primary-100 opacity-15 absolute pointer-events-none" />
        ) : null}
        <div className="flex justify-between items-center">
          <CardTitle
            icon={'Star'}
            title={'Daily Grade'}
            className={
              getDailyGradeResult.isError || isEmpty
                ? 'text-on-surface-disabled grow'
                : 'grow'
            }
          />
          <div
            onClick={isModalDisabled ? undefined : () => setIsOpen(true)}
            className={clsx(
              isModalDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
              'flex items-center justify-center'
            )}
          >
            <Icon
              name="RightCircularChevron"
              color={isModalDisabled ? 'onSurfaceDisabled' : 'onSurfaceMedium'}
              className="flex-shrink-0"
            />
          </div>
        </div>

        {getDailyGradeResult.isLoading ? (
          <Skeleton
            height={160}
            className="mt-auto"
            style={{ transform: 'scale(1,1)' }}
          />
        ) : getDailyGradeResult.isError ? (
          <ErrorFallBack />
        ) : isEmpty ? (
          <div className="flex-grow flex-col items-center justify-center flex ">
            <Icon name="EyeNo" color="error2" className="w-[67x] h-[67px]" />
            <Typography type="body-3" color="error">
              No data available
            </Typography>
          </div>
        ) : (
          <>
            <div className="grid mt-[9px] grid-cols-2">
              <CardAggregate
                header={'Grade'}
                type={'numeric'}
                value={
                  getDailyGradeResult.data.dashboardMAScoreGrade[
                    getDailyGradeResult.data.dashboardMAScoreGrade.length - 1
                  ].grade
                }
              />
              <CardAggregate
                header={'Raw Score'}
                type={'numeric'}
                value={String(
                  getDailyGradeResult.data.dashboardMAScoreGrade[
                    getDailyGradeResult.data.dashboardMAScoreGrade.length - 1
                  ].score.toFixed(2)
                )}
              />
            </div>

            <LineGraph
              data={getDailyGradeResult.data.dashboardMAScoreGrade.map(
                (grade, index) => [index, grade.score]
              )}
              labels={getDailyGradeResult.data.dashboardMAScoreGrade.map(
                (grade) => [
                  moment(grade.date).format('YYYY-MM-DD'),
                  `Grade ${grade.grade}`,
                  String(grade.score.toFixed(2)),
                ]
              )}
              ticks={{
                count: 5,
                autoSkip: false,
                stepSize: 1,
                display: true,
                font: {
                  family: 'Kanit',
                  size: 10,
                },
              }}
              YGrid={{
                display: true,
              }}
              scales={{
                yAxis: {
                  min: 1,
                  max: 5,
                },
                xAxis: {
                  min: 0,
                  max: 30,
                },
              }}
              className="mt-auto"
            />
          </>
        )}
      </Card>
    </>
  );
}

export function GradeBreakdownModal({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'gradeBreakDownModal',
  });
  const getDailyGradeResult = useGetDashboardMaScoreGradeQuery(undefined, {
    enabled: false,
    useErrorBoundary: false,
  });
  const [lastUpdated, setLastUpdated] = React.useState<Date | null>(null);

  React.useEffect(() => {
    if (getDailyGradeResult.data) {
      setLastUpdated(new Date());
    }
  }, [getDailyGradeResult.data]);

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog onClose={() => setIsOpen(false)}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/30" />
        </Transition.Child>
        <div className="fixed top-0 h-[100dvh] z-10 w-screen overflow-y-hidden flex items-end justify-center box-border sm:items-center sm:p-0 ">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="translate-y-[100px] opacity-0 sm:translate-y-0 sm:scale-95"
            enterTo="translate-y-[0%] opacity-100 scale-100"
            leave="ease-in duration-150"
            leaveFrom="opacity-100 scale-100 translate-y-[0%]"
            leaveTo="translate-y-[100px] opacity-0 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="h-[calc(100%-170px)] w-full sm:w-[unset] sm:h-[calc(100%-80px)] sm:max-h-[600px] sm:max-w-[380px] sm:relative rounded-lg rounded-b-none sm:rounded-b-lg bg-white overflow-hidden shadow-xl flex flex-col">
              <div className="bg-white p-6 pb-0">
                <div className="flex items-center justify-between">
                  <Dialog.Title>
                    <Typography type="headline-6" color="onSurfaceHigh">
                      {t('grade')}
                    </Typography>
                  </Dialog.Title>
                  <button
                    onClick={() => setIsOpen(false)}
                    className="bg-transparent outline-none p-0 flex items-center justify-center cursor-pointer"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                        fill="black"
                        fillOpacity="0.87"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="px-6 flex items-center">
                {lastUpdated ? (
                  <Typography
                    type="body-2"
                    color="onSurfaceMedium"
                    className="animate-fade-in"
                  >
                    {moment(lastUpdated).format('MMM DD, YYYY HH:mm')}
                  </Typography>
                ) : null}
              </div>
              <div className="flex-[1_1_0] min-h-0">
                <ErrorBoundary
                  fallback={({ error }) => <Fallback error={error} />}
                >
                  <ModelContents />
                </ErrorBoundary>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

function ModelContents() {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'gradeBreakDownModal',
  });

  const {
    data: { dashboardMAScoreGrade },
  } = useGetDashboardMaScoreGradeQuery(undefined, {
    enabled: false,
    useErrorBoundary: false,
  });
  const gradeBreakdown =
    dashboardMAScoreGrade[dashboardMAScoreGrade.length - 1];

  const sections = {
    expansionAndRevenueManagementScore: [
      {
        label: 'newLocation',
        multiplier: 10,
        rawScore: "N/A",
        score: "N/A",
      },
      {
        label: 'averageSalesPerMachinePerDay',
        multiplier: 10,
        rawScore: gradeBreakdown.averageMachineSalesRaw.toFixed(2),
        score: gradeBreakdown.averageMachineSalesGrade,
      },
      {
        label: 'lowMachineRevenue',
        multiplier: 10,
        rawScore: gradeBreakdown.lowMachineRevenueRaw,
        score: gradeBreakdown.lowMachineRevenueGrade,
      },
    ],
    maintenanceAndRepairScore: [
      {
        label: 'moduleScore',
        multiplier: 18,
        rawScore: gradeBreakdown.moduleScore,
        score: gradeBreakdown.moduleScoreGrade,
      },
      {
        label: 'inventoryScore',
        multiplier: 18,
        rawScore: gradeBreakdown.inventoryScore,
        score: gradeBreakdown.inventoryScoreGrade,
      },
      {
        label: 'swhwFail',
        multiplier: 16,
        rawScore: gradeBreakdown.softwareHardwareFailRaw,
        score: gradeBreakdown.softwareHardwareFailGrade,
      },
      {
        label: 'inventoryFail',
        multiplier: 16,
        rawScore: gradeBreakdown.inventoryFailRaw,
        score: gradeBreakdown.inventoryFailGrade,
      },
    ],
    cleanlinessMaintenanceScore: [
      {
        label: 'contaminationReport',
        multiplier: 2,
        rawScore: gradeBreakdown.contaminationRaw,
        score: gradeBreakdown.contaminationGrade,
      },
    ],
  };

  return (
    <div className="h-full box-border py-6 overflow-auto hide-scroll-bar flex-col px-6 flex">
      <div className="grid [grid-template-columns:1fr_auto_minmax(auto,80px)]">
        <Title>{t('expansionAndRevenueManagementScore')}</Title>
        <div className="h-2 col-span-3" />
        <TableHeaders />
        <Rows
          rows={sections.expansionAndRevenueManagementScore.map((section) => [
            t(section.label) + ` (${section.multiplier}%)`,
            section.rawScore,
            section.score,
          ])}
        />

        <div className="h-4 col-span-3" />
        <Title>{t('maintenanceAndRepairScore')}</Title>
        <div className="h-2 col-span-3" />
        <TableHeaders />
        <Rows
          rows={sections.maintenanceAndRepairScore.map((section) => [
            t(section.label) + ` (${section.multiplier}%)`,
            section.rawScore,
            section.score,
          ])}
        />

        <div className="h-4 col-span-3" />
        <Title>{t('cleanlinessMaintenanceScore')}</Title>
        <div className="h-2 col-span-3" />
        <TableHeaders />
        <Rows
          rows={sections.cleanlinessMaintenanceScore.map((section) => [
            t(section.label) + ` (${section.multiplier}%)`,
            section.rawScore,
            section.score,
          ])}
        />

        <div className="h-4 col-span-3" />
        <Title>{t('summary')}</Title>
        <div className="h-2 col-span-3" />

        <span className="py-3 px-[14px] flex items-center text-body2 whitespace-nowrap border-[1px] border-outline border-solid rounded-tl-lg">
          {t('averageScore')}
        </span>
        <span className="py-3 px-[14px] flex items-center justify-center text-subtitle2 whitespace-nowrap border-[1px] border-l-0 border-outline border-solid col-span-2 rounded-tr-lg">
          {gradeBreakdown.score}
        </span>

        <span className="py-3 px-[14px] flex items-center text-subtitle2 leading-6 bg-primary-50 border-[1px] border-t-[0px] border-outline border-solid rounded-bl-lg">
          {t('dailyGrade')}
        </span>
        <span className="py-3 px-[14px] flex items-center justify-center text-subtitle2 leading-6 bg-primary-50 border-r-[1px] border-b-[1px] border-outline border-solid rounded-br-lg col-span-2">
          {gradeBreakdown.grade}
        </span>
      </div>
    </div>
  );
}

function Rows({ rows }: { rows: Array<Array<React.ReactNode>> }) {
  return (
    <>
      {rows.map((row, index) => (
        <React.Fragment key={index}>
          <span
            className={clsx(
              'min-h-11 px-[14px] leading-[14px] flex items-center text-body2 border-b-[1px] border-l-[1px] border-outline border-solid text-balance',
              index === rows.length - 1 ? 'rounded-bl-lg' : ''
            )}
          >
            {row[0]}
          </span>
          <span className="px-[14px] flex items-center text-body2 border-[1px] border-t-0 border-outline border-solid">
            {row[1]}
          </span>
          <span
            className={clsx(
              'px-[14px] flex items-center text-body2 border-r-[1px] border-b-[1px] border-outline border-solid',
              index === rows.length - 1 ? 'rounded-br-lg' : ''
            )}
          >
            {row[2]}
          </span>
        </React.Fragment>
      ))}
    </>
  );
}

function Title({ children }: { children: React.ReactNode }) {
  return (
    <span className="font-kanit text-subtitle1 leading-6 col-span-3">
      {children}
    </span>
  );
}

function TableHeaders() {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'gradeBreakDownModal',
  });
  return (
    <>
      <span className="py-3 px-[14px] flex items-center text-[10px] font-kanit leading-4 tracking-[0.4px] text-on-surface-medium bg-black-overlay-4 border-[1px] border-solid border-r-0 border-outline rounded-tl-lg">
        {t('listRatio')}
      </span>
      <span className="px-[14px] flex items-center text-[10px] font-kanit leading-4 tracking-[0.4px] text-on-surface-medium bg-black-overlay-4 border-[1px] border-solid border-outline max-w-[78px] box-border">
        {t('rawScore')}
      </span>
      <span className="py-3 px-[14px] flex items-center text-[10px] font-kanit leading-4 tracking-[0.4px] text-on-surface-medium bg-black-overlay-4 border-[1px] border-l-0 border-solid border-outline rounded-tr-lg box-border">
        {t('score')}
      </span>
    </>
  );
}

export function Fallback({ error }: { error: Error }) {
  const { t } = useTranslation();
  return (
    <div className="h-full flex justify-center items-center overflow-hidden rounded-lg animate-fade-in">
      <div className={'text-center w-full min-w-96 pb-20'}>
        <Typography color="onSurfaceMedium" translate type="headline-6">
          {t('label_an_error_has_occured')}
        </Typography>

        <div>
          <Typography color="onSurfaceMedium" translate type="caption">
            {error.message}
          </Typography>
        </div>
      </div>
    </div>
  );
}
