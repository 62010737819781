import * as React from 'react';
import { useState } from 'react';

import Button from 'common/components/button/Button';
import Typography from 'common/components/typography/Typography';
import Input from 'common/components/input/Input';
import Icon from 'common/components/icon/Icon';

import UploadImageModal from 'containers/settings/Modal/UploadModal/UploadImageModal';
import LanguagePicker from 'common/components/languagepicker/LanguagePicker';

import { useNavigate } from 'react-router-dom';
import { logout } from 'userandauth/utilsAuth';
import { useGetCurrentUser } from 'userandauth/hooks/useGetCurrentUser';
import { usePatchUserDetails } from 'userandauth/hooks/usePatchUserDetails';

import * as styles from './UserSettings.module.scss';

function UserSettings(): JSX.Element {
  const { data: userInfo } = useGetCurrentUser();
  const [userName, setUserName] = useState(userInfo.name);
  const [modalOpen, setModalOpen] = useState<IModalOpen>('none');

  const { mutate: patchUser } = usePatchUserDetails();

  const navigate = useNavigate();

  async function handleLogout(): Promise<void> {
    try {
      await logout(); // Will redirect immediately and rerender due to state updates not being batched
      navigate('/login', { replace: true });
    } catch {
      alert('Log out failed');
    }
  }

  return (
    <div className={styles.UserSettings}>
      {modalOpen === 'uploadPicture' && (
        <UploadImageModal
          onClose={() => setModalOpen('none')}
          uploadAvatarFor="user"
          userId={userInfo.userId}
        />
      )}
      <div className={styles.header}>
        <Typography type="headline-5" translationKey="label_user_setting" />
      </div>

      <div className={styles.userInfoContainer}>
        <div className={styles.picture}>
          {userInfo?.userAvatarUrl === '' ||
          userInfo?.userAvatarUrl === null ||
          userInfo?.userAvatarUrl === undefined ? (
            <div className={styles.imagePlaceholder}></div>
          ) : (
            <div>
              <img
                className={styles.userAvatar}
                src={userInfo?.userAvatarUrl}
                alt=""
              />
            </div>
          )}

          <div className={styles.photoInfo}>
            <div className={styles.organisationPhoto}>
              <Typography
                translationKey="label_user_profile_picture"
                type="caption"
              />
            </div>
            <Button
              onClick={() => setModalOpen('uploadPicture')}
              className={styles.uploadButton}
              type="secondary"
              translationKey="action_upload"
            />
          </div>
        </div>
        <Input
          value={userName}
          type="text"
          label="label_name"
          className={styles.nameInput}
          onChange={(value) => setUserName(value)}
        />
        <Input
          value={userInfo.email}
          type="text"
          label="label_email"
          disable={true}
          className={styles.nameInput}
          renderStyle="noLineBox"
        />
        <Input
          value={userInfo.role}
          type="text"
          label="label_role"
          disable={true}
          className={styles.roleInput}
          renderStyle="noLineBox"
        />
        <LanguagePicker className={styles.languagePicker} />
        <div className={styles.memberOf}>
          <div className={styles.memberOfLabel}>
            <Typography translationKey="label_member_of" type="caption" />
          </div>
          <div className={styles.memberOfContent}>
            <div className={styles.machine}>
              <div>
                <Icon name="Machine" color="onSurfaceMedium" />
              </div>
              <Typography text={userInfo.memberOf.machines} type="subtitle-1" />
              <div>
                <Typography translationKey="label_machine" type="subtitle-1" />
              </div>
            </div>
            <div className={styles.warehouse}>
              <div>
                <Icon name="Machine" color="onSurfaceMedium" />
              </div>
              <Typography
                text={userInfo.memberOf.warehouses}
                type="subtitle-1"
              />
              <div>
                <Typography
                  translationKey="label_warehouse"
                  type="subtitle-1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          translationKey="action_sign_out"
          type="secondary"
          onClick={handleLogout}
          className={styles.logout}
        />
        {userInfo.name === userName ? null : (
          <Button
            translationKey="action_save"
            type="primary"
            onClick={() =>
              patchUser({
                userId: userInfo.userId,
                newUserData: {
                  name: userName,
                },
              })
            }
            className={styles.save}
          />
        )}
      </div>
    </div>
  );
}

export default UserSettings;

type IModalOpen = 'none' | 'uploadPicture';
