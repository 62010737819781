import { SortingState } from "@tanstack/react-table";
import { SalesByLocationSort, SalesByLocationSortField, SortingOrder } from "gql/generated";

function mapSortIdtoSalesByLocationSortField (id: string): SalesByLocationSortField {
  switch (id) {
    case "totalSales":
      return SalesByLocationSortField.TotalSales;
    case "salesCount":
      return SalesByLocationSortField.SalesCount;
    case "totalPromotions":
      return SalesByLocationSortField.TotalPromotionDiscount;
    default:
      return null;
  }
}

export function toSalesByLocationSort (sortState: SortingState): SalesByLocationSort {
    if(sortState && sortState.length > 0) {
    const sortByEnum = mapSortIdtoSalesByLocationSortField(sortState[0].id);
    if(!sortByEnum) {
    return undefined;
    }
    return     {
      order: sortState[0].desc ? SortingOrder.Desc : SortingOrder.Asc,
      orderBy: sortByEnum,
    };
   
  }
    return undefined;
}
