import { useMatch } from "react-router-dom";
import { warehousingRoutes } from "containers/warehousing/routes";


export function useInventoryIdFromURL() {
  const match = useMatch(
    warehousingRoutes.masterInventoryListWithInventoryId()
  );
  return match ? match.params.inventoryId : null;
}
