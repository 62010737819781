import { contractRoutes } from "containers/contract/routes";
import { useNavigate } from "react-router-dom";
import { locationIdCache } from "../../components/location/LocationIdAutoComplete";
import {
  ACCOUNT_TYPE_MAP_REVERSE, ELECTRIC_FEE_TYPE_MAP_REVERSE,
  ElectricFee,
  InsuranceFee,
  PaymentInfo,
  RENT_FEE_TYPE_MAP_REVERSE,
  RentFee,
  locationFeeCache
} from "containers/contract/hooks/useGetLocationFee";
import { RentFeeFormValue } from "../../components/location/RentFee";
import { PaymentInformationFormValue } from "../../components/location/PaymentInformation";
import { ElectricFeeFormValue } from "../../components/location/ElectricFee";
import { InsuranceFeeFormValue } from "../../components/location/InsuranceFee";
import { toasti18n } from "utils/toast";
import {
  ServiceFeeInput,
  useUpdateLocationInContractMutation
} from "gql/generated";
import { contractByIdCache } from "containers/contract/hooks/useGetContractById";


export type EditLocationFeeFormValue = {
  oldLocationId: string;
  newLocationId: string;
  contractId: string;
  rentFee: RentFeeFormValue;
  rentFeePaymentInfo: PaymentInformationFormValue;
  electricFee: ElectricFeeFormValue;
  electricFeePaymentInfo: PaymentInformationFormValue;
  insuranceFee: InsuranceFeeFormValue;
  insuranceFeePaymentInfo: PaymentInformationFormValue;
};


export function useEditLocationFee({ form }: { form: EditLocationFeeFormValue; }) {
  const navigate = useNavigate();
  const { mutate, ...rest } = useUpdateLocationInContractMutation({
    onError: async (err: Error) => {
      toasti18n.error(err);
    },
    onSuccess: async () => {
      toasti18n.success();
      const contractId = form.contractId;
      const currentLocationId = form.oldLocationId;
      const newLocationId = form.newLocationId;

      if (currentLocationId !== newLocationId) {
        const newLocation = await computeOptimisticContractLocation(newLocationId);
        contractByIdCache.changeLocationInContract({
          contractId: contractId,
          previousLocationId: currentLocationId,
          newLocation,
        });
      }

      const computeData = await computeOptimisticLocationFee({
        locationId: newLocationId,
        electricFee: form.electricFee,
        electricFeePaymentInfo: form.electricFeePaymentInfo,
        insuranceFee: form.insuranceFee,
        insuranceFeePaymentInfo: form.insuranceFeePaymentInfo,
        rentFee: form.rentFee,
        rentFeePaymentInfo: form.rentFeePaymentInfo,
      });

      locationFeeCache.updateLocationFee({
        oldLocationId: currentLocationId,
        newLocationId: newLocationId,
        contractId: contractId,
        data: computeData,
      });

      navigate(contractRoutes.locationId(contractId, newLocationId));
    },
  });

  function submit() {
    let rentFee: ServiceFeeInput;
    if (form.rentFee.type === "FIXED") {
      rentFee = {
        payWithCode: form.rentFee.payWithCode,
        type: RENT_FEE_TYPE_MAP_REVERSE[form.rentFee.type],
        fixedFee: Number(form.rentFee.value),
      };
    } else if (form.rentFee.type === "PERCENTAGE") {
      rentFee = {
        payWithCode: form.rentFee.payWithCode,
        type: RENT_FEE_TYPE_MAP_REVERSE[form.rentFee.type],
        percentageFee: {
          minimumAmount: Number(form.rentFee.value.minimumAmount),
          ladderPercentages: form.rentFee.value.segments.map((segment) => ({
            lowerSales: Number(segment.lower),
            upperSales: Number(segment.upper),
            percentage: Number(segment.percentage),
          })),
        },
      };
    } else {
      rentFee = {
        payWithCode: form.rentFee.payWithCode,
        type: RENT_FEE_TYPE_MAP_REVERSE[form.rentFee.type],
        steps: form.rentFee.value.map((step) => ({
          period: {
            start: step.from?.format("YYYY-MM-DD"),
            end: step.to?.format("YYYY-MM-DD"),
          },
          stepFee: {
            amount: Number(step.rentalPrice),
          },
        })),
      };
    }

    mutate({
      input: {
        contractId: form.contractId,
        locationId: form.oldLocationId,
        newLocationId: form.newLocationId,
        rentFee: rentFee,
        rentPaymentAccount: {
          bankAccountName: form.rentFeePaymentInfo.nameOnAccount,
          bankAccountNumber: form.rentFeePaymentInfo.accountNumber,
          bankAccountType: ACCOUNT_TYPE_MAP_REVERSE[form.rentFeePaymentInfo.typeOfAccount],
          bankBranch: form.rentFeePaymentInfo.branchName,
          bankName: form.rentFeePaymentInfo.bankName,
          email: form.rentFeePaymentInfo.email,
        },
        electricFee: {
          type: ELECTRIC_FEE_TYPE_MAP_REVERSE[form.electricFee.type],
          value: Number(form.electricFee.value),
        },
        electricPaymentAccount: {
          bankAccountName: form.electricFeePaymentInfo.nameOnAccount,
          bankAccountNumber: form.electricFeePaymentInfo.accountNumber,
          bankAccountType: ACCOUNT_TYPE_MAP_REVERSE[form.electricFeePaymentInfo.typeOfAccount],
          bankBranch: form.electricFeePaymentInfo.branchName,
          bankName: form.electricFeePaymentInfo.bankName,
          email: form.electricFeePaymentInfo.email,
        },
        insuranceFee: {
          amount: Number(form.insuranceFee.value),
        },
        insurancePaymentAccount: {
          bankAccountName: form.insuranceFeePaymentInfo.nameOnAccount,
          bankAccountNumber: form.insuranceFeePaymentInfo.accountNumber,
          bankAccountType: ACCOUNT_TYPE_MAP_REVERSE[form.insuranceFeePaymentInfo.typeOfAccount],
          bankBranch: form.insuranceFeePaymentInfo.branchName,
          bankName: form.insuranceFeePaymentInfo.bankName,
          email: form.insuranceFeePaymentInfo.email,
        },
      },
    });
  }

  return { submit, ...rest };
}

async function computeOptimisticContractLocation(locationId: string) {
  const location = await locationIdCache.getLocationById(locationId);
  return {
    id: location?.node?.id,
    friendlyId: location?.node?.friendlyId,
    machineId: locationIdCache.utils.getFirstMachineInLocation(location),
  };
}

async function computeOptimisticLocationFee({
  locationId, electricFee, electricFeePaymentInfo, insuranceFee, insuranceFeePaymentInfo, rentFee, rentFeePaymentInfo,
}: {
  locationId: string;
  electricFee: ElectricFee;
  electricFeePaymentInfo: PaymentInformationFormValue;
  rentFee: RentFee;
  rentFeePaymentInfo: PaymentInformationFormValue;
  insuranceFee: InsuranceFee;
  insuranceFeePaymentInfo: PaymentInformationFormValue;
}) {
  const location = await locationIdCache.getLocationById(locationId);

  return {
    fees: {
      electricFee: {
        fee: {
          type: electricFee.type,
          value: electricFee.value,
        },
        paymentInformation: {
          accountNumber: electricFeePaymentInfo.accountNumber,
          bankName: electricFeePaymentInfo.bankName,
          branchName: electricFeePaymentInfo.branchName,
          email: electricFeePaymentInfo.email,
          nameOnAccount: electricFeePaymentInfo.nameOnAccount,
          typeOfAccount: electricFeePaymentInfo.typeOfAccount as PaymentInfo["typeOfAccount"],
        },
      },
      rentFee: {
        fee: rentFee,
        paymentInformation: {
          accountNumber: rentFeePaymentInfo.accountNumber,
          bankName: rentFeePaymentInfo.bankName,
          branchName: rentFeePaymentInfo.branchName,
          email: rentFeePaymentInfo.email,
          nameOnAccount: rentFeePaymentInfo.nameOnAccount,
          typeOfAccount: rentFeePaymentInfo.typeOfAccount as PaymentInfo["typeOfAccount"],
        },
      },
      insuranceFee: {
        fee: {
          value: insuranceFee.value,
        },
        paymentInformation: {
          accountNumber: insuranceFeePaymentInfo.accountNumber,
          bankName: insuranceFeePaymentInfo.bankName,
          branchName: insuranceFeePaymentInfo.branchName,
          email: insuranceFeePaymentInfo.email,
          nameOnAccount: insuranceFeePaymentInfo.nameOnAccount,
          typeOfAccount: insuranceFeePaymentInfo.typeOfAccount as PaymentInfo["typeOfAccount"],
        },
      },
    },
    location: {
      friendlyId: location?.node?.friendlyId,
      id: location?.node?.id,
      name: location?.node?.locationName,
    },
  };
}

