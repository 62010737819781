import Icon from "common/components/icon/Icon";
import { MachineTimelineQuery } from "gql/generated";
import * as moment from "moment";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { clsx } from "clsx";
import { Skeleton } from "@mui/material";
import { usePaginatedTimelineQuery } from "./usePaginatedTimelineQuery";
import { StatusIndicator } from "./StatusIndicator";
import { EventDetails } from "./EventDetails";

export function DateAccordion({
  date, defaultOpen,
}: {
  date: Date;
  defaultOpen?: boolean;
}) {
  const { t } = useTranslation("translation", { keyPrefix: "timeline" });
  const { status, load, data, paginate } = usePaginatedTimelineQuery({
    machineId: useParams<{ machineId: string; }>().machineId,
    date,
  });

  const [isOpen, setIsOpen] = React.useState(defaultOpen);
  React.useEffect(() => {
    if (isOpen) {
      load();
    }
  }, [isOpen]);

  const scrollContainer = React.useRef<HTMLDivElement>(null);
  const endOfTableRef = React.useRef<HTMLDivElement>(null);
  const paginateRef = React.useRef<() => void>(null);
  React.useEffect(() => {
    paginateRef.current = paginate;
  }, [paginateRef.current, paginate]);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            paginateRef.current();
          }
        });
      },
      { root: scrollContainer.current, threshold: 1, rootMargin: "100px" }
    );

    if (endOfTableRef.current) {
      observer.observe(endOfTableRef.current);
    }

    return () => {
      if (endOfTableRef.current) {
        observer.unobserve(endOfTableRef.current);
      }
      observer.disconnect();
    };
  }, [endOfTableRef.current]);

  const [element, heightRef] = React.useState(null);
  const [height, setHeight] = React.useState(0);

  const observer = React.useMemo(
    () => new ResizeObserver((entries) => {
      if (entries[0]) {
        const { height } = entries[0].contentRect;
        setHeight(height);
      }
    }),
    []
  );

  React.useLayoutEffect(() => {
    if (!element)
      return;
    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [element]);

  // Do not animate on first render
  const [heightSetOnce, setHeightSetOnce] = React.useState(false);
  React.useEffect(() => {
    if (!defaultOpen)
      return;
    if (height) {
      setHeightSetOnce(true);
    }
  }, [height]);

  return (
    <>
      <div className="py-[10px] px-6 bg-primary-400 flex justify-between items-center first:rounded-t-lg">
        <h2 className="text-subtitle2 font-normal text-white">
          {moment(date).format("MMM D, YYYY")}
        </h2>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="border-none bg-transparent cursor-pointer p-0 flex items-center justify-center"
        >
          <Icon
            name="DropDownChevron"
            style={{
              transition: "transform 0.2s ease-in-out",
              transform: isOpen ? "rotate(180deg)" : null,
              transformOrigin: "center",
            }} />
        </button>
      </div>
      <div
        className="overflow-hidden bg-white transition-all ease-out duration-300"
        style={{
          height: isOpen ? height : 0,
          transitionDuration: !heightSetOnce && defaultOpen ? "0s" : "0.3s",
        }}
      >
        <div ref={heightRef}>
          {!isOpen ? null : (
            <>
              <div
                className={clsx(
                  "max-h-[500px] overflow-y-auto bg-white snap-y snap-mandatory"
                )}
                ref={scrollContainer}
              >
                <div>
                  {(data?.machineTimeline.edges.length ?? 0) >= 1
                    ? data?.machineTimeline.edges.map((edge, index) => (
                      <div
                        key={edge.cursor}
                        className="grid [grid-template-columns:100px_1fr] hover:bg-[#F1F1F6] transition-colors duration-75 border-b-[1px] border-[#f2f2f2] last:border-b-0 snap-start relative animate-fade-in"
                      >
                        <div className="has-[+div_[data-slot=description]]:self-start has-[+div_[data-slot=description]]:[--aligned-top:1] self-center items-center grid grid-cols-2">
                          <p className="text-overline tracking-[0.4px] leading-4 text-on-surface-medium justify-self-center py-[10px]">
                            {moment(edge.node.timestamp.split("+")[0]).format(
                              "HH.mm"
                            )}
                          </p>
                          <div
                            className={"py-[13px] justify-self-center flex items-center justify-center"}
                          >
                            <TimelineLine
                              index={index}
                              machineTimeline={data} />
                            <StatusIndicator event={edge.node} />
                          </div>
                        </div>

                        <div className="min-h-12 flex items-center">
                          <div className="py-2 grid grid-cols-1 gap-4 md:gap-0 md:grid-cols-2 flex-grow basis-[0px]">
                            <EventDetails event={edge.node} />
                            {edge.node.staffName ? (
                              <div className="font-kanit">
                                <p className="text-overline tracking-[0.4px] leading-4 text-on-surface-medium">
                                  {t("user")}
                                </p>
                                <p className="text-caption leading-4 text-on-surface-high line-clamp-none md:line-clamp-2">
                                  {edge.node.staffName}
                                </p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ))
                    : null}

                  {status === "paginating" || status === "loading"
                    ? Array.from({ length: 10 }).map((_, index) => (
                      <LoadingRow key={index} />
                    ))
                    : null}
                </div>

                <div ref={endOfTableRef} />
              </div>

              {status === "success" && !data?.machineTimeline.edges.length ? (
                <div className="h-[350px] flex flex-col items-center justify-center bg-white animate-fade-in ">
                  <Icon
                    name="EyeNo"
                    color="onSurfaceDisabled"
                    className="w-7 h-7 mt-6" />
                  <p className="text-h6 font-normal font-kanit text-on-surface-disabled ">
                    {t("noDataToShow")}
                  </p>
                  <p className="text-subtitle2 font-normal leading-6 text-on-surface-disabled max-w-64 text-balance text-center">
                    {t("noDataToShowDescription")}
                  </p>
                </div>
              ) : null}

              {status === "error" ? (
                <div className="h-[350px] flex items-center justify-center flex-col bg-white animate-fade-in">
                  <Icon name="AlertOutline" color="error" className="w-8 h-8" />
                  <p className="text-h6 font-normal font-kanit text-error ">
                    {t("failedToLoadData")}
                  </p>
                  <p className="text-subtitle2 font-normal leading-6 text-on-surface-medium max-w-[350px] text-balance text-center">
                    {t("failedToLoadDataDescription")}
                  </p>
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
    </>
  );
}

function LoadingRow() {
  return (
    <div className="grid [grid-template-columns:100px_1fr] hover:bg-[#F1F1F6] transition-colors duration-75 border-b-[1px] border-[#f2f2f2] last:border-b-0 snap-start relative group">
      <div className="self-start items-center grid grid-cols-2">
        <p className="text-overline leading-4 justify-self-center py-[10px]">
          <Skeleton width={24} height={16} style={{ transform: "scale(1)" }} />
        </p>
        <div
          className={"py-[13px] justify-self-center flex items-center justify-center"}
        >
          <div className="absolute h-[calc(100%+1px)] w-[1px] bg-[#BDBDBD] top-0 opacity-60 group-first:top-[23px] group-first:h-[calc(100%-22px)] group-last:h-[13px]" />
          <Skeleton
            style={{ borderRadius: "100%", transform: "scale(1)" }}
            width={10}
            height={10} />
        </div>
      </div>

      <div className="min-h-12 flex items-center">
        <div className="py-2 grid grid-cols-1 gap-4 md:gap-0 md:grid-cols-2 flex-grow basis-[0px]">
          <div className="font-kanit">
            <p className="text-overline leading-4">
              <Skeleton
                height={12}
                width={50}
                style={{ transform: "scale(0.8)" }} />
            </p>
            <p className="text-caption leading-4" data-slot="description">
              <Skeleton
                height={14}
                width={200}
                style={{ transform: "scaleY(0.8)" }} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function TimelineLine({
  machineTimeline, index,
}: {
  machineTimeline?: MachineTimelineQuery;
  index: number;
}) {
  if (machineTimeline.machineTimeline.edges.length === 1) {
    return null;
  }
  if (index === 0) {
    return (
      <div className="absolute h-[calc(calc(calc(50%+1px)*calc(1-var(--aligned-top,0)))+calc(calc(100%-12px)*var(--aligned-top,0)))] w-[1px] bg-[#BDBDBD] top-[calc(calc(50%*calc(1-var(--aligned-top,0)))+calc(13px*var(--aligned-top,0)))]" />
    );
  }
  if (index === machineTimeline.machineTimeline.edges.length - 1 &&
    !machineTimeline.machineTimeline.pageInfo.hasNextPage) {
    return (
      <div className="absolute h-[calc(calc(50%*calc(1-var(--aligned-top,0)))+calc(13px*var(--aligned-top,0)))] w-[1px] bg-[#BDBDBD] top-0" />
    );
  }

  return (
    <div className="absolute h-[calc(100%+1px)] w-[1px] bg-[#BDBDBD] top-0" />
  );
}
