import * as React from "react";

// Hooks
import { useEffect, useLayoutEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

// Custom hooks
import useQuerySync from "common/hooks/useQuerySync";

// Configs & Default settings
import { filterOptionsForGroupBy } from "transactions/constants/filterOptionsForGroupBy";
import salesTableColumnsConfig from "transactions/constants/salesTableColumnsConfig";

// Component imports
import Table, { IColumn } from "common/components/table/Table";
import Icon from "common/components/icon/Icon";
import Typography from "common/components/typography/Typography";
import TextButton from "common/components/textbutton/TextButton";
import { DateRangePicker } from "components/DateRangePicker";
import Select from "common/components/select/Select";
import { Paginator } from "transactions/components/Paginator";
import {
  IAppliedFilter,
  PureFilterBar,
} from "common/components/filterbar/PureFilterBar";

// Service imports
import {
  getMachineTransactions,
  getMachineTransactionsCSV,
} from "machines/service";

// Utils
import * as moment from "moment";

// Custom utils
import insertTransactionHeader from "transactions/utils/insertTransactionHeader";
import { formatMomentDateFullYear } from "common/utils/format";
import { getDefaultQueryParamsForGroupBy } from "transactions/utils/getDefaultQueryParamsForGroupBy";

// Style imports
import * as styles from "./Transaction.module.scss";

import {
  useIsAdmin,
  useIsAdminContract,
  useIsOperator,
  useIsSuperViewer,
} from "utils/user-role";
import { IMachineTransactionData } from "machines/interfaces";
import { machineMenuSelectOptions } from "./selectOptions";

function Transaction(): JSX.Element {
  const canUploadDonwload =
    useIsSuperViewer() ||
    useIsOperator() ||
    useIsAdmin() ||
    useIsAdminContract();

  const navigate = useNavigate();
  const { machineId } = useParams();
  const { queryObject, setQueryObject, queryString } = useQuerySync(
    getDefaultQueryParamsForGroupBy("productName")
  );

  const [data, setData] = useState(null);
  const [metaData, setMetaData] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const isMounted = useRef<boolean>(true);
  const queryStamp = useRef<string | null>(null);

  useLayoutEffect(() => {
    fetchFromSales();
  }, [queryString]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  function onGroupBySelect(selectedGroupBy: string) {
    if(selectedGroupBy === "allTransactionNone") {
      navigate(`/machines/detail/${machineId}/transaction/group-by-none`);
    }

    if (selectedGroupBy === "groupByError") {
      navigate(`/machines/detail/${machineId}/transaction/group-by-error`);
      return;
    }
    if (selectedGroupBy === queryObject.groupBy) {
      return;
    }
    setQueryObject({
      ...getDefaultQueryParamsForGroupBy(selectedGroupBy),
      from: queryObject.from,
      to: queryObject.to,
    });
  }

  async function fetchFromSales() {
    setIsLoading(true);
    setData([]);
    queryStamp.current = queryString;

    try {
      const aggregatedDataFromAPI = await getMachineTransactions(
        machineId,
        queryObject
      );
      const { results, meta } = aggregatedDataFromAPI;
      if (isMounted.current && queryStamp.current === queryString) {
        setData(replaceUndefinedLocationNameWithMachineName(results));
        setMetaData(meta === undefined ? null : meta);
        setIsLoading(false);
      }
    } catch (err) {
      if (isMounted.current && queryStamp.current === queryString) {
        setData(null);
        setMetaData(null);
        setIsLoading(false);
      }
    }
  }

  // 23 May 2024: started adding locationName to telemetrySale
  //   but we'll not updating past telemetrySale data so we'll fallback to use machineName instead
  function replaceUndefinedLocationNameWithMachineName(
    machineTransactions: IMachineTransactionData[]
  ): IMachineTransactionData[] {
    return machineTransactions.map((machineTransaction) => {
      return {
        ...machineTransaction,
        locationName:
          machineTransaction.locationName ?? machineTransaction.machineName,
      };
    });
  }

  function handleRefresh(): void {
    fetchFromSales();
  }

  function onDatePickerBlur({ startDate, endDate }) {
    if (startDate >= endDate) {
      return;
    }

    const queryParams = {
      ...getDefaultQueryParamsForGroupBy(queryObject.groupBy),
      from: startDate,
      to: endDate,
    };

    setQueryObject((queryObject) => {
      if ("filter" in queryObject && "filterBy" in queryObject) {
        return {
          ...queryParams,
          filterBy: queryObject.filterBy,
          filter: queryObject.filter,
        };
      } else {
        return queryParams;
      }
    });
  }

  function onFilterSelect(appliedFilter: IAppliedFilter | null) {
    if (appliedFilter === null) {
      setQueryObject((queryObject) => {
        const { filter, filterBy, ...rest } = queryObject;
        return rest;
      });
    } else {
      setQueryObject((queryObject) => ({
        ...queryObject,
        filter: appliedFilter.value,
        filterBy: appliedFilter.field,
      }));
    }
  }
  /// Download CSV from API
  const CSVDownloading = useRef(false);

  async function handleDownload() {
    // If downloading, do not attempt to download again
    if (CSVDownloading.current === true) {
      return;
    }
    CSVDownloading.current = true;

    const rawCSV = await getMachineTransactionsCSV(machineId, queryObject);

    const CSVFile = new File(["\ufeff" + rawCSV], "transactions.csv", {
      type: "text/csv",
    });

    // Create link to file and simulate click
    const anchorElement = document.createElement("a");
    anchorElement.href = URL.createObjectURL(CSVFile);
    anchorElement.download = `transactions-${
      queryObject.groupBy
    }-${formatMomentDateFullYear(
      moment(Number(queryObject.from))
    )}-${formatMomentDateFullYear(moment(Number(queryObject.to)))}.csv`;
    anchorElement.click();

    URL.revokeObjectURL(anchorElement.href);

    CSVDownloading.current = false;
  }

  let currentColumnsConfig = [
    ...salesTableColumnsConfig[queryObject.groupBy],
  ] as IColumn[];

  currentColumnsConfig = insertTransactionHeader(
    currentColumnsConfig,
    queryObject,
    setQueryObject
  );

  return (
    <div className={styles.Transactions}>
      <div className={styles.header}>
        <div className={styles.left}>
          <div className={styles.title}>
            <Typography type="headline-5" translationKey="menu_transaction" />
            <div className={styles.refresh} onClick={handleRefresh}>
              <Icon name="Refresh" color="primary" />
            </div>
          </div>
          {canUploadDonwload && !!data?.length && (
            <TextButton
              translationKey="action_download"
              onClick={handleDownload}
              icon="Download"
              className={styles.action}
            />
          )}
        </div>
        <div className={styles.right}>
          <Select
            className={styles.groupby}
            label="label_group_by"
            value={queryObject.groupBy}
            options={machineMenuSelectOptions}
            onSelect={onGroupBySelect}
          />
          <DateRangePicker
            defaultStartDate={Number(queryObject.from)}
            defaultEndDate={Number(queryObject.to)}
            className={styles.action}
            onBlur={onDatePickerBlur}
            key={queryString}
          />
        </div>
      </div>
      <PureFilterBar
        onChange={onFilterSelect}
        value={
          queryObject.filterBy === undefined
            ? null
            : {
                field: queryObject.filterBy,
                queryVerb: "contains",
                value: queryObject.filter,
              }
        }
        filterOptions={filterOptionsForGroupBy[queryObject.groupBy]}
        key={queryString}
      />
      <div className={styles.TableContainer}>
        <Table
          infiniteScroll={false}
          className={styles.table}
          data={data}
          loading={isLoading}
          loadingRows={20}
          columns={currentColumnsConfig}
        />
      </div>
      {metaData !== null && (
        <Paginator metaData={metaData} queryObject={queryObject} />
      )}
    </div>
  );
}

export default Transaction;
