import * as styles from './Transactions.module.scss';
import Select from "common/components/select/Select";
import { SortingState } from "components/tableV2";
import { RowPerPageValue } from "components/tableV2/types/tableTypes";
import { CursorPagination, ReportType, useGenerateReportMutation, useGetSalesByLocationQuery } from "gql/generated";
import * as React from "react";
import * as moment from 'moment';
import { toSalesByLocationSort } from "transactions/utils/salesByLocation/SalesByLocationSortMapper";
import { toTableRows } from "transactions/utils/salesByLocation/SalesByLocationTableMapper";
import Typography from "common/components/typography/Typography";
import Icon from "common/components/icon/Icon";
import { useIsAdmin, useIsAdminContract, useIsOperator, useIsRouteman, useIsSuperViewer, useIsTechnician, useIsViewer } from "utils/user-role";
import NoPermissionComponent from "containers/noPermissionPage/NoPermission";
import { groupByOptions } from "./common/groupByOptions";
import { getDefaultQueryParamsForGroupBy } from "transactions/utils/getDefaultQueryParamsForGroupBy";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { UNIXTimeStampTodayInMS } from "common/utils/momentUtils";
import { DateRangePicker } from "components/DateRangePicker";
import TextButton from 'common/components/textbutton/TextButton';
import { TransactionGroupByLocationTable } from './TransactionsByLocation/table';

export function TransactionGroupByLocation(): JSX.Element {
  const cannotViewContent =
    useIsViewer() || useIsTechnician() || useIsRouteman();
  if (cannotViewContent) {
    return <NoPermissionComponent />;
  }
  const canDownload =
    useIsAdmin() ||
    useIsAdminContract() ||
    useIsOperator() ||
    useIsSuperViewer();
  const [dateTo, setDateTo] = useState(
    UNIXTimeStampTodayInMS({ timeOfDay: "dayEnd" })
  );
  const [dateFrom, setDateFrom] = useState(
    UNIXTimeStampTodayInMS({ timeOfDay: "dayStart" })
  );
  const { mutate } = useGenerateReportMutation({
    onSuccess: (data) => {
      window.open(`/download-report/${data.generateReport}`);
    },
  });
  const [rowPerPage, setRowPerPage] = React.useState<RowPerPageValue>(20);
  const [pagination, setPagination] = React.useState<Omit<CursorPagination, "sort">>({first: rowPerPage,});
  const [sorting, setSorting] = React.useState<SortingState>(undefined);
  const {data, isLoading, refetch, isFetching} = useGetSalesByLocationQuery({
    pagination: {
      ...pagination
    },
    sort: toSalesByLocationSort(sorting),     
    filter: {
      date: {
        from: moment.unix(parseInt(dateFrom)/1000).format("YYYY-MM-DD"),
        to: moment.unix(parseInt(dateTo)/1000).format("YYYY-MM-DD")
      }
    }
  });
  const dataRows = React.useMemo(() => toTableRows(data), [data]);
  function onDatePickerBlur({ startDate, endDate }) {
    if (startDate >= endDate) {
      return;
    }
    setDateFrom(startDate);
    setDateTo(endDate);
  }
  const navigate = useNavigate();
  function onGroupBySelect(selectedGroupBy) {
    if (selectedGroupBy === "allTransactionNone") {
      navigate("/transactions/group-by-none");
      return;
    }

    if (selectedGroupBy === "groupByError") {
      navigate("/transactions/group-by-error");
      return;
    }

    if (selectedGroupBy === "locationId") {
      navigate("/transactions/group-by-location");
      return;
    }

    const newURLSearchParams = new URLSearchParams(
      getDefaultQueryParamsForGroupBy(selectedGroupBy)
    ).toString();

    navigate(`/transactions/transaction?${newURLSearchParams}`);
  }  

  function handleRefresh(): void {
    refetch();
    resetPagination(rowPerPage);
  }

  function handleDownloadCSVForDailySalesGroupByLocationId() {
    mutate({
      params: {
        startDate: moment.unix(parseInt(dateFrom)/1000).format("YYYY-MM-DD"),
        endDate: moment.unix(parseInt(dateTo)/1000).format("YYYY-MM-DD"),
        reportType: ReportType.TransactionGroupByLocation,
      },
    });
  }

  const resetPagination = (val) => setPagination({ first: val });  
return (
    <div className={styles.Transactions}>
      <div className={styles.header}>
        <div className={styles.left}>
          <div className={styles.title}>
            <Typography
              type="headline-5"
              translationKey="workspace_transactions"
            />
            <div className={styles.refresh} onClick={handleRefresh}>
              <Icon name="Refresh" color="primary" />
            </div>
          </div>
          {canDownload && !!dataRows?.length && (
            <TextButton
              disabled={!(dateFrom && dateTo)}              
              translationKey="action_download"
              onClick={handleDownloadCSVForDailySalesGroupByLocationId}
              icon="Download"
              className={styles.action}
            />
          )}
        </div>
        <div className={styles.right}>
          <Select
            className={styles.groupby}
            label="label_group_by"
            value="locationId"
            options={groupByOptions}
            onSelect={onGroupBySelect}
          />
            <DateRangePicker
              defaultStartDate={Number(dateFrom)}
              defaultEndDate={Number(dateTo)}
              className={styles.action}
              onBlur={onDatePickerBlur}
            />
        </div>
      </div>
        <TransactionGroupByLocationTable 
          data={dataRows}
          isLoading={isLoading || isFetching}
          pageInfo={data?.salesByLocation?.pageInfo}
          sorting={sorting}
          rowPerPage={rowPerPage}
          setPagination={setPagination}
          setSorting={setSorting}
          setRowPerPage={setRowPerPage}
          />
    </div>
  );
}
