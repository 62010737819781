import { fetcher } from "gql/fetcher";
import {
  MachineTimelineDocument,
  MachineTimelineQuery,
  MachineTimelineQueryVariables,
} from "gql/generated";
import * as moment from "moment";
import * as React from "react";
import { useImmer } from "use-immer";

const POLLING_INTERVAL = 15 * 1000;

export function usePaginatedTimelineQuery({
  machineId,
  date,
}: {
  machineId: string;
  date: Date;
}) {
  const [status, setStatus] = React.useState<
    "idle" | "loading" | "error" | "success" | "paginating" | "paginating-error"
  >("idle");
  const [data, setData] = useImmer<MachineTimelineQuery | null>(null);
  const [error, setError] = React.useState<Error | null>(null);
  const [pollerStatus, setPollerStatus] = React.useState<
    "idle" | "loading" | "error" | "waiting-to-poll"
  >("idle");
  const [pollingStartCursor, setPollingStartCursor] =
    React.useState<string>("");

  const filter = React.useMemo(
    () => ({
      machineId,
      date: {
        from: moment(date).format("YYYY-MM-DD"),
        to: moment(date).format("YYYY-MM-DD"),
      },
    }),
    []
  );

  async function load() {
    if (status !== "idle") return;
    setStatus("loading");
    try {
      const response = await getMachineTimeline({
        filter,
      });
      setData(response);
      setStatus("success");
      if (
        moment().isSame(date, "day") ||
        response.machineTimeline.pageInfo.hasPreviousPage
      ) {
        setPollingStartCursor(response.machineTimeline.pageInfo.startCursor);
        setPollerStatus("waiting-to-poll");
      }
    } catch (error) {
      console.error(error);
      setError(error);
      setStatus("error");
    }
  }

  async function paginate() {
    if (status !== "success") return;
    if (!data.machineTimeline.pageInfo.hasNextPage) return;
    setStatus("paginating");
    try {
      const response = await getMachineTimeline({
        pagination: {
          after: data.machineTimeline.pageInfo.endCursor,
        },
        filter,
      });
      setData((draft) => {
        draft.machineTimeline.edges = [
          ...draft.machineTimeline.edges,
          ...response.machineTimeline.edges,
        ];
        draft.machineTimeline.pageInfo.endCursor =
          response.machineTimeline.pageInfo.endCursor;
        draft.machineTimeline.pageInfo.hasNextPage =
          response.machineTimeline.pageInfo.hasNextPage;
      });
      setStatus("success");
    } catch (error) {
      console.error(error);
      setError(error);
      setStatus("paginating-error");
    }
  }

  React.useEffect(() => {
    if (pollerStatus !== "waiting-to-poll") return;
    const timerId = setTimeout(async () => {
      setPollerStatus("loading");
    }, POLLING_INTERVAL);
    return () => clearTimeout(timerId);
  }, [pollerStatus]);

  React.useEffect(() => {
    if (pollerStatus !== "loading") return;
    let hasChangedState = false;
    async function getNewEvents() {
      try {
        const response = await getMachineTimeline({
          pagination: {
            before: pollingStartCursor === "" ? null : pollingStartCursor,
          },
          filter: filter,
        });
        if (hasChangedState) return;

        if (response.machineTimeline.edges.length !== 0) {
          setData((draft) => {
            draft.machineTimeline.edges = [
              ...response.machineTimeline.edges,
              ...draft.machineTimeline.edges,
            ];
          });
          setPollingStartCursor(
            response.machineTimeline.pageInfo.startCursor
          );
        }

        if (
          response.machineTimeline.pageInfo.hasPreviousPage ||
          moment().isSame(date, "day")
        ) {
          setPollerStatus("waiting-to-poll");
        } else {
          setPollerStatus("idle");
        }
      } catch (error) {
        console.error(error);
        setPollerStatus("error");
      }
    }

    getNewEvents();

    return () => {
      hasChangedState = true;
    };
  }, [pollerStatus]);

  return {
    status,
    data,
    error,
    load,
    paginate,
  };
}

function getMachineTimeline(variables: MachineTimelineQueryVariables) {
  return fetcher<MachineTimelineQuery, MachineTimelineQueryVariables>(
    MachineTimelineDocument,
    variables
  )();
}
