import * as React from "react";
import { TextCard } from "./TextCard";
import { useGetDailyPaymentTypeSalesQuery } from "gql/generated";

export function BestSellingPaymentMethod(): JSX.Element {
  const getDailyMachineSalesResult = useGetDailyPaymentTypeSalesQuery(
    undefined,
    {
      useErrorBoundary: false,
    }
  );
  return (
    <TextCard
      isError={getDailyMachineSalesResult.isError}
      isLoading={getDailyMachineSalesResult.isLoading}
      data={getDailyMachineSalesResult.data?.dailyPaymentTypeSales
        .slice(0, 5)
        .map((method) => ({
          label: method.paymentType,
          value: method.totalCount.toLocaleString(),
        }))}
      icon="MoneyAll2"
      title="Most Used Payment Method"
      labelType="Payment Method"
      valueType="Total Transactions"
    />
  );
}
