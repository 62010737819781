import {
  MachineStatusEnum,
  MachineTimelineQuery,
  MachineTimelineType
} from "gql/generated";
import * as moment from "moment";
import * as React from "react";
import { useTranslation } from "react-i18next";

const MACHINE_STATUS_TO_TRANSLATION = {
  [MachineStatusEnum.Online]: "online_label",
  [MachineStatusEnum.Maintenance]: "maintenance_label",
  [MachineStatusEnum.MaintenanceExit]: "maintenance_exit_label",
  [MachineStatusEnum.Upgrading]: "upgrading_label",
  [MachineStatusEnum.Offline]: "offline_label",
  [MachineStatusEnum.OutOfService]: "out_of_service_label",
  [MachineStatusEnum.Register]: "register_label",
} as const;

export function EventDetails({
  event,
}: {
  event: MachineTimelineQuery["machineTimeline"]["edges"][number]["node"];
}) {
  const { t, i18n } = useTranslation();

  if (event.type === MachineTimelineType.MachineStatus) {
    return (
      <p className="text-caption leading-4 text-on-surface-high self-center">
        {MACHINE_STATUS_TO_TRANSLATION[event.machineStatus]
          ? t(
            "machine_status." +
            MACHINE_STATUS_TO_TRANSLATION[event.machineStatus]
          )
          : event.machineStatus}
      </p>
    );
  }

  if (event.type === MachineTimelineType.AlarmClear) {
    return (
      <div className="font-kanit">
        <p className="text-overline tracking-[0.4px] leading-4 text-on-surface-medium">
          {`${t("timeline.alarm")} (${event.errorCode})`}
        </p>
        <p
          className="text-caption leading-4 text-on-surface-high line-clamp-none md:line-clamp-2 pr-3"
          data-slot="description"
        >
          {t("timeline.alarmCleared")}
        </p>
      </div>
    );
  }

  if (event.type === MachineTimelineType.Alarm) {
    let description = i18n.language === "th"
      ? event.errorDetail?.descriptionTh
      : event.errorDetail?.descriptionEn;
    if (!description) {
      description = event.errorDetail?.raw;
    }

    return !description ? (
      <p className="text-caption leading-4 text-error self-center">
        {t("timeline.alarm")} ({event.errorCode})
      </p>
    ) : (
      <div className="font-kanit">
        <p className="text-overline tracking-[0.4px] leading-4 text-error">
          {t("timeline.alarm")} ({event.errorCode})
        </p>
        <p
          className="text-body3 leading-4 text-on-surface-high line-clamp-none md:line-clamp-2 pr-3"
          data-slot="description"
        >
          {description}
        </p>
      </div>
    );
  }

  if (event.type === MachineTimelineType.Information) {
    return !event.description ? (
      <p className="text-caption leading-4 text-on-surface-high self-center">
        {t("timeline.information")}
      </p>
    ) : (
      <div className="font-kanit">
        <p className="text-overline tracking-[0.4px] leading-4 text-on-surface-medium">
          {t("timeline.information")}
        </p>
        <p
          className="text-caption leading-4 text-on-surface-high line-clamp-none md:line-clamp-2 pr-3"
          data-slot="description"
        >
          {event.description}
        </p>
      </div>
    );
  }

  if (event.type === MachineTimelineType.OpenRefill) {
    return (
      <div className="font-kanit">
        <p className="text-overline tracking-[0.4px] leading-4 text-on-surface-medium">
          {t("timeline.openRefill")}
        </p>
        <p
          className="text-caption leading-4 text-on-surface-high line-clamp-none md:line-clamp-2 pr-3"
          data-slot="description"
        >
          {moment(event.timestamp.split("+")[0]).format("DD-MM-YYYY HH:mm")}
        </p>
      </div>
    );
  }

  if (event.type === MachineTimelineType.OpenService) {
    return (
      <div className="font-kanit">
        <p className="text-overline tracking-[0.4px] leading-4 text-on-surface-medium">
          {t("timeline.openService")}
        </p>
        <p
          className="text-caption leading-4 text-on-surface-high line-clamp-none md:line-clamp-2 pr-3"
          data-slot="description"
        >
          {moment(event.timestamp.split("+")[0]).format("DD-MM-YYYY HH:mm")}
        </p>
      </div>
    );
  }

  return (
    <p className="text-caption leading-4 text-on-surface-high self-center">
      {/* If new events, will just show */}
      {(event as any).type.toString()}
    </p>
  );
}
