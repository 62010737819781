import * as React from "react";
import { ColumnFiltersState, SortingState, TableV2 } from "components/tableV2";
import { SalesByLocationTableDataRow } from "transactions/utils/salesByLocation/SalesByLocationTableMapper";
import { useGenerateSalesByLocationConfig } from "transactions/utils/salesByLocation/generateSalesByLocationConfig";
import { CursorPagination, PageInfo } from "gql/generated";
import { RowPerPageValue } from "components/tableV2/types/tableTypes";

export type TransactionsGroupByLocationProps = {
  data: SalesByLocationTableDataRow[];
  isLoading: boolean;
  pageInfo: PageInfo;
  sorting:  SortingState
  rowPerPage: RowPerPageValue
  setPagination: React.Dispatch<React.SetStateAction<Omit<CursorPagination, "sort">>>
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>
  setRowPerPage: React.Dispatch<React.SetStateAction<RowPerPageValue>>
}

export function TransactionGroupByLocationTable({
  data,
  isLoading,
  pageInfo,
  sorting,
  rowPerPage,
  setPagination,
  setSorting,
  setRowPerPage
}: TransactionsGroupByLocationProps) {
  const columns = useGenerateSalesByLocationConfig();
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const initialPagination: CursorPagination = {first: rowPerPage,};
  const resetPagination = (val: number) => setPagination({ first: val });
   return (
    <TableV2
      tableName="Sales Group By Location Table"
      columns={columns}
      isLoading={isLoading}
      data={data}
      sorting={sorting}
      onSortingChange={(state) => {
        setSorting(state);
        resetPagination(rowPerPage);
      }}
      columnFilters={columnFilters}
      onColumnFiltersChange={setColumnFilters}
      pagination={{
        rowPerPage: rowPerPage,
        onRowPerPageChange: (val) => {
          setRowPerPage(val);
          resetPagination(val);
        },
        hasNextPage: pageInfo?.hasNextPage || false,
        hasPreviousPage:pageInfo?.hasPreviousPage || false,
        onNextPage: () =>
          setPagination(() => ({
            after: pageInfo?.endCursor,
            first: rowPerPage,
            last: undefined,
            before: undefined,
          })),
        onPreviousPage: () =>
          setPagination(() => ({
            first: undefined,
            after: undefined,
            last: rowPerPage,
            before:pageInfo?.startCursor,
          })),
        resetPagination: () => setPagination(initialPagination),
      }}
    />
   );
}
