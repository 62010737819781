/* eslint-disable no-unused-vars */
import * as React from "react";
import { ColumnFiltersState, RowSelectionState, TableV2, SortingState } from "components/tableV2";
import { CursorPagination, useGetMachineMonitorsQuery } from "gql/generated";
import { mapDataToTable } from "./utils/mapDataToTable";
import { mapColumnFilterToMachineMonitorFilter } from "./utils/mapColumnFilterToMachineMonitorFilter";
import { useGenerateColumnConfig } from "./utils/columnsConfig";
import { mapSortStateToSortFilter } from "./utils/mapSortStateToSortFilter";
import { OnChangeFn } from "@tanstack/react-table";
import { useMachinesStore } from "containers/machinesV2/useMachinesStore";
import { getSelectedKeys } from "./utils/getAllSelectedKey";
import { RowPerPageValue } from "components/tableV2/types/tableTypes";

export function OverviewPage () {
  const [rowPerPage, setRowPerPage] = React.useState<RowPerPageValue>(20);
  const { setSelectedMachine } = useMachinesStore();
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [rowSelectionState, SetRowSelectionState] = React.useState<RowSelectionState>({});
  const [pagination, setPagination] = React.useState<Omit<CursorPagination, "sort">>({
    first: rowPerPage
  });
  const [sorting, setSorting] = React.useState<SortingState>(undefined);
  
  const { data, isLoading } = useGetMachineMonitorsQuery({
    pagination: {
      ...pagination,
    },
    sort: mapSortStateToSortFilter(sorting),
    filter: mapColumnFilterToMachineMonitorFilter(columnFilters),
  });

  const resetPagination = (val) => setPagination({ first: val });

  const handleColumnFilterChange: OnChangeFn<ColumnFiltersState> = React.useCallback((updater) => {
    setColumnFilters(updater);
    let newFilter = typeof updater === "function" ? updater(columnFilters) : updater;
    // check if newFilter don't have state with id "machineInventoryStatus" in it
    // remove all state with id "module"
    if (newFilter.findIndex((filter) => filter.id === "machineInventoryStatus") === -1) {
      newFilter = newFilter.filter((filter) => filter.id !== "module");
      setColumnFilters(newFilter);
    }
    setColumnFilters(newFilter);
  }, [columnFilters, rowPerPage]);

  
  const dataRows = React.useMemo(() => mapDataToTable(data), [data]);
  const columns = useGenerateColumnConfig(columnFilters);

  React.useEffect(() => {
    return setSelectedMachine([]);
  }, []);

  return (
    <TableV2
      columnPinning={["machineId"]}
      tableName="machine overview"
      data={dataRows}
      columns={columns}
      isLoading={isLoading}
      rowSelection={{
        enable: true,
        getRowId: (row) => row.machineId,
      }}
      rowSelectionState={rowSelectionState}
      columnFilters={columnFilters}
      sorting={sorting}
      onSortingChange={(state) => {
        setSorting(state);
        resetPagination(rowPerPage);
      }}
      onColumnFiltersChange={handleColumnFilterChange}
      onRowSelectionChange={(updater) => {
        SetRowSelectionState(updater);
        
        // get selected state
        const selected = typeof updater === "function" ? updater(rowSelectionState) : updater;
        // convert selected state to array of machineId and set it to store
        setSelectedMachine(getSelectedKeys(selected));
      }}
      pagination={{
        rowPerPage: rowPerPage,
        onRowPerPageChange: (val) => {
          setRowPerPage(val);
          resetPagination(val);
        },
        hasNextPage: data?.machineMonitors?.pageInfo?.hasNextPage || false,
        hasPreviousPage:
          data?.machineMonitors?.pageInfo?.hasPreviousPage || false,
        onNextPage: () =>
          setPagination(() => ({
            after: data?.machineMonitors?.pageInfo?.endCursor,
            first: rowPerPage,
            last: undefined,
            before: undefined,
          })),
        onPreviousPage: () =>
          setPagination(() => ({
            first: undefined,
            after: undefined,
            last: rowPerPage,
            before: data?.machineMonitors?.pageInfo?.startCursor,
          })),
        resetPagination: () => resetPagination(rowPerPage),
      }}
    />
  );
};
