import * as React from "react";

import { getDefaultQueryParamsForGroupBy } from "transactions/utils/getDefaultQueryParamsForGroupBy";
import { useNavigate, useParams } from "react-router-dom";
import { TransactionGroupByErrorPage } from "containers/transactions/groupByError/page";
import { machineMenuSelectOptions } from "../selectOptions";

export function MachineTransactionGroupByError () {
  const { machineId } = useParams();
  const navigate = useNavigate();

  function onGroupBySelect(selectedGroupBy: string) {


    if(selectedGroupBy === "allTransactionNone") {
      navigate(`/machines/detail/${machineId}/transaction/group-by-none`);
      return;
    }

    if (selectedGroupBy === "groupByError") {
      return;
    }

    const newURLSearchParams = new URLSearchParams(
      getDefaultQueryParamsForGroupBy(selectedGroupBy)
    ).toString();

    navigate(`transaction?${newURLSearchParams}`);
  }
  
  
  return <TransactionGroupByErrorPage name="All Transaction group by error" machineId={machineId} options={machineMenuSelectOptions} onSelect={onGroupBySelect} />;
}
