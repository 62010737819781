import { WarehousesOverview } from "./views/WarehouseOverview";
import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { WarehouseId } from "./views/WarehouseId/WarehouseId";
import { WarehouseIdSupportedMachines } from "./views/WarehouseIdSupportedMachines";
import { WarehouseIdSettings } from "./views/WarehouseIdSettings";
import { WarehouseIdTeam } from "./views/WarehouseIdTeam";
import { WarehouseIdWithProductId } from "./views/WarehouseIdWithProductId";
import { ErrorBoundary } from "@sentry/react";
import { Fallback } from "components/Fallback";
import {
  useIsAdmin,
  useIsAdminContract,
  useIsClerk,
  useIsOperator,
} from "utils/user-role";
import i18n from "common/i18n";
import { WarehousingNavigation } from "containers/warehousing/components/WarehousingNavigation";

function useHasAccessToWarehouses() {
  const canView =
    useIsAdmin() || useIsOperator() || useIsClerk() || useIsAdminContract();
  if (!canView) {
    throw new Error(i18n.t("label_no_permission"));
  }
}

export function Warehouses() {
  return (
    <>
      <ErrorBoundary
        fallback={function FallBackWithNavigation(props: { error: Error }) {
          return (
            <>
              <WarehousingNavigation />
              <Fallback error={props.error} />
            </>
          );
        }}
        key="no-permissions"
      >
        <WarehousesRouter />
      </ErrorBoundary>
    </>
  );
}

export function WarehousesRouter() {
  useHasAccessToWarehouses();
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <ErrorBoundary fallback={Fallback} key="overview">
              <WarehousesOverview />
            </ErrorBoundary>
          }
        />
        <Route
          path="/:warehouseId"
          element={
            <ErrorBoundary fallback={Fallback} key="warehouse-id">
              <WarehouseId />
            </ErrorBoundary>
          }
        />
        <Route
          path="/:warehouseId/warehouse-team"
          element={
            <ErrorBoundary fallback={Fallback} key="warehouse-id-team">
              <WarehouseIdTeam />
            </ErrorBoundary>
          }
        />
        <Route
          path="/:warehouseId/supported-machines"
          element={
            <ErrorBoundary fallback={Fallback} key="supported-machines">
              <WarehouseIdSupportedMachines />
            </ErrorBoundary>
          }
        />
        <Route
          path="/:warehouseId/settings"
          element={
            <ErrorBoundary fallback={Fallback} key="warehouse-id-settings">
              <WarehouseIdSettings />
            </ErrorBoundary>
          }
        />

        <Route
          path="/:warehouseId/product/:productId"
          element={
            <ErrorBoundary
              fallback={Fallback}
              key="warehouse-id-with-product-id"
            >
              <WarehouseIdWithProductId />
            </ErrorBoundary>
          }
        />

        <Route
          path="*"
          element={<Navigate to="/warehousing/warehouses" />}
        />
      </Routes>
    </>
  );
}
