import * as React from "react";

import Typography from "components/Typography";
import { ErrorBoardTabs } from "./ErrorBoardNav";
import { Route, Routes, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { routes } from "../../routes";
import { RefreshButton } from "./RefreshButton";
import { LastUpdatedIndicator } from "./LastUpdatedIndicator";
import { ErrorBoardProvider } from "./providers/ErrorBoardProvider";
import { InventoryView } from "./views/InventoryView";
import { TransactionsView } from "./views/TransactionsView";
import { MajorTroubleView } from "./views/MajorTroubleView";
import { ErrorOverviews } from "./ErrorOverviews";
import { SelectedServiceZoneChips } from "./SelectedServiceZoneChips";
import { clsx } from "clsx";
import { ServiceZoneDropDown } from "./ServiceZoneDropDown";

import * as styles from "./ErrorBoard.module.scss";

export function ErrorBoard() {
  const { t } = useTranslation("errorBoard");

  return (
    <ErrorBoardProvider>
      <div className={styles.ErrorBoardContainer}>
        <div className={styles.HeadingContainer}>
          <Typography type="subtitle-1" color="onSurfaceMedium">
            {t("maintenance")}
          </Typography>
          <div style={{ width: "8px" }} />
          <Typography type="subtitle-1" color="onSurfaceMedium">
            {"/"}
          </Typography>
          <div style={{ width: "12px" }} />
          <Typography type="subtitle-1" color="primary500">
            {t("errorBoard")}
          </Typography>
        </div>

        <div
          className={clsx(
            styles.ServiceZoneAndRefreshButtonContainer,
            "mt-2 md:mt-4"
          )}
        >
          <ServiceZoneDropDown />

          <div className={clsx(styles.RefreshButtonContainer)}>
            <LastUpdatedIndicator />
            <RefreshButton />
          </div>
        </div>

        <div className="mt-1 md:mt-2">
          <SelectedServiceZoneChips />
        </div>

        <div className="mt-1 md:mt-2">
          <ErrorOverviews />
        </div>

        <div className={clsx(styles.ErrorBoard, "mt-2 md:mt-4")}>
          <div>
            <ErrorBoardTabs />
          </div>

          <div className={styles.SubNavAndTableContainer}>
            <Routes>
              <Route
                path="/major-trouble/*"
                element={<MajorTroubleView />}
              />
              <Route path="/inventory/*" element={<InventoryView />} />
              <Route
                path="/transactions/*"
                element={<TransactionsView />}
              />
              <Route path="*" element={<Navigate to={routes.majorTrouble.root} replace />} />
            </Routes>
          </div>
        </div>
      </div>
    </ErrorBoardProvider>
  );
}
