import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import IndexTransaction from "./IndexTransaction/IndexTransaction";
import Transactions from "./Transactions";
import { TransactionGroupByError } from "./groupByError";
import { TransactionGroupByLocation } from "./TransactionsByLocation";

export function TransactionRoute() {
  return (
    <Routes>
      <Route path="/group-by-none" element={<IndexTransaction />} />
      <Route path="/group-by-error" element={<TransactionGroupByError />} />
      <Route
        path="/group-by-location"
        element={<TransactionGroupByLocation />}
      />
      <Route path="/transaction" element={<Transactions />} />

      <Route path="*" element={<Navigate to="/transactions/group-by-none" />} />
    </Routes>
  );
}
