import * as React from "react";

import {
  Exact,
  KitPlanMutation,
  KitPlanMutationVariables,
  RefillInventoryInput,
  useGetCreatedPlanByIdQuery,
  useKitPlanMutation,
} from "gql/generated";
import { useImmer } from "use-immer";
import { MutateOptions } from "react-query";
import { AxiosError } from "axios";
import {
  WarehouseProducts,
  useGetWarehouseProducts,
} from "./useGetWarehouseProducts";
import {
  Machine,
  Inventory,
  MachineMap,
  computeMachineMapFromPlanAndUsage,
} from "./computeMachineMapFromPlanAndUsage";
import { checkIfRoutingSkipped } from "./KittingModal";

export default function useKittingModalState({ planId }: { planId: string }) {
  const { data: planQuery } = useGetCreatedPlanByIdQuery(
    { planId },
    { cacheTime: 0 }
  );
  const { data: warehouseProducts } = useGetWarehouseProducts(
    planQuery.plan.warehouseId
  );

  const isRoutingSkipped = checkIfRoutingSkipped(planQuery.plan);

  const machines = React.useMemo(
    () =>
      computeMachineMapFromPlanAndUsage(planQuery.plan),
    [planQuery]
  );

  const initializeSelectionState = initializeSelectedPackages(machines);
  const hasChanged = React.useRef(false);
  const [selectedPackages, _setSelectedPackages] = useImmer(() =>
    initializeSelectionState
  );
  const setSelectedPackages: typeof _setSelectedPackages = (arg) => {
    hasChanged.current = true;
    _setSelectedPackages(arg);
  };

  React.useEffect(
    function syncSelectedPackagesWithPlanQuery() {
      const incomingMachineIds = planQuery.plan.refillOrders.map(
        (ro) => ro.machineId
      );

      const machineIdsToRemove = Object.keys(selectedPackages).filter(
        (machineId) => !incomingMachineIds.includes(machineId)
      );

      if (machineIdsToRemove.length === 0) {
        return;
      }

      setSelectedPackages((draft) => {
        machineIdsToRemove.forEach((machineId) => {
          delete draft[machineId];
        });
      });
    },
    [planQuery]
  );

  const usedProduct = React.useMemo(
    () =>
      computeUsedProduct({
        machines: machines,
        selectedPackages: selectedPackages,
        warehouseProducts: warehouseProducts,
      }),
    [warehouseProducts, machines, selectedPackages]
  );

  const formValidity = React.useMemo(
    () =>
      dervieFormValidity({
        machines: machines,
        selectedPackages: selectedPackages,
        usedProduct: usedProduct,
      }),
    [machines, selectedPackages, usedProduct]
  );

  const aggregates = React.useMemo(
    () => computeAggregates({ machines, selectedPackages }),
    [machines, selectedPackages]
  );

  const { isLoading: isSubmitting, mutate } = useKitPlanMutation();

  function submitForm(options: KitPlanMutateOptions = {}) {
    mutate(
      mapStateToKitPlanMutationArg({
        planId: planId,
        machines: machines,
        selectedPackages: selectedPackages,
      }),
      options
    );
  }

  const returnValue: KittingModalState = {
    plan: {
      planId: planQuery.plan.id,
      planName: planQuery.plan.name,
      warehouseId: planQuery.plan.warehouseId,
      isRoutingSkipped: isRoutingSkipped,
    },
    initializeSelectionState,
    aggregates: aggregates,
    form: {
      ...formValidity,
      submitForm: submitForm,
      isSubmitting,
      hasChanged: hasChanged.current,
    },
    machines: mapStateValuesToMachinesField({
      machines,
      selectedPackages,
      usedProduct,
      onInventoryToggle: ({ inventoryId, machineId }) =>
        setSelectedPackages((draft) => {
          toggleInventorySelection({
            inventoryId,
            machineId,
            selectedPackages: draft,
          });
        }),
      onPackageNumberChange: ({ inventoryId, machineId, type }) =>
        setSelectedPackages((draft) => {
          changePackageNumber({
            type,
            inventoryId,
            machineId,
            selectedPackages: draft,
          });
        }),
    }),
  };
  return returnValue;
}

type KitPlanMutateOptions = MutateOptions<
  KitPlanMutation,
  AxiosError,
  Exact<{
    planId: string;
    refillInventories: RefillInventoryInput | RefillInventoryInput[];
  }>
>;

function mapStateValuesToMachinesField({
  machines,
  onInventoryToggle,
  onPackageNumberChange,
  selectedPackages,
  usedProduct,
}: {
  machines: MachineMap;
  selectedPackages: SelectedPackages;
  onInventoryToggle: ({
    inventoryId,
    machineId,
  }: {
    inventoryId: string;
    machineId: string;
  }) => void;
  onPackageNumberChange: ({
    inventoryId,
    machineId,
    type,
  }: {
    inventoryId: string;
    machineId: string;
    type: ActionType;
  }) => void;
  usedProduct: UsedProductMap;
}): KittingModalState["machines"] {
  return Object.fromEntries(
    Object.entries(machines).map(([machineId, machine]) => {
      const { inventory, ...restOfMachine } = machine;

      const modifiedInventory = Object.fromEntries(
        Object.entries(inventory).map(([inventoryId, inventory]) => {
          const { selected, packageNumber } =
            selectedPackages[machineId][inventoryId];

          const { productToUse, ...restOfInventory } = inventory;
          const productId = productToUse.productId;

          return [
            inventoryId,
            {
              ...restOfInventory,
              selected,
              toggleInventorySelection: () =>
                onInventoryToggle({ inventoryId, machineId }),
              productToUse: {
                ...productToUse,
                amountUsedInPackages: packageNumber,
                availablePackages: usedProduct[productId].available,
                changePackageNumber: (type: ActionType) =>
                  onPackageNumberChange({ inventoryId, machineId, type }),
              },
              machineId,
            },
          ];
        })
      );

      return [
        machineId,
        {
          ...restOfMachine,
          inventory: modifiedInventory,
        },
      ];
    })
  );
}

function mapStateToKitPlanMutationArg({
  planId,
  machines,
  selectedPackages,
}: {
  planId: string;
  machines: MachineMap;
  selectedPackages: SelectedPackages;
}): KitPlanMutationVariables {
  return {
    planId,
    refillInventories: Object.values(machines).map((machine) => {
      const machineId = machine.machineId;
      const refillOrderId = machine.refillOrder.refillOrderId;

      const kittedItems = Object.values(machine.inventory)
        .map((inventory) => {
          const inventoryId = inventory.inventoryId;
          const { selected, packageNumber } =
            selectedPackages[machineId][inventoryId];

          const isSelectedAndRefill =
            inventory.recommendedAction !== "SWAP_REFILL_ON_TOP" &&
            inventory.recommendedAction !== "SWAP_REPLACE" &&
            selected;

          return isSelectedAndRefill
            ? {
                packageAmount: packageNumber,
                machineInventoryId: inventoryId,
              }
            : undefined;
        })
        .filter(Boolean);

      const swapItems = Object.values(machine.refillOrder.swapOrders)
        .map((swapOrder) =>
          swapOrder.swapItems.reduce(
            (aggregateArray, swapItem) => {
              const swapItemId = swapItem.swapItemId;
              const inventoryId = swapItem.inventoryReference.inventoryId;
              const { packageNumber } = selectedPackages[machineId][inventoryId];

              aggregateArray.push({
                machineInventoryId: inventoryId,
                swapItemId,
                packageAmount: packageNumber,
              });

              return aggregateArray;
            },
            [] as { machineInventoryId: string, swapItemId: string; packageAmount: number }[]
          )
        )
        .flat(1);

      return {
        machineId,
        refillOrderId,
        kittedItems,
        swapItems,
      };
    }),
    kittingRecommendations: Object.values(machines).flatMap(
      (machine) => Object.values(machine.inventory).map((inventory) => inventory.recommendation)
    )
  };
}

interface Aggregates {
  numberOfRecommendedRefillSlots: number;
  totalNumberOfItemsPermachine: { [machineId: string]: number | undefined };
  numberOfSelectedSlots: { [machineId: string]: number | undefined };
}

function computeAggregates({
  machines,
  selectedPackages,
}: {
  machines: MachineMap;
  selectedPackages: SelectedPackages;
}): Aggregates {
  let numberOfRecommendedRefillSlots: Aggregates["numberOfRecommendedRefillSlots"] = 0;
  const totalNumberOfItemsPermachine: Aggregates["totalNumberOfItemsPermachine"] =
    {};
  const numberOfSelectedSlots: Aggregates["numberOfSelectedSlots"] = {};

  Object.values(machines).forEach((machine) => {
    const machineId = machine.machineId;
    numberOfSelectedSlots[machineId] = 0;
    totalNumberOfItemsPermachine[machineId] = 0;

    Object.values(machine.inventory).forEach((inventory) => {
      const inventoryId = inventory.inventoryId;
      const { selected, packageNumber } =
        selectedPackages[machineId][inventoryId];

      if (inventory.recommendedAction !== null) {
        numberOfRecommendedRefillSlots++;
      }

      if (selected) {
        totalNumberOfItemsPermachine[machineId] =
          totalNumberOfItemsPermachine[machineId] + packageNumber;

        numberOfSelectedSlots[machineId] = numberOfSelectedSlots[machineId] + 1;
      }
    });
  });

  return {
    numberOfRecommendedRefillSlots,
    totalNumberOfItemsPermachine,
    numberOfSelectedSlots,
  };
}

type SelectionValidity = "WARN" | "INVALID" | "VALID";
interface FormValidity {
  machineValidity: MachineValidity;
  inventoryValidity: InventoryValidity;
  isFormValid: boolean;
}

interface MachineValidity {
  [machineId: string]: SelectionValidity | undefined;
}
interface InventoryValidity {
  [machineId: string]:
    | { [inventoryId: string]: SelectionValidity | undefined }
    | undefined;
}

function dervieFormValidity({
  selectedPackages,
  usedProduct,
  machines,
}: {
  machines: MachineMap;
  selectedPackages: SelectedPackages;
  usedProduct: UsedProductMap;
}): FormValidity {
  const machineValidity: FormValidity["machineValidity"] = {};
  const inventoryValidity: FormValidity["inventoryValidity"] = {};
  let isFormValid: FormValidity["isFormValid"] = true;

  Object.values(machines).forEach((machine) => {
    const machineId = machine.machineId;
    inventoryValidity[machineId] = {};

    let atLeastOneInventorySelected = false;
    let mostSevereSelectionValidity: SelectionValidity = "VALID";

    Object.values(machine.inventory).forEach((inventory) => {
      const productId = inventory.productToUse.productId;
      const inventoryId = inventory.inventoryId;

      const { selected, packageNumber } =
        selectedPackages[machineId][inventoryId];
      if (!selected) {
        inventoryValidity[machineId][inventoryId] = "VALID";
      } else {
        atLeastOneInventorySelected = true;
        const availablePackages = usedProduct[productId].available;
        if (availablePackages === null || availablePackages < 0) {
          inventoryValidity[machineId][inventoryId] = "INVALID";
          mostSevereSelectionValidity = "INVALID";
        } else if (packageNumber === 0) {
          inventoryValidity[machineId][inventoryId] = "WARN";
          mostSevereSelectionValidity =
            mostSevereSelectionValidity === "VALID"
              ? "WARN"
              : mostSevereSelectionValidity;
        } else {
          inventoryValidity[machineId][inventoryId] = "VALID";
        }
      }
    });

    const assignedMachineValidty = !atLeastOneInventorySelected
      ? "WARN"
      : mostSevereSelectionValidity;
    if (assignedMachineValidty !== "VALID") {
      isFormValid = false;
    }
    machineValidity[machine.machineId] = assignedMachineValidty;
  });

  return {
    isFormValid,
    machineValidity,
    inventoryValidity,
  };
}

interface UsedProductMap {
  [productId: string]: {
    used: number;
    available: number | null;
    packagesInWarehouse: number | null;
  };
}

function computeUsedProduct({
  machines,
  selectedPackages,
  warehouseProducts,
}: {
  machines: MachineMap;
  warehouseProducts: WarehouseProducts;
  selectedPackages: SelectedPackages;
}): UsedProductMap {
  const numberOfPackagesUsedPerProductId: {
    [productId: string]: number | undefined;
  } = {};

  Object.values(machines).forEach((machine) =>
    Object.values(machine.inventory).forEach((inventory) => {
      const productId = inventory.productToUse.productId;
      const inventoryId = inventory.inventoryId;
      const machineId = machine.machineId;
      const { packageNumber, selected } =
        selectedPackages[machineId][inventoryId];

      const currentNumberOfPackages =
        numberOfPackagesUsedPerProductId[productId] ?? 0;
      if (selected) {
        numberOfPackagesUsedPerProductId[productId] =
          currentNumberOfPackages + packageNumber;
      } else {
        numberOfPackagesUsedPerProductId[productId] = currentNumberOfPackages;
      }
    })
  );

  const numberOfPackagesUsedWithAvailability: UsedProductMap = {};
  Object.entries(numberOfPackagesUsedPerProductId).forEach(
    ([productId, used]) => {
      const warehouseProduct = warehouseProducts[productId] ?? null;
      numberOfPackagesUsedWithAvailability[productId] = {
        packagesInWarehouse:
          warehouseProduct !== null ? warehouseProduct : null,
        used,
        available: warehouseProduct !== null ? warehouseProduct - used : null,
      };
    }
  );

  return numberOfPackagesUsedWithAvailability;
}

type SelectedPackages = {
  [machineId: string]:
    | {
        [inventoryId: string]:
          | { packageNumber: number; selected: boolean, slotId: string, slotName: string }
          | undefined;
      }
    | undefined;
};

function initializeSelectedPackages(machines: MachineMap): SelectedPackages {
  const selectedPackages: SelectedPackages = {};

  for (const machine of Object.values(machines)) {
    const selectedPackagesForMachine: SelectedPackages[string] = {};
    for (const inventory of Object.values(machine.inventory)) {
      const isSelectedByDefault = inventory.recommendedAction !== null;

      selectedPackagesForMachine[inventory.inventoryId] = {
        packageNumber: inventory.productToUse.amountRequired.roundedToPackageSize,
        selected: isSelectedByDefault,
        slotId: inventory.inventoryId,
        slotName: inventory.slot
      };
    }
    selectedPackages[machine.machineId] = selectedPackagesForMachine;
  }
  return selectedPackages;
}

function toggleInventorySelection({
  selectedPackages,
  machineId,
  inventoryId,
}: {
  selectedPackages: SelectedPackages;
  machineId: string;
  inventoryId: string;
}) {
  selectedPackages[machineId][inventoryId].selected =
    !selectedPackages[machineId][inventoryId].selected;
}

type ActionType = "increment" | " decrement";

function changePackageNumber({
  selectedPackages,
  machineId,
  inventoryId,
  type,
}: {
  selectedPackages: SelectedPackages;
  machineId: string;
  inventoryId: string;
  type: ActionType;
}) {
  const currentValue = selectedPackages[machineId][inventoryId].packageNumber;
  const newValue = Math.max(
    type === "increment" ? currentValue + 1 : currentValue - 1,
    0
  );
  selectedPackages[machineId][inventoryId].packageNumber = newValue;
}

export interface KittingModalState {
  form: FormValidity & {
    submitForm: (options?: KitPlanMutateOptions) => void;
    isSubmitting: boolean;
    hasChanged: boolean;
  };
  aggregates: Aggregates;
  initializeSelectionState: SelectedPackages;
  plan: {
    planId: string;
    planName: string;
    warehouseId: string;
    isRoutingSkipped: boolean;
  };
  machines: {
    [machineId: string]: Omit<Machine, "inventory"> & {
      inventory: {
        [inventoryId: string]: Omit<Inventory, "productToUse"> & {
          selected: boolean;
          machineId: string;
          toggleInventorySelection: () => void;
          productToUse: Inventory["productToUse"] & {
            changePackageNumber: (type: ActionType) => void;
            amountUsedInPackages: number;
            availablePackages: number;
          };
        };
      };
    };
  };
}
