import * as React from "react";

import {
  contractRoutes,
  useContractIdUrlParams,
} from "containers/contract/routes";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ContractWorkSpace } from "../../components/ContractWorkSpace";
import {
  RentFeeForm,
  RentFeeFormValue,
  initializeRentFeeFormValue,
} from "../../components/location/RentFee";
import {
  PaymentInformationForm,
  PaymentInformationFormValue,
  initializePaymentInfoFormValue,
} from "../../components/location/PaymentInformation";
import {
  ElectricFeeForm,
  ElectricFeeFormValue,
  initializeElectricFeeFormValue,
} from "../../components/location/ElectricFee";
import {
  InsuranceFeeFormValue,
  InsuranceFeeForm,
  initializeInsuranceFeeFormValue,
} from "../../components/location/InsuranceFee";
import { toasti18n } from "utils/toast";
import Button from "components/Button/Button";
import TextFieldViewOnly from "components/TextFieldViewOnly/TextFieldViewOnly";
import {
  LocationIdAutoComplete,
  locationIdCache,
} from "../../components/location/LocationIdAutoComplete";
import {
  contractByIdCache,
  useGetContractById,
} from "../../hooks/useGetContractById";
import * as moment from "moment";
import {
  ServiceFeeInput,
  useAssignContractToLocationMutation,
} from "gql/generated";
import {
  ACCOUNT_TYPE_MAP_REVERSE,
  ContractLocationFee,
  ELECTRIC_FEE_TYPE_MAP_REVERSE,
  ElectricFee,
  InsuranceFee,
  PaymentInfo,
  RENT_FEE_TYPE_MAP_REVERSE,
  RentFee,
  locationFeeCache,
} from "containers/contract/hooks/useGetLocationFee";
import { useCanAccessContractFeature } from "containers/contract/hooks/useCanAccessContractFeature";

function AddLocationToContract() {
  const contractId = useContractIdUrlParams();
  const getContractByIdResult = useGetContractById(contractId);
  const permissionResult = useCanAccessContractFeature();
  const isLoading =
    getContractByIdResult.isLoading || permissionResult.isLoading;
  const { t } = useTranslation();

  return (
    <ContractWorkSpace.ScreenWrapper>
      <ContractWorkSpace.PageTitle>
        {t("label_add_new_location")}
      </ContractWorkSpace.PageTitle>

      {isLoading ? (
        <ContractWorkSpace.LoadingIndicator />
      ) : (
        <AddLocationToContractForm
          contract={{
            id: getContractByIdResult.data.general.contractId,
            friendlyId: getContractByIdResult.data.general.contractFriendlyId,
            startDate: getContractByIdResult.data.general.contractStartDate,
            endDate: getContractByIdResult.data.general.contractEndDate,
          }}
        />
      )}
    </ContractWorkSpace.ScreenWrapper>
  );
}

function AddLocationToContractForm({
  contract,
}: {
  contract: {
    id: string;
    friendlyId: string;
    startDate: moment.Moment;
    endDate: moment.Moment;
  };
}) {
  const [locationId, setLocationId] = React.useState("");
  const [rentFee, setRentFee] = React.useState(initializeRentFeeFormValue);
  const [rentFeePaymentInfo, setRentFeePaymentInfo] = React.useState(
    initializePaymentInfoFormValue
  );

  const [electricFee, setElectricFee] = React.useState(
    initializeElectricFeeFormValue
  );

  const [electricFeePaymentInfo, setElectricFeePaymentInfo] = React.useState(
    initializePaymentInfoFormValue
  );

  const [insuranceFee, setInsuranceFee] = React.useState(
    initializeInsuranceFeeFormValue
  );

  const [insuranceFeePaymentInfo, setInsuranceFeePaymentInfo] = React.useState(
    initializePaymentInfoFormValue
  );

  const navigate = useNavigate();
  const addLocationToContractResult = useAddLocationToContract({
    onSuccess: async () => {
      const location = await computeOptimisticContractLocation({ locationId });
      const locationFee = await computeOptimisticLocationFee(
        contract,
        locationId,
        electricFee,
        electricFeePaymentInfo,
        rentFee,
        rentFeePaymentInfo,
        insuranceFee,
        insuranceFeePaymentInfo
      );
      contractByIdCache.addLocationToContract({
        contractId: contract.id,
        location: location,
      });

      locationFeeCache.addNewLocationFee(locationFee);
      navigate(contractRoutes.contractId(contract.id));
    },
  });

  function submitForm() {
    addLocationToContractResult.submit({
      contractId: contract.id,
      endDate: contract.endDate,
      startDate: contract.startDate,
      locationId: locationId,
      rentFee,
      rentFeePaymentInfo,
      electricFee,
      electricFeePaymentInfo,
      insuranceFee,
      insuranceFeePaymentInfo,
    });
  }

  return (
    <ContractWorkSpace.MaxWidthContainer>
      <ContractWorkSpace.Spacer />
      <TextFieldViewOnly label={"label_contract"} value={contract.friendlyId} />
      <LocationIdAutoComplete value={locationId} onChange={setLocationId} />
      <ContractWorkSpace.Spacer />
      <RentFeeForm value={rentFee} onChange={setRentFee} />
      <ContractWorkSpace.Spacer />
      <PaymentInformationForm
        title="label_rent_payment_information"
        value={rentFeePaymentInfo}
        onChange={setRentFeePaymentInfo}
      />
      <ContractWorkSpace.Spacer />
      <ElectricFeeForm value={electricFee} onChange={setElectricFee} />
      <ContractWorkSpace.Spacer />
      <PaymentInformationForm
        title="label_electric_payment_information"
        value={electricFeePaymentInfo}
        onChange={setElectricFeePaymentInfo}
      />
      <ContractWorkSpace.Spacer />
      <InsuranceFeeForm value={insuranceFee} onChange={setInsuranceFee} />
      <ContractWorkSpace.Spacer />
      <PaymentInformationForm
        title="label_insurance_fee_payment_information"
        value={insuranceFeePaymentInfo}
        onChange={setInsuranceFeePaymentInfo}
      />

      <ContractWorkSpace.Spacer />

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Link
          to={contractRoutes.contractId(contract.id)}
          style={{
            pointerEvents: addLocationToContractResult.isLoading
              ? "none"
              : "auto",
          }}
        >
          <Button
            type="secondary"
            disabled={addLocationToContractResult.isLoading}
          >
            action_cancel
          </Button>
        </Link>
        <Button
          type="primary"
          onClick={submitForm}
          loading={addLocationToContractResult.isLoading}
        >
          action_save
        </Button>
      </div>
    </ContractWorkSpace.MaxWidthContainer>
  );
}

type AddLocationToContractFormValue = {
  locationId: string;
  contractId: string;
  startDate: moment.Moment;
  endDate: moment.Moment;
  rentFee: RentFeeFormValue;
  rentFeePaymentInfo: PaymentInformationFormValue;
  electricFee: ElectricFeeFormValue;
  electricFeePaymentInfo: PaymentInformationFormValue;
  insuranceFee: InsuranceFeeFormValue;
  insuranceFeePaymentInfo: PaymentInformationFormValue;
};

async function computeOptimisticLocationFee(
  contract: {
    id: string;
    friendlyId: string;
    startDate: moment.Moment;
    endDate: moment.Moment;
  },
  locationId: string,
  electricFee: ElectricFee,
  electricFeePaymentInfo: PaymentInformationFormValue,
  rentFee: RentFee,
  rentFeePaymentInfo: PaymentInformationFormValue,
  insuranceFee: InsuranceFee,
  insuranceFeePaymentInfo: PaymentInformationFormValue
): Promise<{
  contractId: string;
  locationId: string;
  data: ContractLocationFee;
}> {
  const location = await locationIdCache.getLocationById(locationId);
  return {
    contractId: contract.id,
    locationId,
    data: {
      contract: {
        friendlyId: contract.friendlyId,
        id: contract.id,
      },
      fees: {
        electricFee: {
          fee: {
            type: electricFee.type,
            value: electricFee.value,
          },
          paymentInformation: {
            accountNumber: electricFeePaymentInfo.accountNumber,
            bankName: electricFeePaymentInfo.bankName,
            branchName: electricFeePaymentInfo.branchName,
            email: electricFeePaymentInfo.email,
            nameOnAccount: electricFeePaymentInfo.nameOnAccount,
            typeOfAccount:
              electricFeePaymentInfo.typeOfAccount as PaymentInfo["typeOfAccount"],
          },
        },
        rentFee: {
          fee: rentFee,
          paymentInformation: {
            accountNumber: rentFeePaymentInfo.accountNumber,
            bankName: rentFeePaymentInfo.bankName,
            branchName: rentFeePaymentInfo.branchName,
            email: rentFeePaymentInfo.email,
            nameOnAccount: rentFeePaymentInfo.nameOnAccount,
            typeOfAccount:
              rentFeePaymentInfo.typeOfAccount as PaymentInfo["typeOfAccount"],
          },
        },
        insuranceFee: {
          fee: {
            value: insuranceFee.value,
          },
          paymentInformation: {
            accountNumber: insuranceFeePaymentInfo.accountNumber,
            bankName: insuranceFeePaymentInfo.bankName,
            branchName: insuranceFeePaymentInfo.branchName,
            email: insuranceFeePaymentInfo.email,
            nameOnAccount: insuranceFeePaymentInfo.nameOnAccount,
            typeOfAccount:
              insuranceFeePaymentInfo.typeOfAccount as PaymentInfo["typeOfAccount"],
          },
        },
      },
      location: {
        friendlyId: location?.node?.friendlyId,
        id: location?.node?.id,
        name: location?.node?.locationName,
      },
    },
  };
}

async function computeOptimisticContractLocation({
  locationId,
}: {
  locationId: string;
}) {
  const location = await locationIdCache.getLocationById(locationId);
  if (!location) {
    return undefined;
  }

  let machineId = null;
  if (location?.node?.__typename === "Location") {
    machineId = location?.node?.machineId;
  } else {
    if(location?.node?.machineIds?.length > 0) {
      machineId = location.node.machineIds[0];
    }
  }

  return {
    id: locationId,
    friendlyId: location?.node?.friendlyId,
    machineId,
  };
}

function useAddLocationToContract({
  onSuccess = () => {},
}: {
  onSuccess?: () => void;
}) {
  const { mutate, ...rest } = useAssignContractToLocationMutation({
    onError: (e: Error) => {
      toasti18n.error(e);
    },
    onSuccess: () => {
      toasti18n.success();
      onSuccess();
    },
  });

  function submit(form: AddLocationToContractFormValue) {
    let rentFee: ServiceFeeInput;
    if (form.rentFee.type === "FIXED") {
      rentFee = {
        payWithCode: form.rentFee.payWithCode,
        type: RENT_FEE_TYPE_MAP_REVERSE[form.rentFee.type],
        fixedFee: Number(form.rentFee.value),
      };
    } else if (form.rentFee.type === "PERCENTAGE") {
      rentFee = {
        payWithCode: form.rentFee.payWithCode,
        type: RENT_FEE_TYPE_MAP_REVERSE[form.rentFee.type],
        percentageFee: {
          minimumAmount: Number(form.rentFee.value.minimumAmount),
          ladderPercentages: form.rentFee.value.segments.map((segment) => ({
            lowerSales: Number(segment.lower),
            upperSales: Number(segment.upper),
            percentage: Number(segment.percentage),
          })),
        },
      };
    } else {
      rentFee = {
        payWithCode: form.rentFee.payWithCode,
        type: RENT_FEE_TYPE_MAP_REVERSE[form.rentFee.type],
        steps: form.rentFee.value.map((step) => ({
          period: {
            start: step.from.format("YYYY-MM-DD"),
            end: step.to.format("YYYY-MM-DD"),
          },
          stepFee: {
            amount: Number(step.rentalPrice),
          },
        })),
      };
    }

    mutate({
      input: {
        contractId: form.contractId,
        contractPeriod: {
          start: form.startDate.format("YYYY-MM-DD"),
          end: form.endDate.format("YYYY-MM-DD"),
        },
        locationId: form.locationId,
        rentFee: rentFee,
        rentPaymentAccount: {
          bankAccountName: form.rentFeePaymentInfo.nameOnAccount,
          bankAccountNumber: form.rentFeePaymentInfo.accountNumber,
          bankAccountType:
            ACCOUNT_TYPE_MAP_REVERSE[form.rentFeePaymentInfo.typeOfAccount],
          bankBranch: form.rentFeePaymentInfo.branchName,
          bankName: form.rentFeePaymentInfo.bankName,
          email: form.rentFeePaymentInfo.email,
        },
        electricFee: {
          type: ELECTRIC_FEE_TYPE_MAP_REVERSE[form.electricFee.type],
          value: Number(form.electricFee.value),
        },
        electricPaymentAccount: {
          bankAccountName: form.electricFeePaymentInfo.nameOnAccount,
          bankAccountNumber: form.electricFeePaymentInfo.accountNumber,
          bankAccountType:
            ACCOUNT_TYPE_MAP_REVERSE[form.electricFeePaymentInfo.typeOfAccount],
          bankBranch: form.electricFeePaymentInfo.branchName,
          bankName: form.electricFeePaymentInfo.bankName,
          email: form.electricFeePaymentInfo.email,
        },
        insuranceFee: {
          amount: Number(form.insuranceFee.value),
        },
        insurancePaymentAccount: {
          bankAccountName: form.insuranceFeePaymentInfo.nameOnAccount,
          bankAccountNumber: form.insuranceFeePaymentInfo.accountNumber,
          bankAccountType:
            ACCOUNT_TYPE_MAP_REVERSE[
              form.insuranceFeePaymentInfo.typeOfAccount
            ],
          bankBranch: form.insuranceFeePaymentInfo.branchName,
          bankName: form.insuranceFeePaymentInfo.bankName,
          email: form.insuranceFeePaymentInfo.email,
        },
      },
    });
  }

  return { submit, ...rest };
}

export default AddLocationToContract;
