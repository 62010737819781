import * as React from "react";
import { Navigate, Routes, Route } from "react-router-dom";

// import Route from "common/components/route/Route";
import SideNav from "common/components/sidenav/SideNav";
import TopNav from "common/components/topnav/TopNav";

import Dashboard from "containers/dashboard/Dashboard";
import MachineDetail from "containers/machinedetail/MachineDetail";
import { Service } from "containers/service/Service";
import Settings from "containers/settings/Settings";
import Location from "containers/location/Location";
import { NewOperations } from "containers/new-operations/NewOperations";
import Warehousing from "containers/warehousing";

import { ErrorBoundary } from "react-error-boundary";
import { FallbackUI } from "error-handling-ui/FallbackUI";

import { Contract } from "containers/contract";
import { TransactionRoute } from "containers/transactions";
import { useIsHideNavigation } from "./isHideNavigation";
import { DownloadReportPage } from "containers/transactions/downloadReport";
import { Maintenance } from "containers/maintenance/Maintenance";

import * as styles from "./Portal.module.scss";
import { MachineV2 } from "containers/machinesV2";

function Portal(): JSX.Element {
  const isHide = useIsHideNavigation();
  return (
    <div className={styles.Portal}>
      {!isHide && (
        <>
          <TopNav />
          <SideNav />
        </>
      )}
      <div
        className={[styles.content, !isHide ? styles.contentWithNav : ""].join(
          " "
        )}
      >
        <ErrorBoundary
          FallbackComponent={FallbackUI}
          resetKeys={[window.location.href]}
        >
          <Routes>
            <Route path="/machines-v2/*" element={<Navigate to="/machines" />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route
              path="/machines/detail/:machineId/*"
              element={<MachineDetail />}
            />
            <Route path="/machines/*" element={<MachineV2 />} />

            <Route path="/transactions/*" element={<TransactionRoute />} />
            <Route path="/warehousing/*" element={<Warehousing />} />
            <Route path="/contract/*" element={<Contract />} />
            <Route path="/maintenance/*" element={<Maintenance />} />
            <Route path="/service/*" element={<Service />} />
            <Route path="/new-operations/*" element={<NewOperations />} />
            <Route path="/settings/*" element={<Settings />} />
            <Route path="/location/*" element={<Location />} />
            <Route
              path="/download-report/:jobId"
              element={<DownloadReportPage />}
            />
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
        </ErrorBoundary>
      </div>
    </div>
  );
}

export default Portal;
