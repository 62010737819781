import * as React from "react";
import { useTranslation } from "react-i18next";
import { routes } from "../../../../routes";
import { useErrorBoard } from "../../hooks/useErrorBoard";
import { ScrollContainer } from "../../components/ScrollContainer";
import { TabButton } from "../../components/TabButton";

export function MajorTroubleSubNav() {
  return (
    <ScrollContainer>
      <MajorTroubleTabs />
    </ScrollContainer>
  );
}

export function MajorTroubleTabs() {

  const { state } = useErrorBoard();
  const majorTroubleSummary = state.data.summary?.majorTrouble;

  const { t } = useTranslation("errorBoard");

  return (
    <>
      <TabButton
        path={routes.majorTrouble.machineUnsellable}
        activePath={routes.majorTrouble.machineUnsellable}
        amount={majorTroubleSummary?.machineUnsellable ?? 0}
      >
        {t("majorTroubleTabs.machineUnsellable")}
      </TabButton>
      <TabButton
        path={routes.majorTrouble.iceError}
        activePath={routes.majorTrouble.iceError}
        amount={majorTroubleSummary?.iceError ?? 0}
      >
        {t("majorTroubleTabs.iceError")}
      </TabButton>
      <TabButton
        path={routes.majorTrouble.coffeeError}
        activePath={routes.majorTrouble.coffeeError}
        amount={majorTroubleSummary?.coffeeError ?? 0}
      >
        {t("majorTroubleTabs.coffeeError")}
      </TabButton>
      <TabButton
        path={routes.majorTrouble.sodaError}
        activePath={routes.majorTrouble.sodaError}
        amount={majorTroubleSummary?.sodaError ?? 0}
      >
        {t("majorTroubleTabs.sodaError")}
      </TabButton>
      <TabButton
        path={routes.majorTrouble.whipper}
        activePath={routes.majorTrouble.whipper}
        amount={majorTroubleSummary?.whipper ?? 0}
      >
        {t("majorTroubleTabs.whipper")}
      </TabButton>
      <TabButton
        path={routes.majorTrouble.outOfService}
        activePath={routes.majorTrouble.outOfService}
        amount={majorTroubleSummary?.outOfService ?? 0}
      >
        {t("majorTroubleTabs.outOfService")}
      </TabButton>
      <TabButton
        path={routes.majorTrouble.offline}
        activePath={routes.majorTrouble.offline}
        amount={majorTroubleSummary?.offline ?? 0}
      >
        {t("majorTroubleTabs.offline")}
      </TabButton>

      <TabButton
        path={routes.majorTrouble.iceRateLow}
        activePath={routes.majorTrouble.iceRateLow}
        amount={majorTroubleSummary?.iceRateLow ?? 0}
      >
        {t("majorTroubleTabs.iceRateLow")}
      </TabButton>

      <TabButton
        path={routes.majorTrouble.spiralUnsellable}
        activePath={routes.majorTrouble.spiralUnsellable}
        amount={majorTroubleSummary?.spiralUnsellable ?? 0}
      >
        {t("majorTroubleTabs.spiralUnsellable")}
      </TabButton>
    </>
  );
}
