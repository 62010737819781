import Button from "components/Button";
import {
  LocationAggregateAttributesInput,
  useUpdateLocationMutation,
} from "gql/generated";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { toasti18n } from "utils/toast";
import { ContractArray, SingleMachineWithContract } from "../../types";
import { PageTitle } from "../../components/PageTitle";
import {
  OperationInfo,
  OperationInfoFormValue,
  mapOperationInfoToOperationInfoFormValue,
  OperationInfoForm,
} from "../../components/OperationInfo";
import {
  mapLocationInfoToGeneralLocationInfoFormValue,
  GeneralLocationInfoForm,
  GeneralLocationInfoFormValue,
  GeneralLocationInfo,
} from "../../components/GeneralLocationInfo";
import { LocationContracts } from "../../components/LocationContracts";
import { MachineIds } from "../../components/MachineIds";
import {
  AdditionalContractInfoForm,
  AdditionalContractInfoFormValue,
  mapAdditionalContractInfoToAdditionalContractInfoFormValue,
} from "containers/location/components/ContractInfo";
import { InfoPanel } from "containers/location/components/ui/InfoPanel";
import {
  RentFeeForm,
} from "containers/contract/components/location/RentFee";
import {
  ElectricFeeForm,
} from "containers/contract/components/location/ElectricFee";
import {
  RentFee,
  ElectricFee,
} from "containers/contract/hooks/useGetLocationFee";
import { mapDataToRentFeeType } from "./utils/mapDataToRentFeeType";
import { mapRentFeeToServiceFeeInput } from "./utils/mapRentFeeToServiceFeeInput";
import { mapRentFeeToElectricFeeInput } from "./utils/mapRentFeeToElectricFeeInput";
import { mapDataToElectricFeeType } from "./utils/mapDataToElectricFeeType";
import { contractRoutes } from "containers/contract";
import { useTranslation } from "react-i18next";
import { ContractLocationFee } from "../LocationId/ContractLocationFee";

type UpdateLocationFormValue = {
  locationId: string;
  generalLocationInfo?: GeneralLocationInfoFormValue;
  operationInfo?: OperationInfoFormValue;
  additionalContractInfo?: AdditionalContractInfoFormValue;
  rentFee: RentFee;
  electricFee: ElectricFee;
};
function useUpdateLocation({ onSuccess }: { onSuccess: () => void }) {
  const { mutate, ...rest } = useUpdateLocationMutation({
    onError: (err: Error) => {
      toasti18n.error(err);
    },
    onSuccess: () => {
      toasti18n.success();
      onSuccess();
    },
  });

  function submit(form: UpdateLocationFormValue) {
    let formInput: LocationAggregateAttributesInput = {
      id: form.locationId,
    };

    if (form.generalLocationInfo) {
      formInput = {
        ...formInput,
        address: {
          province: form.generalLocationInfo.address.province,
          street: form.generalLocationInfo.address.street,
          city: "",
          country: "",
          postalCode: "",
        },
        coordinates: {
          latitude: form.generalLocationInfo.coordinates.latitude,
          longitude: form.generalLocationInfo.coordinates.longitude,
        },
        googleMapLink: form.generalLocationInfo.googleMapLink,
        name: form.generalLocationInfo.name,
        type: form.generalLocationInfo.type,
        organizationId: form.generalLocationInfo.organizationId,
      };
    }

    if (form.operationInfo) {
      formInput = {
        ...formInput,
        refillOperationNote: form.operationInfo.refillOperationNote,
        refillZoneId: form.operationInfo.refillZoneId,
        serviceZoneId: form.operationInfo.serviceZoneId,
        warehouseId: form.operationInfo.warehouseId,
      };
    }

    if (form.additionalContractInfo) {
      formInput = {
        ...formInput,
        projectTypeId: Number(form.additionalContractInfo.projectTypeId),
        sales: {
          name: form.additionalContractInfo.sales,
        },
      };
    }

    if (form.rentFee) {
      formInput = {
        ...formInput,
        rentFee: mapRentFeeToServiceFeeInput(form.rentFee),
      };
    }

    if (form.electricFee) {
      formInput = {
        ...formInput,
        electricFee: mapRentFeeToElectricFeeInput(form.electricFee),
      };
    }

    mutate({
      input: formInput,
    });
  }
  return { submit, ...rest };
}

export function LocationForm({
  locationId,
  location,
  canView,
  canEditGeneralInfo,
  canEditOperationalInfo,
}: {
  locationId: string;
  location: SingleMachineWithContract;
  canView: boolean;
  canEditGeneralInfo: boolean;
  canEditOperationalInfo: boolean;
}) {
  const { t } = useTranslation();
  if (!canView) {
    throw new Error("User do not have permission to edit location");
  }

  const [generalLocationInfoFormValue, setGeneralLocationInfoFormValue] =
    React.useState(
      mapLocationInfoToGeneralLocationInfoFormValue(location.locationInfo)
    );

  const [operationInfoFormValue, setOperationInfoFormValue] = React.useState(
    mapOperationInfoToOperationInfoFormValue(location.operation)
  );
  const isOperationInfoFormInvalid =
    !operationInfoFormValue.refillZoneId ||
    !operationInfoFormValue.serviceZoneId ||
    !operationInfoFormValue.warehouseId;

  const [additionalContractFormValue, setAdditionalContractFormValue] =
    React.useState(
      mapAdditionalContractInfoToAdditionalContractInfoFormValue(
        location.additionalContractInformation
      )
    );

  const [rentFee, setRentFee] = React.useState<RentFee>(
    mapDataToRentFeeType(location.rentFee)
  );
  const [electricFee, setElectricFee] = React.useState<ElectricFee>(
    mapDataToElectricFeeType(location.electricFee)
  );

  const navigate = useNavigate();
  const useUpdateLocationResult = useUpdateLocation({
    onSuccess: () => {
      navigate(`/location/${location.locationInfo.id}`);
    },
  });

  function submitForm() {
    useUpdateLocationResult.submit({
      locationId: location.locationInfo.id,
      generalLocationInfo: canEditGeneralInfo
        ? generalLocationInfoFormValue
        : undefined,
      operationInfo: canEditOperationalInfo
        ? operationInfoFormValue
        : undefined,
      additionalContractInfo: additionalContractFormValue,
      rentFee,
      electricFee,
    });
  }

  const CTA = () => (
    <div className="flex gap-4">
      <Link
        to={`/location/${location.locationInfo.id}`}
        style={{
          pointerEvents: useUpdateLocationResult.isLoading ? "none" : "auto",
        }}
      >
        <Button type="secondary" disabled={useUpdateLocationResult.isLoading}>
          action_cancel
        </Button>
      </Link>
      <Button
        type="primary"
        onClick={submitForm}
        loading={useUpdateLocationResult.isLoading}
        disabled={isOperationInfoFormInvalid}
      >
        action_save
      </Button>
    </div>
  );

  return (
    <>
      <PageViewHeader location={location} cta={<CTA />} />
      <div className="space-y-4">
        <InfoPanel
          title="label_general"
          body={
            <>
              <MachineIds machineIds={[location.machineId]} />
              <LocationContracts contracts={location.contracts} />
              {canEditGeneralInfo ? (
                <GeneralLocationInfoForm
                  value={generalLocationInfoFormValue}
                  initialOrgOptions={[
                    {
                      label: location.locationInfo.organization.name,
                      value: location.locationInfo.organization.id,
                    },
                  ]}
                  onChange={setGeneralLocationInfoFormValue}
                />
              ) : (
                <GeneralLocationInfo locationInfo={location.locationInfo} />
              )}
            </>
          }
        />
        <InfoPanel
          title="label_operation"
          body={
            <>
              {canEditOperationalInfo ? (
                <OperationInfoForm
                  organizationId={generalLocationInfoFormValue.organizationId}
                  initialRefillZoneOptions={[
                    {
                      label: location.operation.refillZone.friendlyId,
                      value: location.operation.refillZone.id,
                    },
                  ]}
                  initialServiceZoneOptions={[
                    {
                      label: location.operation.serviceZone.friendlyId,
                      value: location.operation.serviceZone.id,
                    },
                  ]}
                  initialWarehouseOptions={[
                    {
                      label: location.operation.warehouse.name,
                      value: location.operation.warehouse.id,
                    },
                  ]}
                  value={operationInfoFormValue}
                  onChange={setOperationInfoFormValue}
                />
              ) : (
                <OperationInfo operationInfo={location.operation} />
              )}
            </>
          }
        />

        {/* edit contract  */}
        <InfoPanel
          title="label_contract"
          body={
            <div>
              <AdditionalContractInfoForm
                initialProjectTypeOptions={[
                  {
                    label: `${location.additionalContractInformation.projectType.name} - ${location.additionalContractInformation.projectType.id}`,
                    value:
                      location.additionalContractInformation.projectType.id,
                  },
                ]}
                onChange={setAdditionalContractFormValue}
                value={additionalContractFormValue}
              />

              {canEditGeneralInfo && location.contracts.active.length === 0 && (
                <div className="mt-3">
                  <RentFeeForm
                    value={rentFee}
                    onChange={setRentFee}
                  />
                  <ElectricFeeForm
                    value={electricFee}
                    onChange={setElectricFee}
                  />
                </div>
              )}

              {location.contracts.active.length > 0 && (
                <div className="mt-3 border border-outline rounded-md p-4 relative">
                  <div className="flex ml-2 w-auto -mt-7">
                    <div className="px-2 bg-white flex gap-1">
                      <p className="text-subtitle1 text-error">
                        {t("contract_information_edit_not_allowed")}
                      </p>
                      <ActiveContractList
                        contracts={location.contracts.active}
                      />
                    </div>
                  </div>
                  <ContractLocationFee contractId={location.contracts.active[0].id} locationId={locationId} />
                </div>
              )}
            </div>
          }
        />

        <CTA />
      </div>
    </>
  );
}

export function PageViewHeader({
  location,
  cta,
}: {
  location: SingleMachineWithContract;
  cta?: React.ReactNode;
}) {
  return (
    <div className="flex items-center py-4 gap-4 justify-between">
      <PageTitle location={location} />
      {cta}
    </div>
  );
}

function ActiveContractList({ contracts }: { contracts: ContractArray }) {
  return (
    <>
      {contracts?.map((contract) => (
        <Link
          to={contractRoutes.contractId(contract.id)}
          target="_blank"
          rel="noopener noreferrer"
          key={`contract-${contract.id}`}
          className="text-primary hover:underline font-medium"
        >
          {contract.friendlyId}
        </Link>
      ))}
    </>
  );
}
