import { useMatch } from "react-router-dom";

export const warehousingRoutes = {
  index: () => "/warehousing",
  warehouses: () => warehousingRoutes.index() + "/warehouses",
  warehousesWithWarehouseId: (warehouseId?: string) =>
    warehousingRoutes.warehouses() +
    (warehouseId ? `/${warehouseId}` : `/:warehouseId`),
  warehouseWithWarehouseIdWarehouseTeam: (warehouseId?: string) =>
    warehousingRoutes.warehousesWithWarehouseId(warehouseId) +
    "/warehouse-team",
  warehouseWithWarehouseIdSupportedMachines: (warehouseId?: string) =>
    warehousingRoutes.warehousesWithWarehouseId(warehouseId) +
    "/supported-machines",
  warehouseWithWarehouseIdSettings: (warehouseId?: string) =>
    warehousingRoutes.warehousesWithWarehouseId(warehouseId) + "/settings",
  warehouseWithWarehouseIdProductWithProductId: (
    warehouseId?: string,
    productId?: string
  ) =>
    warehousingRoutes.warehousesWithWarehouseId(warehouseId) +
    "/product" +
    (productId ? `/${productId}` : `/:productId`),
  pendingRequest: () => warehousingRoutes.index() + "/pending-request",
  pendingOrder: () => warehousingRoutes.index() + "/pending-order",
  masterCustomerList: () => warehousingRoutes.index() + "/master-customer-list",
  masterInventoryList: () =>
    warehousingRoutes.index() + "/master-inventory-list",
  masterInventoryListWithInventoryId: (inventoryId?: string) =>
    warehousingRoutes.masterInventoryList() +
    (inventoryId ? `/${inventoryId}` : `/:inventoryId`),
};

export function useGetWarehouseId() {
  const match = useMatch("/warehousing/warehouses/:warehouseId/*");
  return match ? match.params.warehouseId : null;
}

export function useGetProductId() {
  const match = useMatch(
    "/warehousing/warehouses/:warehouseId/product/:productId"
  );
  return match ? match.params.productId : null;
}
