import {
  MachineStatusEnum,
  MachineTimelineQuery,
  MachineTimelineType
} from "gql/generated";
import * as React from "react";
import { clsx } from "clsx";

const EVENT_TYPE_TO_COLOR = {
  [MachineTimelineType.Alarm]: "bg-error",
  [MachineTimelineType.AlarmClear]: "bg-[#666666]",
  [MachineTimelineType.Information]: "bg-[#666666]",
  [MachineTimelineType.MachineStatus]: {
    [MachineStatusEnum.Online]: "bg-success",
    [MachineStatusEnum.Maintenance]: "bg-[#666666]",
    [MachineStatusEnum.Upgrading]: "bg-[#666666]",
    [MachineStatusEnum.Offline]: "bg-error",
    [MachineStatusEnum.OutOfService]: "bg-[#666666]",
    [MachineStatusEnum.Register]: "bg-[#666666]",
    [MachineStatusEnum.MaintenanceExit]: "bg-[#666666]",
  },
  [MachineTimelineType.OpenRefill]: "bg-[#666666]",
  [MachineTimelineType.OpenService]: "bg-[#666666]",
} as const;

export function StatusIndicator({
  event,
}: {
  event: MachineTimelineQuery["machineTimeline"]["edges"][number]["node"];
}) {
  let colorClassName: string;
  if (event.type === MachineTimelineType.MachineStatus) {
    colorClassName = EVENT_TYPE_TO_COLOR[event.type][event.machineStatus];
  } else {
    colorClassName = EVENT_TYPE_TO_COLOR[event.type] ?? "bg-[#666666]";
  }

  return (
    <div
      className={clsx(
        "w-[10px] h-[10px] rounded-full relative",
        colorClassName
      )}
    >
      {event.type === MachineTimelineType.Alarm ? (
        <div
          className={clsx(
            "h-full w-full rounded-full absolute animate-pulse-and-scale opacity-30",
            colorClassName
          )} />
      ) : null}
    </div>
  );
}
