import Typography from "components/Typography/Typography";
import * as React from "react";
import { Link, useMatch } from "react-router-dom";
import { warehousingRoutes } from "../routes";
import * as SCSScolors from "common/styles/colors.scss";
import { WarehousingWorkspacePaddingContainer } from "./WarehousingWorkspacePaddingContainer";
import {
  useIsAdmin,
  useIsAdminContract,
  useIsClerk,
  useIsForthCKUser,
  useIsMalaysianUser,
  useIsSingaporeanUser,
  useIsAustralianUser,
  useIsUnitedArabEmiratesUser
} from "utils/user-role";

export function WarehousingNavigation(): JSX.Element {
  return (
    <nav>
      <WarehousingWorkspacePaddingContainer>
        <ul
          style={{
            display: "flex",
            gap: "40px",
            listStyle: "none",
            margin: "0px",
            padding: "0px",
          }}
        >
          <li>
            <WarehousingLink
              to={`${warehousingRoutes.warehouses()}/*`}
              label="label_warehouses"
            />
          </li>
          <MasterInventoryList />
        </ul>
      </WarehousingWorkspacePaddingContainer>
    </nav>
  );
}

export function useCanViewMasterInventoryList() {
  return (
    (useIsAdmin() || useIsClerk() || useIsAdminContract()) &&
    (useIsForthCKUser() || useIsMalaysianUser() || useIsSingaporeanUser() || useIsAustralianUser() || useIsUnitedArabEmiratesUser() )
  );
}

function MasterInventoryList() {
  const isVisible = useCanViewMasterInventoryList();

  return isVisible ? (
    <li>
      <WarehousingLink
        to={warehousingRoutes.masterInventoryList()}
        label="label_master_inventory_list"
      />
    </li>
  ) : null;
}

function WarehousingLink({ to, label }: { to: string; label: string }) {
  const match = useMatch(to);
  return (
    <Link
      to={to}
      style={{
        display: "block",
        padding: "6px 0px",
        position: "relative",
      }}
    >
      <Typography
        type="overline"
        translate
        color={match ? "primary500" : "onSurfaceHigh"}
      >
        {label}
      </Typography>
      {match && (
        <div
          style={{
            position: "absolute",
            height: "2px",
            backgroundColor: SCSScolors.primary500,
            bottom: "-1px",
            width: "100%",
            borderRadius: "9999px",
          }}
        ></div>
      )}
    </Link>
  );
}
