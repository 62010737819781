import { GetMachineInventoriesQuery } from "gql/generated";
import { IMachineInventoryData, SwapStatusType } from "machines/interfaces";

export type IMachineInventoryWithPercentageData = IMachineInventoryData & {
  tier: number;
  refillPercentage: number;
  warningPercentage: number;
  depletionTimeBeforeRefill: string;
  materialUsage: {
    weekdayUsage: number;
    weekendUsage: number;
  }
}

export function mapDataToTable (data: GetMachineInventoriesQuery): IMachineInventoryWithPercentageData[] {
  return data?.machine?.machineInventories?.map((inventory) => {
    let refillPercentage = Number(inventory.value) / Number(inventory.capacity);
    if (isNaN(refillPercentage)) {
      refillPercentage = 0;
    }
    if (refillPercentage === Infinity) {
      refillPercentage = 1;
    }

    let warningPercentage = Number(inventory.parLevel) / Number(inventory.capacity);
    if (isNaN(warningPercentage)) {
      warningPercentage = 0;
    }
    if (warningPercentage === Infinity) {
      warningPercentage = 1;
    }
    return {
      id: inventory.id,
      tier: data?.machine.tier,
      productId: inventory.productId,
      autoRefill: inventory.autoRefill,
      capacity: inventory.capacity,
      image: inventory.product.image,
      inventory: inventory.product.name,
      inventoryCode: inventory.inventoryCode,
      machineId: inventory.machineId,
      noPartialRefill: inventory.noPartialRefill,
      parLevel: inventory.parLevel,
      recommendRefillAbovePar: inventory.recommendRefillAbovePar,
      refillLevel: inventory.refillLevel,
      SKU: inventory.product.SKU,
      slot: inventory.slot,
      status: inventory.status,
      swapStatus: inventory.swapStatus as SwapStatusType,
      uom: inventory.product.uom,
      value: inventory.value,
      additional: inventory.additional,
      expiryDate: inventory.expiryDate,
      lastRefilled: Number(inventory.lastRefilled),
      depletionTimeBeforeRefill: inventory.refillOverview.materialDepletion.depletionTimeBeforeRefill,
      materialUsage: inventory.refillOverview.materialUsage,
      refillPercentage,
      warningPercentage,
    };
  });
}
