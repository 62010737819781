import { useMatch } from "react-router-dom";

export const contractRoutes = {
  /** Contract index page */
  index: () => "/contract",

  /** Route to create new contract */
  newContract: () => contractRoutes.index() + "/new",

  /** Route to view contract */
  contractId: (contractId?: string) =>
    contractRoutes.index() + (contractId ? `/${contractId}` : `/:contractId`),

  /** Route to edit contract */
  editContract: (contractId?: string) =>
    contractRoutes.contractId(contractId) + "/edit",

  /** Route to add new location contract */
  addLocationToContract: (contractId?: string) =>
    contractRoutes.contractId(contractId) + "/location/add",

  /** Route to view location in contract */
  locationId: (contractId?: string, locationId?: string) =>
    contractRoutes.contractId(contractId) +
    "/location" +
    (locationId ? `/${locationId}` : `/:locationId`),

  /** Route to edit location information in contract */
  editLocation: (contractId?: string, locationId?: string) =>
    contractRoutes.locationId(contractId, locationId) + "/edit",
};

export function useContractIdUrlParams() {
  const match = useMatch("/contract/:contractId/*");
  return match ? match.params.contractId : null;
}

export function useLocationIdUrlParams() {
  const match = useMatch("/contract/:contractId/location/:locationId/*");
  return match ? match.params.locationId : null;
}
