import * as moment from "moment";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { clsx } from "clsx";
import { DayPicker, PropsRange } from "react-day-picker";
import { th, enUS } from "react-day-picker/locale";
import { Popover, Transition } from "@headlessui/react";

export function DatePicker({
  selected, onSelect,
}: Pick<PropsRange, "selected"> & {
  onSelect: (selected: PropsRange["selected"]) => void;
}) {
  const { i18n } = useTranslation();
  const [_selected, _setSelected] = React.useState<PropsRange["selected"]>(selected);

  function onClose() {
    if (!_selected) {
      _setSelected(selected);
    } else {
      onSelect(_selected);
    }
  }

  return (
    <Popover className="relative z-20">
      {({ open }) => (
        <>
          <Popover.Button
            className={clsx(
              "font-kanit w-52 grid [grid-template-columns:minmax(0,1fr)_auto_minmax(0,1fr)] gap-1 py-3 text-subtitle1 leading-6 text-on-surface-medium rounded-[4px] bg-transparent border-[1px] border-outline hover:border-black cursor-pointer",
              open ? "border-black" : ""
            )}
          >
            {!open || !_selected ? (
              <>
                <span className="justify-self-end">
                  {moment(selected.from).format("DD/MM/YYYY")}
                </span>
                <span>-</span>
                <span className="justify-self-start">
                  {moment(selected.to).format("DD/MM/YYYY")}
                </span>
              </>
            ) : (
              <>
                <span className="justify-self-end">
                  {moment(_selected.from).format("DD/MM/YYYY")}
                </span>
                <span>-</span>
                <span className="justify-self-start">
                  {moment(_selected.to).format("DD/MM/YYYY")}
                </span>
              </>
            )}
          </Popover.Button>

          <Transition.Child
            enter="ease-out duration-200 origin-top-right "
            enterFrom="opacity-0 scale-75"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-100 origin-top-right "
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-75"
          >
            <Popover.Panel className="absolute top-[calc(100%+3px)] bg-white font-kanit right-0 shadow-xl rounded-lg ring-1 ring-outline p-5">
              <DayPicker
                className='[--rdp-font-family:"Kanit"] hover:[&_.rdp-day:not(.rdp-selected)]:bg-primary-50 [&_.rdp-range\_start]:rounded-l [&_.rdp-range\_end]:rounded-r [--rdp-day-font:400_14px_var(--rdp-font-family)] [--rdp-range_middle-font:400_14px_var(--rdp-font-family)]  [--rdp-day-height:36px] [--rdp-day-width:36px] [--rdp-selected-font:400_14px_var(--rdp-font-family)] [--rdp-month_caption-font:400_16px_var(--rdp-font-family)] [--rdp-weekday-opacity:0.5] [--rdp-weekday-font:400_smaller_var(--rdp-font-family);'
                locale={i18n.language === "th" ? th : enUS}
                mode="range"
                onSelect={_setSelected}
                selected={_selected}
                disabled={{
                  after: new Date(),
                }} />
            </Popover.Panel>
          </Transition.Child>
          <OnClose open={open} callback={onClose} />
        </>
      )}
    </Popover>
  );
}

function OnClose({ open, callback }: { open: boolean; callback: () => void; }) {
  React.useEffect(() => {
    if (!open) {
      callback();
    }
  }, [open]);
  return null;
}

