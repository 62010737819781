import { GetSalesByLocationQuery } from "gql/generated";

export type SalesByLocationTableDataRow = {
  friendlyId: string;
  locationName: string;
  totalSales: number;
  salesCount: number;
  totalPromotions: number;
}

export function toTableRows(data: GetSalesByLocationQuery): SalesByLocationTableDataRow[] {
  return data?.salesByLocation?.edges?.map((edge) => {
    const node = edge?.node;
    return {
      friendlyId: node?.location?.friendlyId,
      locationName: node?.location?.locationName,
      totalSales: node?.totalSales,
      salesCount: node?.salesCount,
      totalPromotions: node?.totalPromotionDiscount
    };
  });
}
