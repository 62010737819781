import { Navigate, Routes, Route } from "react-router-dom";
import { RefillOrder } from "./refill-order/RefillOrder";
import { VendiiGoogleMapsProvider } from "./refill-order/plan/GoogleMaps";
import { Archive } from "./archive/Archive";

import * as React from "react";

export function NewOperations(): JSX.Element {

  return (
    <>
      <VendiiGoogleMapsProvider>
        <Routes>
          <Route path="/refillorder/*" element={<RefillOrder />} />
          <Route path="/archive/*" element={<Archive />} />
          <Route path="*" element={<Navigate to="/new-operations/refillorder" />} />
        </Routes>
      </VendiiGoogleMapsProvider>
    </>
  );
}
