import * as React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { routes } from "../../../../routes";
import { useServiceZoneQueryParam } from "../../hooks/useServiceZoneQueryParam";
import { ErrorBoundary } from "@sentry/react";
import { AaNoTransaction30MinsTable } from "./AaNoTransaction30MinsTable";
import { NoTransaction2HoursTable } from "./NoTransaction2HoursTable";
import { BillAndCoinTable } from "./BillAndCoinTable";
import { NoTransactionTodayTable } from "./NoTransactionTodayTable";
import { Fallback } from "../../components/Fallback";

export function TransactionsTable() {
  const { search } = useLocation();
  const [serviceZone] = useServiceZoneQueryParam();
  return (
    <Routes>
      <Route
        path="/aa-no-transaction-30-mins"
        element={
          <ErrorBoundary
            fallback={Fallback}
            key={"aANoTransaction30Mins" + (serviceZone ?? +"")}
          >
            <AaNoTransaction30MinsTable />
          </ErrorBoundary>
        }
      />

      <Route
        path="/no-transaction-2-hours"
        element={
          <ErrorBoundary
            fallback={Fallback}
            key={"noTransaction2Hours" + (serviceZone ?? +"")}
          >
            <NoTransaction2HoursTable />
          </ErrorBoundary>
        }
      />

      <Route
        path="/no-transaction-today"
        element={
          <ErrorBoundary
            fallback={Fallback}
            key={"noTransactionToday" + (serviceZone ?? +"")}
          >
            <NoTransactionTodayTable />
          </ErrorBoundary>
        }
      />

      <Route
        path="/bill-and-coin"
        element={
          <ErrorBoundary
            fallback={Fallback}
            key={"billAndCoin" + (serviceZone ?? +"")}
          >
            <BillAndCoinTable />
          </ErrorBoundary>
        }
      />

      <Route
        path="*"
        element={
          <Navigate to={routes.transactions.aaNoTransaction30Mins + search} />
        }
      />
    </Routes>
  );
}
