import { routes } from "../../../routes";
import { CompressorStatus, Data, MachineStatus, Mode } from "../types";
import * as errorBoardService from "../services";
import {
  GetMaintenanceBoardMachineErrorQuery,
  MaintenanceBoardSortField,
  MaintenanceBoardSortInput,
  SortingOrder,
} from "gql/generated";
import { SortDir } from "../hooks/useSortQueryParam";

const QUERY_PARAM_MAPPING = {
  "machine-id": MaintenanceBoardSortField.MachineId,
  timestamp: MaintenanceBoardSortField.Timestamp,
  "last-transaction": MaintenanceBoardSortField.LastTransaction,
  "staff-at-machine": MaintenanceBoardSortField.StaffAtMachine,
  "error-code": MaintenanceBoardSortField.ErrorCode,
  grade: MaintenanceBoardSortField.GradeWeekday,
};

function getSortEnum(
  sortDir?: SortDir,
  sortCol?: string
): MaintenanceBoardSortInput {
  if (!sortDir || !sortCol) {
    return null;
  }
  const sortField = QUERY_PARAM_MAPPING[sortCol];

  if (!sortField) {
    console.warn("Invalid sort column", sortCol);
    return null;
  }

  return {
    order: sortDir === "asc" ? SortingOrder.Asc : SortingOrder.Desc,
    orderBy: sortField,
  };
}

export async function fetchTableData({
  pathname,
  serviceZoneIds,
  cursor,
  sortDir,
  sortCol,
}: {
  pathname: string;
  serviceZoneIds: string[];
  sortDir?: SortDir;
  sortCol?: string;
  cursor?: string;
}): Promise<Pick<Data, "cursor" | "table">> {
  switch (pathname) {
    case routes.majorTrouble.machineUnsellable: {
      const response = await errorBoardService.getMachineUnsellableError({
        serviceZoneIds,
        cursor,
        sort: getSortEnum(sortDir, sortCol),
      });
      return {
        cursor: extractCursor(response.maintenanceBoard.pageInfo),
        table: {
          type: "MACHINE_UNSELLABLE",
          data: response.maintenanceBoard.edges.map((edge) => ({
            id: edge.cursor,
            machineId: edge.node.machineId,
            errorCode: edge.node.errorCode,
            descriptionEn: edge.node.errorDetail?.descriptionEn,
            descriptionTh: edge.node.errorDetail?.descriptionTh,
            grade: {
              weekday: edge.node.gradeWeekday,
              weekend: edge.node.gradeWeekend,
            },
            location: edge.node.location.locationName,
            serviceZone: edge.node.location.serviceZone.friendlyId,
            refillZone: edge.node.location.refillZone.friendlyId,
            date: new Date(edge.node.dateTime),
            lastTransaction: edge.node.lastTransaction
              ? new Date(edge.node.lastTransaction)
              : undefined,
            staffAtMachine: edge.node.staffAtMachine,
            staffName: edge.node.staffName,
          })),
        },
      };
    }
    case routes.majorTrouble.outOfService: {
      const response = await errorBoardService.getOutOfServiceError({
        serviceZoneIds,
        cursor,
        sort: getSortEnum(sortDir, sortCol),
      });
      return {
        cursor: extractCursor(response.maintenanceBoard.pageInfo),
        table: {
          type: "OUT_OF_SERVICE",
          data: response.maintenanceBoard.edges.map((edge) => ({
            id: edge.cursor,
            date: new Date(edge.node.dateTime),
            grade: {
              weekday: edge.node.gradeWeekday,
              weekend: edge.node.gradeWeekend,
            },
            errorCode: edge.node.errorCode,
            staffAtMachine: edge.node.staffAtMachine,
            staffName: edge.node.staffName,
            serviceZone: edge.node.location.serviceZone.friendlyId,
            refillZone: edge.node.location.refillZone.friendlyId,
            lastTransaction: edge.node.lastTransaction
              ? new Date(edge.node.lastTransaction)
              : undefined,
            machineId: edge.node.machineId,
            location: edge.node.location.locationName,
          })),
        },
      };
    }
    case routes.majorTrouble.offline: {
      const response = await errorBoardService.getOfflineError({
        serviceZoneIds,
        cursor,
        sort: getSortEnum(sortDir, sortCol),
      });
      return {
        cursor: extractCursor(response.maintenanceBoard.pageInfo),
        table: {
          type: "OFFLINE",
          data: response.maintenanceBoard.edges.map((edge) => ({
            id: edge.cursor,
            date: new Date(edge.node.dateTime),
            grade: {
              weekday: edge.node.gradeWeekday,
              weekend: edge.node.gradeWeekend,
            },
            errorCode: edge.node.errorCode,
            staffAtMachine: edge.node.staffAtMachine,
            staffName: edge.node.staffName,
            serviceZone: edge.node.location.serviceZone.friendlyId,
            refillZone: edge.node.location.refillZone.friendlyId,
            lastTransaction: edge.node.lastTransaction
              ? new Date(edge.node.lastTransaction)
              : undefined,
            machineId: edge.node.machineId,
            location: edge.node.location.locationName,
          })),
        },
      };
    }
    case routes.majorTrouble.iceError: {
      const response = await errorBoardService.getIceError({
        serviceZoneIds,
        cursor,
        sort: getSortEnum(sortDir, sortCol),
      });

      const compressorResponseToCompressorStatusMap: Record<
        string,
        CompressorStatus
      > = {
        IDLE: "idle",
        "Make Soda": "make_soda",
        "Make Ice": "make_ice",
      };

      return {
        cursor: extractCursor(response.maintenanceBoard.pageInfo),
        table: {
          type: "ICE_ERROR",
          data: response.maintenanceBoard.edges.map((edge) => ({
            id: edge.cursor,
            date: new Date(edge.node.dateTime),
            grade: {
              weekday: edge.node.gradeWeekday,
              weekend: edge.node.gradeWeekend,
            },
            rate:
              typeof edge.node.details?.rate === "string"
                ? Number(edge.node.details.rate)
                : undefined,
            temperature: edge.node.details?.temperature,
            compressorStatus:
              compressorResponseToCompressorStatusMap[
                edge.node.details?.compressor
              ] ?? (edge.node.details?.compressor as CompressorStatus),
            errorCode: edge.node.errorCode,
            descriptionEn: edge.node.errorDetail?.descriptionEn,
            descriptionTh: edge.node.errorDetail?.descriptionTh,
            staffAtMachine: edge.node.staffAtMachine,
            staffName: edge.node.staffName,
            serviceZone: edge.node.location.serviceZone.friendlyId,
            refillZone: edge.node.location.refillZone.friendlyId,
            machineId: edge.node.machineId,
            location: edge.node.location.locationName,
          })),
        },
      };
    }

    case routes.majorTrouble.coffeeError: {
      const response = await errorBoardService.getCoffeeError({
        serviceZoneIds,
        cursor,
        sort: getSortEnum(sortDir, sortCol),
      });
      return {
        cursor: extractCursor(response.maintenanceBoard.pageInfo),
        table: {
          type: "COFFEE_ERROR",
          data: response.maintenanceBoard.edges.map((edge) => ({
            id: edge.cursor,
            date: new Date(edge.node.dateTime),
            grade: {
              weekday: edge.node.gradeWeekday,
              weekend: edge.node.gradeWeekend,
            },
            errorCode: edge.node.errorCode,
            descriptionEn: edge.node.errorDetail?.descriptionEn,
            descriptionTh: edge.node.errorDetail?.descriptionTh,
            staffAtMachine: edge.node.staffAtMachine,
            staffName: edge.node.staffName,
            serviceZone: edge.node.location.serviceZone.friendlyId,
            refillZone: edge.node.location.refillZone.friendlyId,
            machineId: edge.node.machineId,
            location: edge.node.location.locationName,
          })),
        },
      };
    }
    case routes.majorTrouble.iceRateLow: {
      const response = await errorBoardService.getIceRateLow({
        serviceZoneIds,
        cursor,
        sort: getSortEnum(sortDir, sortCol),
      });
      return {
        cursor: extractCursor(response.maintenanceBoard.pageInfo),
        table: {
          type: "ICE_RATE_LOW",
          data: response.maintenanceBoard.edges.map((edge) => ({
            id: edge.cursor,
            rate:
              edge.node.details?.rate && !isNaN(Number(edge.node.details.rate))
                ? Number(edge.node.details.rate)
                : undefined,
            machineId: edge.node.machineId,
            grade: {
              weekday: edge.node.gradeWeekday,
              weekend: edge.node.gradeWeekend,
            },
            date: new Date(edge.node.dateTime),
            staffAtMachine: edge.node.staffAtMachine,
            staffName: edge.node.staffName,
            serviceZone: edge.node.location.serviceZone.friendlyId,
            refillZone: edge.node.location.refillZone.friendlyId,
            location: edge.node.location.locationName,
          })),
        },
      };
    }
    case routes.majorTrouble.sodaError: {
      const response = await errorBoardService.getSodaError({
        serviceZoneIds,
        cursor,
        sort: getSortEnum(sortDir, sortCol),
      });

      const modeResponseToMode: Record<string, Mode> = {
        IDLE: "idle",
        MAKE_ICE: "make_ice",
        MAKE_SODA: "make_soda",
        CLOSED_BY_ACTION_ON_SCREEN: "closed",
        DEFROST: "defrost",
      };

      return {
        cursor: extractCursor(response.maintenanceBoard.pageInfo),
        table: {
          type: "SODA_ERROR",
          data: response.maintenanceBoard.edges.map((edge) => ({
            id: edge.cursor,
            date: new Date(edge.node.dateTime),
            grade: {
              weekday: edge.node.gradeWeekday,
              weekend: edge.node.gradeWeekend,
            },
            probe1:
              typeof edge.node.details?.probe1 === "string"
                ? Number(edge.node.details.probe1)
                : undefined,
            probe2:
              typeof edge.node.details?.probe2 === "string"
                ? Number(edge.node.details.probe2)
                : undefined,
            rate:
              typeof edge.node.details?.rate === "string"
                ? Number(edge.node.details.rate)
                : undefined,
            remain:
              typeof edge.node.details?.remain === "string"
                ? Number(edge.node.details.remain)
                : undefined,
            temperature: edge.node.details?.temperature,
            psi:
              typeof edge.node.details?.psi === "string"
                ? Number(edge.node.details.psi)
                : undefined,
            co2Out:
              typeof edge.node.details?.psi === "string"
                ? Number(edge.node.details.psi) < 65
                : undefined,
            mode:
              modeResponseToMode[edge.node.details?.mode] ??
              (edge.node.details?.mode as Mode),
            errorCode: edge.node.errorCode,
            descriptionEn: edge.node.errorDetail?.descriptionEn,
            descriptionTh: edge.node.errorDetail?.descriptionTh,
            staffAtMachine: edge.node.staffAtMachine,
            staffName: edge.node.staffName,
            serviceZone: edge.node.location.serviceZone.friendlyId,
            refillZone: edge.node.location.refillZone.friendlyId,
            machineId: edge.node.machineId,
            location: edge.node.location.locationName,
          })),
        },
      };
    }
    case routes.majorTrouble.spiralUnsellable: {
      const response = await errorBoardService.getSpiralUnsellableError({
        serviceZoneIds,
        cursor,
        sort: getSortEnum(sortDir, sortCol),
      });
      return {
        cursor: extractCursor(response.maintenanceBoard.pageInfo),
        table: {
          type: "SPIRAL_UNSELLABLE",
          data: response.maintenanceBoard.edges.map((edge) => ({
            id: edge.cursor,
            machineId: edge.node.machineId,
            errorCode: edge.node.errorCode,
            descriptionEn: edge.node.errorDetail?.descriptionEn,
            descriptionTh: edge.node.errorDetail?.descriptionTh,
            grade: {
              weekday: edge.node.gradeWeekday,
              weekend: edge.node.gradeWeekend,
            },
            location: edge.node.location.locationName,
            serviceZone: edge.node.location.serviceZone.friendlyId,
            refillZone: edge.node.location.refillZone.friendlyId,
            date: new Date(edge.node.dateTime),
            lastTransaction: edge.node.lastTransaction
              ? new Date(edge.node.lastTransaction)
              : undefined,
            staffAtMachine: edge.node.staffAtMachine,
            staffName: edge.node.staffName,
          })),
        },
      };
    }
    case routes.majorTrouble.whipper: {
      const response = await errorBoardService.getWhipper({
        serviceZoneIds,
        cursor,
        sort: getSortEnum(sortDir, sortCol),
      });
      return {
        cursor: extractCursor(response.maintenanceBoard.pageInfo),
        table: {
          type: "WHIPPER",
          data: response.maintenanceBoard.edges.map((edge) => ({
            id: edge.cursor,
            date: new Date(edge.node.dateTime),
            grade: {
              weekday: edge.node.gradeWeekday,
              weekend: edge.node.gradeWeekend,
            },
            errorCode: edge.node.errorCode,
            descriptionEn: edge.node.errorDetail?.descriptionEn,
            descriptionTh: edge.node.errorDetail?.descriptionTh,
            staffAtMachine: edge.node.staffAtMachine,
            staffName: edge.node.staffName,
            serviceZone: edge.node.location.serviceZone.friendlyId,
            refillZone: edge.node.location.refillZone.friendlyId,
            machineId: edge.node.machineId,
            location: edge.node.location.locationName,
          })),
        },
      };
    }
    case routes.inventory.milk: {
      const response = await errorBoardService.getMilkError({
        serviceZoneIds,
        cursor,
        sort: getSortEnum(sortDir, sortCol),
      });
      return {
        cursor: extractCursor(response.maintenanceBoard.pageInfo),
        table: {
          type: "MILK",
          data: response.maintenanceBoard.edges.map((edge) => ({
            id: edge.cursor,
            date: new Date(edge.node.dateTime),
            grade: {
              weekday: edge.node.gradeWeekday,
              weekend: edge.node.gradeWeekend,
            },
            errorCode: edge.node.errorCode,
            descriptionEn: edge.node.errorDetail?.descriptionEn,
            descriptionTh: edge.node.errorDetail?.descriptionTh,
            staffAtMachine: edge.node.staffAtMachine,
            staffName: edge.node.staffName,
            serviceZone: edge.node.location.serviceZone.friendlyId,
            refillZone: edge.node.location.refillZone.friendlyId,
            machineId: edge.node.machineId,
            location: edge.node.location.locationName,
          })),
        },
      };
    }
    case routes.inventory.lid: {
      const response = await errorBoardService.getLidError({
        serviceZoneIds,
        cursor,
        sort: getSortEnum(sortDir, sortCol),
      });
      return {
        cursor: extractCursor(response.maintenanceBoard.pageInfo),
        table: {
          type: "LID",
          data: response.maintenanceBoard.edges.map((edge) => ({
            id: edge.cursor,
            date: new Date(edge.node.dateTime),
            grade: {
              weekday: edge.node.gradeWeekday,
              weekend: edge.node.gradeWeekend,
            },
            errorCode: edge.node.errorCode,
            descriptionEn: edge.node.errorDetail?.descriptionEn,
            descriptionTh: edge.node.errorDetail?.descriptionTh,
            staffAtMachine: edge.node.staffAtMachine,
            staffName: edge.node.staffName,
            serviceZone: edge.node.location.serviceZone.friendlyId,
            refillZone: edge.node.location.refillZone.friendlyId,
            machineId: edge.node.machineId,
            location: edge.node.location.locationName,
          })),
        },
      };
    }
    case routes.inventory.other: {
      const response = await errorBoardService.getOtherError({
        serviceZoneIds,
        cursor,
        sort: getSortEnum(sortDir, sortCol),
      });
      return {
        cursor: extractCursor(response.maintenanceBoard.pageInfo),
        table: {
          type: "OTHER",
          data: response.maintenanceBoard.edges.map((edge) => ({
            id: edge.cursor,
            date: new Date(edge.node.dateTime),
            grade: {
              weekday: edge.node.gradeWeekday,
              weekend: edge.node.gradeWeekend,
            },
            slot: edge.node.details?.slot,
            errorCode: edge.node.errorCode,
            descriptionEn: edge.node.errorDetail?.descriptionEn,
            descriptionTh: edge.node.errorDetail?.descriptionTh,
            staffAtMachine: edge.node.staffAtMachine,
            staffName: edge.node.staffName,
            serviceZone: edge.node.location.serviceZone.friendlyId,
            refillZone: edge.node.location.refillZone.friendlyId,
            machineId: edge.node.machineId,
            location: edge.node.location.locationName,
          })),
        },
      };
    }
    case routes.inventory.sugar: {
      const response = await errorBoardService.getSugarError({
        serviceZoneIds,
        cursor,
        sort: getSortEnum(sortDir, sortCol),
      });
      return {
        cursor: extractCursor(response.maintenanceBoard.pageInfo),
        table: {
          type: "SUGAR",
          data: response.maintenanceBoard.edges.map((edge) => ({
            id: edge.cursor,
            date: new Date(edge.node.dateTime),
            grade: {
              weekday: edge.node.gradeWeekday,
              weekend: edge.node.gradeWeekend,
            },
            errorCode: edge.node.errorCode,
            descriptionEn: edge.node.errorDetail?.descriptionEn,
            descriptionTh: edge.node.errorDetail?.descriptionTh,
            staffAtMachine: edge.node.staffAtMachine,
            staffName: edge.node.staffName,
            serviceZone: edge.node.location.serviceZone.friendlyId,
            refillZone: edge.node.location.refillZone.friendlyId,
            machineId: edge.node.machineId,
            location: edge.node.location.locationName,
          })),
        },
      };
    }
    case routes.inventory.coffeeBeans: {
      const response = await errorBoardService.getCoffeeBeans({
        serviceZoneIds,
        cursor,
        sort: getSortEnum(sortDir, sortCol),
      });
      return {
        cursor: extractCursor(response.maintenanceBoard.pageInfo),
        table: {
          type: "COFFEE_BEANS",
          data: response.maintenanceBoard.edges.map((edge) => ({
            id: edge.cursor,
            slot: edge.node.details?.slot,
            remain:
              typeof edge.node?.details?.remain === "string"
                ? Number(edge.node.details.remain)
                : undefined,
            date: new Date(edge.node.dateTime),
            grade: {
              weekday: edge.node.gradeWeekday,
              weekend: edge.node.gradeWeekend,
            },
            errorCode: edge.node.errorCode,
            descriptionEn: edge.node.errorDetail?.descriptionEn,
            descriptionTh: edge.node.errorDetail?.descriptionTh,
            staffAtMachine: edge.node.staffAtMachine,
            staffName: edge.node.staffName,
            serviceZone: edge.node.location.serviceZone.friendlyId,
            refillZone: edge.node.location.refillZone.friendlyId,
            machineId: edge.node.machineId,
            location: edge.node.location.locationName,
          })),
        },
      };
    }
    case routes.inventory.espressoCups: {
      const response = await errorBoardService.getEspressoCups({
        serviceZoneIds,
        cursor,
        sort: getSortEnum(sortDir, sortCol),
      });
      return {
        cursor: extractCursor(response.maintenanceBoard.pageInfo),
        table: {
          type: "ESPRESSO_CUPS",
          data: response.maintenanceBoard.edges.map((edge) => ({
            id: edge.cursor,
            date: new Date(edge.node.dateTime),
            grade: {
              weekday: edge.node.gradeWeekday,
              weekend: edge.node.gradeWeekend,
            },
            machineId: edge.node.machineId,
            errorCode: edge.node.errorCode,
            descriptionEn: edge.node.errorDetail?.descriptionEn,
            descriptionTh: edge.node.errorDetail?.descriptionTh,
            staffAtMachine: edge.node.staffAtMachine,
            staffName: edge.node.staffName,
            serviceZone: edge.node.location.serviceZone.friendlyId,
            refillZone: edge.node.location.refillZone.friendlyId,
            location: edge.node.location.locationName,
          })),
        },
      };
    }
    case routes.inventory.paperCups: {
      const response = await errorBoardService.getPaperCups({
        serviceZoneIds,
        cursor,
        sort: getSortEnum(sortDir, sortCol),
      });
      return {
        cursor: extractCursor(response.maintenanceBoard.pageInfo),
        table: {
          type: "PAPER_CUPS",
          data: response.maintenanceBoard.edges.map((edge) => ({
            id: edge.cursor,
            date: new Date(edge.node.dateTime),
            grade: {
              weekday: edge.node.gradeWeekday,
              weekend: edge.node.gradeWeekend,
            },
            machineId: edge.node.machineId,
            errorCode: edge.node.errorCode,
            descriptionEn: edge.node.errorDetail?.descriptionEn,
            descriptionTh: edge.node.errorDetail?.descriptionTh,
            staffAtMachine: edge.node.staffAtMachine,
            staffName: edge.node.staffName,
            serviceZone: edge.node.location.serviceZone.friendlyId,
            refillZone: edge.node.location.refillZone.friendlyId,
            location: edge.node.location.locationName,
          })),
        },
      };
    }
    case routes.inventory.plasticCups: {
      const response = await errorBoardService.getPlasticCups({
        serviceZoneIds,
        cursor,
        sort: getSortEnum(sortDir, sortCol),
      });
      return {
        cursor: extractCursor(response.maintenanceBoard.pageInfo),
        table: {
          type: "PLASTIC_CUPS",
          data: response.maintenanceBoard.edges.map((edge) => ({
            id: edge.cursor,
            date: new Date(edge.node.dateTime),
            grade: {
              weekday: edge.node.gradeWeekday,
              weekend: edge.node.gradeWeekend,
            },
            machineId: edge.node.machineId,
            errorCode: edge.node.errorCode,
            descriptionEn: edge.node.errorDetail?.descriptionEn,
            descriptionTh: edge.node.errorDetail?.descriptionTh,
            staffAtMachine: edge.node.staffAtMachine,
            staffName: edge.node.staffName,
            serviceZone: edge.node.location.serviceZone.friendlyId,
            refillZone: edge.node.location.refillZone.friendlyId,
            location: edge.node.location.locationName,
          })),
        },
      };
    }
    case routes.inventory.co2: {
      const response = await errorBoardService.getCO2OutError({
        serviceZoneIds,
        cursor,
        sort: getSortEnum(sortDir, sortCol),
      });
      const modeResponseToMode: Record<string, Mode> = {
        IDLE: "idle",
        MAKE_ICE: "make_ice",
        MAKE_SODA: "make_soda",
        CLOSED_BY_ACTION_ON_SCREEN: "closed",
        DEFROST: "defrost",
      };
      return {
        cursor: extractCursor(response.maintenanceBoard.pageInfo),
        table: {
          type: "CO2_OUT",
          data: response.maintenanceBoard.edges.map((edge) => ({
            id: edge.cursor,
            date: new Date(edge.node.dateTime),
            grade: {
              weekday: edge.node.gradeWeekday,
              weekend: edge.node.gradeWeekend,
            },
            machineId: edge.node.machineId,
            errorCode: edge.node.errorCode,
            descriptionEn: edge.node.errorDetail?.descriptionEn,
            descriptionTh: edge.node.errorDetail?.descriptionTh,
            staffAtMachine: edge.node.staffAtMachine,
            staffName: edge.node.staffName,
            serviceZone: edge.node.location.serviceZone.friendlyId,
            refillZone: edge.node.location.refillZone.friendlyId,
            location: edge.node.location.locationName,
            co2Out: parseInt(edge.node.details.psi) < 65,
            details: {
              rate: edge.node.details.rate,
              remain: edge.node.details.remain,
              psi: edge.node.details.psi,
              probe1: edge.node.details.probe1,
              probe2: edge.node.details.probe2,
              mode: modeResponseToMode[edge.node.details.mode],
              compressor: edge.node.details.compressor,
              temperature: edge.node.details.temperature,
              slot: edge.node.details.slot,
              lastRefilled: edge.node.details.lastRefilled,
            }
          })),
        },
      };
    }

    case routes.transactions.aaNoTransaction30Mins: {
      const response = await errorBoardService.getAaNoTransaction30Mins({
        serviceZoneIds,
        cursor,
        sort: getSortEnum(sortDir, sortCol),
      });
      return {
        cursor: extractCursor(response.maintenanceBoard.pageInfo),
        table: {
          type: "AA_NO_TRANSACTION_30_MINS",
          data: response.maintenanceBoard.edges.map((edge) => ({
            id: edge.cursor,
            grade: {
              weekday: edge.node.gradeWeekday,
              weekend: edge.node.gradeWeekend,
            },
            staffAtMachine: edge.node.staffAtMachine,
            staffName: edge.node.staffName,
            serviceZone: edge.node.location.serviceZone.friendlyId,
            refillZone: edge.node.location.refillZone.friendlyId,
            lastTransaction: edge.node.lastTransaction
              ? new Date(edge.node.lastTransaction)
              : undefined,
            machineId: edge.node.machineId,
            location: edge.node.location.locationName,
          })),
        },
      };
    }

    case routes.transactions.noTransaction2Hours: {
      const response = await errorBoardService.getNoTransaction2Hours({
        serviceZoneIds,
        cursor,
        sort: getSortEnum(sortDir, sortCol),
      });
      return {
        cursor: extractCursor(response.maintenanceBoard.pageInfo),
        table: {
          type: "NO_TRANSACTION_2_HOURS",
          data: response.maintenanceBoard.edges.map((edge) => ({
            id: edge.cursor,
            grade: {
              weekday: edge.node.gradeWeekday,
              weekend: edge.node.gradeWeekend,
            },
            staffAtMachine: edge.node.staffAtMachine,
            staffName: edge.node.staffName,
            serviceZone: edge.node.location.serviceZone.friendlyId,
            refillZone: edge.node.location.refillZone.friendlyId,
            lastTransaction: edge.node.lastTransaction
              ? new Date(edge.node.lastTransaction)
              : undefined,
            machineId: edge.node.machineId,
            location: edge.node.location.locationName,
          })),
        },
      };
    }

    case routes.transactions.noTransactionToday: {
      const response = await errorBoardService.getNoTransactionToday({
        serviceZoneIds,
        cursor,
        sort: getSortEnum(sortDir, sortCol),
      });
      return {
        cursor: extractCursor(response.maintenanceBoard.pageInfo),
        table: {
          type: "NO_TRANSACTION_TODAY",
          data: response.maintenanceBoard.edges.map((edge) => ({
            id: edge.cursor,
            grade: {
              weekday: edge.node.gradeWeekday,
              weekend: edge.node.gradeWeekend,
            },
            status: edge.node.machine.status as MachineStatus,
            staffAtMachine: edge.node.staffAtMachine,
            staffName: edge.node.staffName,
            serviceZone: edge.node.location.serviceZone.friendlyId,
            refillZone: edge.node.location.refillZone.friendlyId,
            lastTransaction: edge.node.lastTransaction
              ? new Date(edge.node.lastTransaction)
              : undefined,
            machineId: edge.node.machineId,
            location: edge.node.location.locationName,
          })),
        },
      };
    }

    case routes.transactions.billAndCoin: {
      const response = await errorBoardService.getBillAndCoin({
        serviceZoneIds,
        cursor,
        sort: getSortEnum(sortDir, sortCol),
      });

      return {
        cursor: extractCursor(response.maintenanceBoard.pageInfo),
        table: {
          type: "BILL_AND_COIN",
          data: response.maintenanceBoard.edges.map((edge) => ({
            id: edge.cursor,
            date: new Date(edge.node.dateTime),
            grade: {
              weekday: edge.node.gradeWeekday,
              weekend: edge.node.gradeWeekend,
            },
            errorCode: edge.node.errorCode,
            descriptionEn: edge.node.errorDetail?.descriptionEn,
            descriptionTh: edge.node.errorDetail?.descriptionTh,
            staffAtMachine: edge.node.staffAtMachine,
            staffName: edge.node.staffName,
            serviceZone: edge.node.location.serviceZone.friendlyId,
            refillZone: edge.node.location.refillZone.friendlyId,
            machineId: edge.node.machineId,
            location: edge.node.location.locationName,
          })),
        },
      };
    }

    default:
      return Promise.reject("Invalid URL");
  }
}
function extractCursor(
  pageInfo: GetMaintenanceBoardMachineErrorQuery["maintenanceBoard"]["pageInfo"]
): string {
  return pageInfo.hasNextPage ? pageInfo.endCursor : undefined;
}
