import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ErrorBoard } from "./pages/ErrorBoard";

import { ErrorBoundary } from "@sentry/react";
import { Fallback } from "components/Fallback";
import * as styles from "./Maintenance.module.scss";
import { routes } from "./routes";

export function Maintenance() {
  return (
    <div className={styles.Maintenance}>
      <ErrorBoundary fallback={Fallback}>
        <Routes>
          <Route path="/error-board/*" element={<ErrorBoard />} />
          <Route path="*" element={<Navigate to={routes.majorTrouble.machineUnsellable} replace />} />
        </Routes>
      </ErrorBoundary>
    </div>
  );
}
