export const SERVICE_ZONE_QUERY_PARAM = 'id';

const BASE_ROOT = "/maintenance";
const ERROR_BOARD_BASE = `${BASE_ROOT}/error-board`;
const MAJOR_TROUBLE_BASE = `${ERROR_BOARD_BASE}/major-trouble`;
const INVENTORY_BASE = `${ERROR_BOARD_BASE}/inventory`;
const TRANSACTIONS_BASE = `${ERROR_BOARD_BASE}/transactions`;

export const routes = {
  root: `${BASE_ROOT}`,
  errorBoard: `${ERROR_BOARD_BASE}`,
  majorTrouble: {
    root: `${MAJOR_TROUBLE_BASE}`,
    machineUnsellable: `${MAJOR_TROUBLE_BASE}/machine-unsellable`,
    outOfService: `${MAJOR_TROUBLE_BASE}/out-of-service`,
    offline: `${MAJOR_TROUBLE_BASE}/offline`,
    iceError: `${MAJOR_TROUBLE_BASE}/ice-error`,
    coffeeError: `${MAJOR_TROUBLE_BASE}/coffee-error`,
    iceRateLow: `${MAJOR_TROUBLE_BASE}/ice-rate-low`,
    sodaError: `${MAJOR_TROUBLE_BASE}/soda-error`,
    whipper: `${MAJOR_TROUBLE_BASE}/whipper`,
    spiralUnsellable: `${MAJOR_TROUBLE_BASE}/spiral-unsellable`,
  },
  inventory: {
    root: `${INVENTORY_BASE}`,
    milk: `${INVENTORY_BASE}/milk`,
    sugar: `${INVENTORY_BASE}/sugar`,
    lid: `${INVENTORY_BASE}/lid`,
    other: `${INVENTORY_BASE}/other`,
    coffeeBeans: `${INVENTORY_BASE}/coffee-beans`,
    plasticCups: `${INVENTORY_BASE}/plastic-cups`,
    paperCups: `${INVENTORY_BASE}/paper-cups`,
    espressoCups: `${INVENTORY_BASE}/espresso-cups`,
    co2: `${INVENTORY_BASE}/co2`
  },
  transactions: {
    root: `${TRANSACTIONS_BASE}`,
    aaNoTransaction30Mins: `${TRANSACTIONS_BASE}/aa-no-transaction-30-mins`,
    noTransaction2Hours: `${TRANSACTIONS_BASE}/no-transaction-2-hours`,
    noTransactionToday: `${TRANSACTIONS_BASE}/no-transaction-today`,
    billAndCoin: `${TRANSACTIONS_BASE}/bill-and-coin`,
  },
};


export function getServiceZoneQueryParam(serviceZoneId?: string | string[]) {
  if (!serviceZoneId) return '';

  if (typeof serviceZoneId === 'string')
    return `?${SERVICE_ZONE_QUERY_PARAM}=${serviceZoneId}`;

  if (serviceZoneId.length === 0) return '';
  const urlParams = new URLSearchParams();
  serviceZoneId.forEach((id) => urlParams.append(SERVICE_ZONE_QUERY_PARAM, id));
  return `?${urlParams.toString()}`;
}
