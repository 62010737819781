import * as React from 'react';
import { useParams } from 'react-router-dom';

import Typography from 'common/components/typography/Typography';
import Table from 'common/components/table/Table';
import { TextStringCell } from 'common/components/table/cells/Cells';

import { useGetMachineModuleStatus } from 'machines/hooks/useGetMachineStatus';

import { IMachineModuleStatus } from 'machines/interfaces';

import * as styles from '../status/Status.module.scss';

import NoPermissionComponent from 'containers/noPermissionPage/NoPermission';
import { useIsRouteman } from 'utils/user-role';

function Status(): JSX.Element {
  const cannotViewContent = useIsRouteman();

  if (cannotViewContent) {
    return <NoPermissionComponent />;
  }
  const { machineId } = useParams();

  const { data: machineStatus, isLoading } =
    useGetMachineModuleStatus(machineId);

  return (
    <div className={styles.MachineStatus}>
      <div className={styles.header}>
        <Typography translationKey="label_status" type="headline-5" />
      </div>
      <div className={styles.table}>
        <Table
          data={isLoading ? undefined : mapDataToTable(machineStatus)}
          columns={[
            {
              dataKey: 'status',
              headerLabel: 'label_machine_status',
              cellRenderer: TextStringCell,
              align: 'flex-start',
              columnFlex: 1,
              showMobile: true,
            },
            {
              dataKey: 'value',
              headerLabel: 'label_value',
              cellRenderer: TextStringCell,
              align: 'flex-start',
              columnFlex: 1,
              showMobile: true,
            },
          ]}
          loading={isLoading}
          loadingRows={20}
        />
      </div>
    </div>
  );
}

export default Status;

interface ITableRow {
  status: string;
  value: string;
}

function mapDataToTable(
  machineStatuses: IMachineModuleStatus
): Array<ITableRow> {
  if (machineStatuses === undefined) return undefined;

  return Object.keys(machineStatuses)
    .map((status) => {
      return { status: deCameliseCase(status), value: machineStatuses[status] };
    })
    .sort((a, b) => a.status.localeCompare(b.status));
}

function deCameliseCase(word: string) {
  const noCamelCase = word.replace(/([A-Z])/g, ' $1');
  const result =
    noCamelCase.charAt(0).toUpperCase() +
    noCamelCase.slice(1).toLocaleLowerCase();
  return result;
}
