import * as amplitude from "@amplitude/analytics-browser";
import config from "common/config";

const AmplitudeApiKey = config.AMPLITUDE_API_KEY;

if (!AmplitudeApiKey) {
  console.error("Amplitude API key is not defined in environment variables.");
} else {
  amplitude.init(AmplitudeApiKey, {
    defaultTracking: true,
  });
}

export type TrackProps = {
  name: string;
  properties: { [key: string]: any };
};

function track({ name, properties }: TrackProps) {
  const defaultProps = {
    page_url: window.location.href,
    page_path: window.location.pathname
  };
  amplitude.track({
    
    event_type: name,
    event_properties: { ...defaultProps, ...properties },
  });
}

function reset() {
  amplitude.reset();
}

type IdentifyType = {
  userId: string;
  properties: { [key: string]: any };
};
function identifyUser({ userId, properties }: IdentifyType) {
  amplitude.setUserId(userId);
  const identify = new amplitude.Identify();
  

  // Iterate over the properties object and set each property.
  Object.keys(properties).forEach((key) => {
    identify.set(key, properties[key]);
  });
  amplitude.identify(identify);
}

const analytics = {
  track,
  reset,
  identifyUser,
};

export default analytics;
