import { clsx } from "clsx";
import Icon from "common/components/icon/Icon";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { ConsumptionForecastModal } from "./ConsumptionForecastModal";
type RemainingCellProps = {
  onClick?: () => void;
  tier: number;
  UOM: string;
  current: number;
  amountTaken: number;
  selected: boolean;
  capacity: number;
  consumptionForecast: {
    days: number;
    hours: number;
  };
  productName: string;
  materialUsage: {
    weekdayUsage: number;
    weekendUsage: number;
  };
};

export function RemainingCell({
  tier,
  productName,
  UOM,
  selected,
  current,
  amountTaken,
  capacity,
  consumptionForecast,
  materialUsage,
}: RemainingCellProps) {
  const [consumptionForecastModal, setConsumptionForecastModal] =
    React.useState(false);
  const { t } = useTranslation("operation", {
    keyPrefix: "kitting.modal.kitting_table",
  });

  const IsNoConsumption =
    materialUsage.weekdayUsage === 0 && materialUsage.weekendUsage === 0;

  return (
    <>
      <div
        onClick={() => setConsumptionForecastModal(true)}
        className="cursor-pointer"
      >
        <p className="text-body3 text-on-surface-high flex gap-1 whitespace-nowrap">
          <span>{`${current} ${amountTaken && selected ? "" : UOM}`}</span>

          {amountTaken && selected ? (
            <span className="font-bold text-primary">
              {"+ " + amountTaken.toFixed(1)}
              &nbsp;{UOM}
            </span>
          ) : null}

          <span>/ {capacity.toFixed(1)}</span>
        </p>
        <p className="text-body3 flex gap-1 items-center">
          {!IsNoConsumption && t("label_time_until_inventory_runout")}
          {IsNoConsumption ? (
            <span className="text-warning">{t("label_no_consumption_usage")}</span>
          ) : (
            <EstimateTimeText
              days={consumptionForecast.days}
              hours={consumptionForecast.hours}
              refillFrequencyDays={tier}
              IsNoConsumption={IsNoConsumption}
            />
          )}
          <Icon name="Info" color="primary" className="w-3.5 h-3.5" />
        </p>
      </div>
      <ConsumptionForecastModal
        tier={tier}
        open={consumptionForecastModal}
        onClose={() => setConsumptionForecastModal(false)}
        name={productName}
        capacity={capacity}
        currentLevel={current}
        refillAmount={amountTaken}
        consumptionForecast={{
          ...consumptionForecast,
          weekdayUsage: materialUsage.weekdayUsage,
          weekendUsage: materialUsage.weekendUsage,
        }}
        uom={UOM}
      />
    </>
  );
}

export function EstimateTimeText({
  days,
  hours,
  className,
  refillFrequencyDays,
  IsNoConsumption,
}: {
  hours: number;
  days: number;
  className?: string;
  refillFrequencyDays: number;
  IsNoConsumption: boolean;
}) {
  const { t } = useTranslation("operation", {
    keyPrefix: "kitting.modal.kitting_table",
  });

  if(IsNoConsumption) return <span className={clsx("text-warning", className)}>{t("label_no_consumption_usage")}</span>;

  // Calculate total hours left
  const totalHoursLeft = days * 24 + hours;

  // Determine the color based on the conditions
  const color =
    totalHoursLeft < 24
      ? "text-error"
      : totalHoursLeft < refillFrequencyDays * 24 + 12
        ? "text-warning"
        : "text-success";

  return (
    <strong className={clsx("font-bold", color, className)}>
      {days !== 0 &&
        t("label_time_runout_days", {
          days: days,
        })}
      &nbsp;
      {t("label_time_runout_hours", {
        hours: hours,
      })}
    </strong>
  );
}

export function formatTimeDifference(dateTimeISO: string) {
  const currentTime = new Date();
  const inputTime = new Date(dateTimeISO);

  // Calculate the difference in milliseconds
  const differenceInMs = Math.max(
    0,
    inputTime.getTime() - currentTime.getTime()
  );

  // Convert the difference to hours
  const totalHours = Math.floor(differenceInMs / (1000 * 60 * 60));

  // Calculate days and hours
  const days = Math.floor(totalHours / 24);
  const hours = totalHours % 24;

  return {
    days,
    hours,
  };
}
