import * as React from 'react';
import { useParams } from 'react-router-dom';

import Typography from 'common/components/typography/Typography';
import Modal from 'common/components/modal/Modal';
import Button from 'common/components/button/Button';
import { IMachineUser } from 'machines/interfaces';

import { useDeleteMachineUser } from 'machines/hooks/useDeleteMachineUser';

import * as styles from './RemoveMachineUserModal.module.scss';

function RemoveMachineUserModal({ onClose, userData }: IRemoveUserProps) {
  const { mutate: deleteUser } = useDeleteMachineUser();
  const { machineId } = useParams();

  function handleRemove(): void {
    const userSelected: IMachineUser = {
      userId: userData.id,
      machineId: machineId,
    };
    deleteUser(userSelected, { onSuccess: () => onClose() });
  }

  return (
    <Modal contentClassName={styles.modal}>
      <div className={styles.RemoveMachineUserModal}>
        <header>
          <Typography type='headline-6' translationKey='label_remove_user' />
        </header>
        <div className={styles.warning}>
          <Typography
            className={styles.name}
            type='subtitle-1'
            text={userData.email}
          />
          <Typography
            type='subtitle-1'
            translationKey='warning_remove_user_from_machine'
          />
        </div>
        <div className={styles.confirmText}>
          <Typography
            type='subtitle-1'
            translationKey='warning_remove_user_confirmation_label'
          />
        </div>
        <footer className={styles.action}>
          <Button
            translationKey='action_cancel'
            onClick={onClose}
            type='secondary'
          />
          <Button
            translationKey='action_remove'
            onClick={handleRemove}
            type='status'
          />
        </footer>
      </div>
    </Modal>
  );
}

export default RemoveMachineUserModal;

interface IRemoveUserProps {
  onClose: () => void;
  userData: IUserData;
}
interface IUserData {
  email: string;
  id: string;
}
