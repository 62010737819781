import * as React from "react";
import LocationList from "./screens/LocationList";
import LocationId from "./screens/LocationId";
import EditLocationId from "./screens/EditLocationId";

import { Navigate, Route, Routes } from "react-router-dom";
import { Fallback } from "components/Fallback";
import { ErrorBoundary } from "@sentry/react";

function Location(): JSX.Element {
  return (
    <Routes>
      <Route
        path="/list"
        element={
          <ErrorBoundary fallback={LocationFallback} key="location-list">
            <LocationList />
          </ErrorBoundary>
        }
      />

      <Route
        path="/:id/edit"
        element={
          <ErrorBoundary fallback={LocationFallback} key="location-edit">
            <EditLocationId />
          </ErrorBoundary>
        }
      />

      <Route
        path="/:id"
        element={
          <ErrorBoundary fallback={LocationFallback} key="location">
            <LocationId />
          </ErrorBoundary>
        }
      />

      <Route path="*" element={<Navigate to="/location/list" />} />
    </Routes>
  );
}

function LocationFallback({ error }: { error: Error }) {
  return (
    <div
      style={{
        margin: "-20px -35px",
        flex: "1 1 0",
        minHeight: 0,
      }}
    >
      <Fallback error={error} />
    </div>
  );
}

export default Location;
