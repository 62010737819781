import * as React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { routes } from "../../../../routes";
import { useServiceZoneQueryParam } from "../../hooks/useServiceZoneQueryParam";
import { ErrorBoundary } from "@sentry/react";
import { MachineUnsellableTable } from "./MachineUnsellableTable";
import { OutOfServiceTable } from "./OutOfServiceTable";
import { OfflineTable } from "./OfflineTable";
import { IceErrorTable } from "./IceErrorTable";
import { CoffeeErrorTable } from "./CoffeeErrorTable";
import { IceRateLowTable } from "./IceRateLowTable";
import { SodaErrorTable } from "./SodaErrorTable";
import { Fallback } from "../../components/Fallback";
import { SpiralUnsellableTable } from "./SpiralUnsellableTable";
import { WhipperTable } from "./WhipperTable";

export function MajorTroubleTables() {
  const { search } = useLocation();
  const [serviceZone] = useServiceZoneQueryParam();

  return (
    <Routes>
      <Route
        path="/machine-unsellable"
        element={
          <ErrorBoundary
            fallback={Fallback}
            key={"machine-unsellable" + (serviceZone ?? +"")}
          >
            <MachineUnsellableTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/out-of-service"
        element={
          <ErrorBoundary
            fallback={Fallback}
            key={"out-of-service" + (serviceZone ?? +"")}
          >
            <OutOfServiceTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/offline"
        element={
          <ErrorBoundary
            fallback={Fallback}
            key={"offline" + (serviceZone ?? +"")}
          >
            <OfflineTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/ice-error"
        element={
          <ErrorBoundary
            fallback={Fallback}
            key={"ice-error" + (serviceZone ?? +"")}
          >
            <IceErrorTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/coffee-error"
        element={
          <ErrorBoundary
            fallback={Fallback}
            key={"coffee-error" + (serviceZone ?? +"")}
          >
            <CoffeeErrorTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/ice-rate-low"
        element={
          <ErrorBoundary
            fallback={Fallback}
            key={"ice-rate-low" + (serviceZone ?? +"")}
          >
            <IceRateLowTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/soda-error"
        element={
          <ErrorBoundary
            fallback={Fallback}
            key={"soda-error" + (serviceZone ?? +"")}
          >
            <SodaErrorTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/whipper"
        element={
          <ErrorBoundary
            fallback={Fallback}
            key={"whipper" + (serviceZone ?? +"")}
          >
            <WhipperTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/spiral-unsellable"
        element={
          <ErrorBoundary
            fallback={Fallback}
            key={"spiral-unsellable" + (serviceZone ?? +"")}
          >
            <SpiralUnsellableTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="*"
        element={
          <Navigate
            to={routes.majorTrouble.machineUnsellable + search}
            replace
          />
        }
      />
    </Routes>
  );
}
