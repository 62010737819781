import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import MasterInventoryList from "./screens/MasterInventoryList";
import Warehouses from "./screens/Warehouses";

export function Warehousing() {
  return (
    <>
      <div
        style={{
          margin: "-20px -35px",
          flex: "1 1 0",
          minHeight: 0,
        }}
      >
        <Routes>
          <Route path="/warehouses/*" element={<Warehouses />} />

          <Route path="/master-inventory-list/*" element={<MasterInventoryList />} />
          <Route path="*" element={<Navigate to="/warehousing/warehouses" />} />
        </Routes>
      </div>
    </>
  );
}
