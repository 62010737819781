import * as React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { routes } from "../../../../routes";
import { useServiceZoneQueryParam } from "../../hooks/useServiceZoneQueryParam";
import { ErrorBoundary } from "@sentry/react";
import { MilkTable } from "./MilkTable";
import { CoffeeBeansTable } from "./CoffeeBeansTable";
import { Fallback } from "../../components/Fallback";
import { SugarTable } from "./SugarTable";
import { LidsTable } from "./LidsTable";
import { OtherTable } from "./OtherTable";
import { PlasticCupsTable } from "./PlasticCupsTable";
import { EspressoCupsTable } from "./EspressoCupsTable";
import { PaperCupsTable } from "./PaperCupsTable";
import { CO2OutTable } from "./CO2OutTable";

export function InventoryTables() {
  const { search } = useLocation();
  const [serviceZone] = useServiceZoneQueryParam();

  return (
    <Routes>
      <Route
        path="/milk"
        element={
          <ErrorBoundary fallback={Fallback} key={"milk" + (serviceZone ?? +"")}>
            <MilkTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/sugar"
        element={
          <ErrorBoundary fallback={Fallback} key={"sugar" + (serviceZone ?? +"")}>
            <SugarTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/coffee-beans"
        element={
          <ErrorBoundary fallback={Fallback} key={"coffee-beans" + (serviceZone ?? +"")}>
            <CoffeeBeansTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/plastic-cups"
        element={
          <ErrorBoundary fallback={Fallback} key={"plastic-cups" + (serviceZone ?? +"")}>
            <PlasticCupsTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/espresso-cups"
        element={
          <ErrorBoundary fallback={Fallback} key={"espresso-cups" + (serviceZone ?? +"")}>
            <EspressoCupsTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/paper-cups"
        element={
          <ErrorBoundary fallback={Fallback} key={"paper-cups" + (serviceZone ?? +"")}>
            <PaperCupsTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/lid"
        element={
          <ErrorBoundary fallback={Fallback} key={"lid" + (serviceZone ?? +"")}>
            <LidsTable />
          </ErrorBoundary>
        }
      />
      <Route 
        path="/co2"
        element={
        <ErrorBoundary fallback={Fallback} key={"co2" + (serviceZone ?? +"")}>
          <CO2OutTable />
        </ErrorBoundary>
      }/>
      <Route
        path="/other"
        element={
          <ErrorBoundary fallback={Fallback} key={"other" + (serviceZone ?? +"")}>
            <OtherTable />
          </ErrorBoundary>
        }
      />
      <Route path="*" element={<Navigate to={routes.inventory.milk + search} replace />} />
    </Routes>
  );
}
