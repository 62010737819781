import { useNavigate, useLocation } from "react-router-dom";
import { SERVICE_ZONE_QUERY_PARAM } from "../../../routes";
import { useMemo } from "react";

export function useServiceZoneQueryParam() {
  const location = useLocation();
  const serviceZoneIds = new URLSearchParams(location.search).getAll(
    SERVICE_ZONE_QUERY_PARAM
  );
  const sortedIds = [...serviceZoneIds].sort().join("");

  const serviceZone = useMemo(() => {
    let serviceZone = serviceZoneIds;
    if (serviceZoneIds.length === 0) {
      serviceZone = null;
    }
    return serviceZone;
  }, [sortedIds]);

  const navigate = useNavigate();

  function setServiceZone(id: string[] | null) {
    if (id !== null && id.length > 0) {
      const newSearchParams = new URLSearchParams();
      id.forEach((zoneId) =>
        newSearchParams.append(SERVICE_ZONE_QUERY_PARAM, zoneId)
      );
      navigate({
        search: newSearchParams.toString(),
      });
    } else {
      navigate({
        search: "",
      });
    }
  }

  return [serviceZone, setServiceZone] as const;
}
