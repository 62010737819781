import * as React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { MachineTicket } from './MachineTicket';
import NoPermissionComponent from 'containers/noPermissionPage/NoPermission';
import {
  useIsAdmin,
  useIsAdminContract,
  useIsClerk,
  useIsOperator,
  useIsRouteman,
  useIsTechnician,
} from 'utils/user-role';

export function Service(): JSX.Element {
  const canViewContent =
    useIsAdmin() || useIsAdminContract() || useIsOperator() || useIsClerk() || useIsTechnician() || useIsRouteman();

  if (canViewContent === false) {
    return <NoPermissionComponent />;
  }
  return (
    <Routes>
      <Route path="/machine-ticket/*" element={<MachineTicket />} />
      <Route path="*" element={<Navigate to="/service/machine-ticket/newticket" />} />
    </Routes>
  );
}
