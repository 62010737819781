import * as React from "react";
import { useParams } from "react-router-dom";
import * as moment from "moment";

// Component imports
import Table, { IColumn } from "common/components/table/Table";
import Typography from "common/components/typography/Typography";
import { DateRangePicker } from "components/DateRangePicker";

import { PaginationAction, useCashEventsQuery } from "gql/generated";
import { TimestampToISO } from "utils/timestampToISO";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "utils/currency";

const PAGE_LIMIT = 20;

function CashEvent(): JSX.Element {
  const { t } = useTranslation();
  const { machineId } = useParams();
  const [startDate, setStartDate] = React.useState<number | null>(null);
  const [endDate, setEndDate] = React.useState<number | null>(null);
  const [pagination, setPagination] = React.useState<{
    cursor: string;
    action: PaginationAction;
  } | null>(null);
  const { data, isLoading } = useCashEventsQuery(
    {
      filter: {
        machineId,
        startTimestamp: startDate ? TimestampToISO(startDate) : undefined,
        endTimestamp: endDate ? TimestampToISO(endDate) : undefined,
      },
      pagination: {
        limit: PAGE_LIMIT,
        cursor: pagination?.cursor || undefined,
        action: pagination?.action || undefined,
      },
    }
  );

  const formattedData = React.useMemo(() => {
    return data?.cashEvents?.edges?.map(({ node }) => {
  
      return {
        collectionDate: formatDate(node?.time),
        warehouseName: node?.location?.__typename === "Location" || node?.location?.__typename === "Store" ? node?.location?.warehouse?.name : "-",
        locationName: node?.location?.locationName,
        zone: node?.location?.__typename === "Location" || node?.location?.__typename === "Store" ? node?.location?.refillZone?.name : "-",
        totalCash: node?.totalCash,
        collectedBy: node?.username,
      };
    });
  }, [data]);

  return (
    <div className="flex flex-col flex-1 min-h-0">
      <div className="flex mb-5">
        <div className="flex-1">
          <div className="">
            <Typography type="headline-5" translationKey="menu_cash" />
          </div>
        </div>
        <div className="">
          <DateRangePicker
            key="cash-event-date-filter"
            maxFutureDays={Date.now()}
            onBlur={(e) => {
              setStartDate(e.startDate);
              setEndDate(e.endDate);
              setPagination(null);
            }}
          />
        </div>
      </div>

      <div>
        <Table
          infiniteScroll={false}
          data={formattedData}
          loading={isLoading}
          loadingRows={20}
          columns={columnsConfig}
        />
      </div>
      <div className="flex justify-between mt-4">
        <Button
          size="small"
          type="secondary"
          disabled={!data?.cashEvents?.pageInfo?.hasPreviousPage}
          onClick={() => {
            setPagination({
              action: PaginationAction.Backward,
              cursor: data?.cashEvents?.pageInfo?.startCursor,
            });
          }}
        >
          {t("label_previous")}
        </Button>
        <Button
          size="small"
          type="secondary"
          disabled={!data?.cashEvents?.pageInfo?.hasNextPage}
          onClick={() => {
            setPagination({
              action: PaginationAction.Forward,
              cursor: data?.cashEvents?.pageInfo?.endCursor,
            });
          }}
        >
          {t("label_next")}
        </Button>
      </div>
    </div>
  );
}

const TextField = ({
  children,
  className = "",
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <p className={["text-body2 text-on-surface-high", className].join(" ")}>
    {children}
  </p>
);

const columnsConfig: IColumn[] = [
  {
    dataKey: "collectionDate",
    cellRenderer: (data: string) => <TextField>{data}</TextField>,
    sortable: false,
    showMobile: true,
    columnFlex: 1,
    columnWidth: 140,
    maxWidth: 140,
    headerLabel: "label_collection_date",
  },
  {
    dataKey: "locationName",
    cellRenderer: (data: string) => <TextField>{data}</TextField>,
    sortable: false,
    showMobile: true,
    columnFlex: 2,
    minWidth: 200,
    headerLabel: "label_location_name",
  },
  {
    dataKey: "warehouseName",
    cellRenderer: (data: string) => <TextField>{data}</TextField>,
    sortable: false,
    showMobile: true,
    columnFlex: 0.5,
    columnWidth: 80,
    maxWidth: 80,
    headerLabel: "label_warehouse",
  },
  {
    dataKey: "zone",
    cellRenderer: (data: string) => <TextField>{data}</TextField>,
    sortable: false,
    showMobile: true,
    columnFlex: 0.5,
    columnWidth: 80,
    maxWidth: 80,
    headerLabel: "label_zone",
  },
  {
    dataKey: "totalCash",
    cellRenderer: (data: string) => (
      <TextField className="!font-bold">{formatCurrency({ input: data })}</TextField>
    ),
    align: "flex-end",
    sortable: false,
    showMobile: true,
    columnFlex: 1,
    columnWidth: 140,
    maxWidth: 140,
    headerLabel: "label_collected_amount",
    headerClassName: "!justify-start"
  },
  {
    dataKey: "collectedBy",
    cellRenderer: (data: string) => <TextField>{data}</TextField>,
    sortable: false,
    showMobile: true,
    columnFlex: 1,
    minWidth: 50,
    headerLabel: "label_collected_by",
  },
];

export function formatDate(timeStamp: string) {
  return moment(timeStamp).format("MMM D, YYYY H:mm");
}

export function timeStampToDate(timeStamp: string) {
  return moment(timeStamp).format("MMM D, YYYY H:mm");
}

export default CashEvent;
