import * as React from 'react';
import { DrinkCategory, useGetDailyDrinkTypeSalesQuery } from 'gql/generated';
import { Card } from '../atoms/Card';
import { PieChart } from '../atoms/charts/PieChart';
import { ErrorFallBack } from '../atoms/ErrorFallback';
import { CardTitle } from '../molecules/CardTitle';
import { PieChartKey } from '../molecules/PieChartKey';
import Select from 'components/Select';
import { Skeleton } from '@mui/material';

type FilterValue = 'ALL' | 'COFFEE' | 'TEA' | 'MILK';

const options: Array<{ value: FilterValue; label: string }> = [
  { value: 'ALL', label: 'All' },
  { value: 'COFFEE', label: 'Coffee' },
  { value: 'TEA', label: 'Tea' },
  { value: 'MILK', label: 'Milk' },
];

const FILTER_VALUE_TO_DRINK_FAILURE_TYPE: Record<FilterValue, DrinkCategory> = {
  ALL: null,
  COFFEE: DrinkCategory.Coffee,
  TEA: DrinkCategory.Tea,
  MILK: DrinkCategory.Milk,
};

export function BestSellingDrinkType() {
  const [filter, setFilter] = React.useState<FilterValue>('ALL');
  const { isError, data, isLoading } = useGetDailyDrinkTypeSalesQuery(
    {
      filter: {
        category: FILTER_VALUE_TO_DRINK_FAILURE_TYPE[filter],
      },
    },
    {
      useErrorBoundary: false,
    }
  );

  const pieChartData = data?.dailyDrinkTypeSales
    .map((drink) => {
      const segmentProps = DRINK_TYPE_TO_SEGMENT_PROPS[drink.drinkType];
      return {
        label: segmentProps.label,
        amount: drink.totalCount,
        color: segmentProps.color,
        borderColor: segmentProps.borderColor,
      };
    })
    .sort((a, b) => b.amount - a.amount);

  const total = pieChartData?.reduce((acc, segment) => acc + segment.amount, 0);
  const pieChartLegend = pieChartData?.map((segment) => ({
    label: segment.label,
    value: ((segment.amount * 100) / total).toFixed(1),
    color: segment.borderColor,
  }));

  return (
    <Card>
      <div className="flex justify-between items-center">
        <CardTitle
          icon="Flame"
          title="Best-Selling Drink Type"
          className={isError ? '!text-on-surface-disabled grow' : 'grow'}
        />
        <div className="w-24 flex items-center justify-center h-0">
          <Select
            label=""
            id="type"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            options={options}
            size="small"
          />
        </div>
      </div>

      {isLoading ? (
        <Skeleton
          height={160}
          className="mt-auto"
          style={{ transform: 'scale(1,1)' }}
        />
      ) : isError ? (
        <ErrorFallBack />
      ) : (
        <div className="mt-auto flex justify-between items-center">
          <PieChart data={pieChartData} />
          <div className="min-w-36 flex flex-col gap-[6px]">
            {pieChartLegend?.map((legend) => (
              <PieChartKey
                key={legend.label}
                value={legend.value}
                label={legend.label}
                color={legend.color}
              />
            ))}
          </div>
        </div>
      )}
    </Card>
  );
}

const DRINK_TYPE_TO_SEGMENT_PROPS = {
  ice: {
    label: 'Iced',
    color: '#BDD6E9',
    borderColor: '#B5C7D5',
  },
  hot: {
    label: 'Hot',
    color: '#F9D0D0',
    borderColor: '#F18989',
  },
  smoothie: {
    label: 'Smoothie',
    color: '#C3D1E7',
    borderColor: '#698CC2',
  },
};
