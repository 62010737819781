import * as React from "react";

// component
import UserSettings from "./component/UserSetting/UserSettings";
import Team from "./component/Team/Team";
import Organisation from "./component/Organisation/Organisation";

import * as styles from "./Settings.module.scss";
import { useIsAdmin, useIsAdminContract } from "utils/user-role";
import { Route, Navigate, Routes } from "react-router-dom";
import { ChangePassword } from "./component/ChangePassword";

function Settings(): JSX.Element {
  const isUserAdmin = useIsAdmin() || useIsAdminContract();

  return (
    <div className={styles.settings}>
      <Routes>
        <Route path="/usersetting" element={<UserSettings />} />

        <Route path="/change-password" element={<ChangePassword />} />

        {isUserAdmin && (
          <Route path="/organisation" element={<Organisation />} />
        )}
        {isUserAdmin && <Route path="/team" element={<Team />} />}
        <Route path="*" element={<Navigate to="/settings/usersetting" />} />
      </Routes>
    </div>
  );
}

export default Settings;
