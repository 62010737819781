import * as React from "react";

import { Card } from "../atoms/Card";
import { CardAggregate } from "../molecules/CardAggregate";
import { CardTitle } from "../molecules/CardTitle";
import { LineGraph } from "../atoms/charts/LineGraph";

import Select from "components/Select";

import { Skeleton } from "@mui/material";
import {
  DrinkFailureType,
  useGetDrinkFailureOverviewQuery,
} from "gql/generated";
import { ErrorFallBack } from "../atoms/ErrorFallback";

type FilterValue =
  | "ALL"
  | "SOFTWARE_HARDWARE"
  | "INVENTORY"
  | "SERVER"
  | "SOFTWARE_HARDWARE_INVENTORY"
  | "PAYMENT";

const options: Array<{ value: FilterValue; label: string }> = [
  { value: "ALL", label: "All" },
  { value: "SOFTWARE_HARDWARE", label: "SW/HW" },
  { value: "INVENTORY", label: "Inventory" },
  { value: "SOFTWARE_HARDWARE_INVENTORY", label: "SW/HW + Inventory" },
  { value: "SERVER", label: "Server" },
  { value: "PAYMENT", label: "Payment" },
];

const FILTER_VALUE_TO_DRINK_FAILURE_TYPE: Record<
  FilterValue,
  DrinkFailureType
> = {
  ALL: null,
  SOFTWARE_HARDWARE: DrinkFailureType.SoftwareHardware,
  INVENTORY: DrinkFailureType.Inventory,
  SERVER: DrinkFailureType.Server,
  SOFTWARE_HARDWARE_INVENTORY: DrinkFailureType.SoftwareHardwareInventory,
  PAYMENT: DrinkFailureType.Payment,
};

export function DrinkFailCard() {
  const [filter, setFilter] = React.useState<FilterValue>("ALL");
  const useGetDrinkFailureOverviewResult = useGetDrinkFailureOverviewQuery(
    {
      failureType: FILTER_VALUE_TO_DRINK_FAILURE_TYPE[filter],
    },
    {
      useErrorBoundary: false,
    }
  );

  return (
    <Card className="flex flex-col">
      <div className="flex justify-between items-center">
        <CardTitle
          icon={"Error"}
          title={"Drink fail"}
          className={
            useGetDrinkFailureOverviewResult.isError
              ? "text-on-surface-disabled grow"
              : "grow"
          }
        />
        <div className="w-48 flex items-center justify-center h-0">
          <Select
            label=""
            id="type"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            options={options}
            size="small"
          />
        </div>
      </div>
      <div className="grid grid-cols-2 mt-[9px]">
        <CardAggregate
          header="Error Rate Today"
          type="numeric"
          value={
            useGetDrinkFailureOverviewResult.isError ||
            useGetDrinkFailureOverviewResult.isLoading
              ? undefined
              : useGetDrinkFailureOverviewResult.data?.drinkFailureOverview.todaySummary.failurePercentage.toFixed(
                  2
                ) + "%"
          }
          isLoading={useGetDrinkFailureOverviewResult.isLoading}
        />
        <CardAggregate
          header="Fail Cup Today"
          type="numeric"
          value={
            useGetDrinkFailureOverviewResult.isError ||
            useGetDrinkFailureOverviewResult.isLoading
              ? undefined
              : String(
                  useGetDrinkFailureOverviewResult.data.drinkFailureOverview
                    .todaySummary.failureCount
                )
          }
          isLoading={useGetDrinkFailureOverviewResult.isLoading}
        />
      </div>
      {useGetDrinkFailureOverviewResult.isLoading ? (
        <Skeleton
          height={80}
          className="mt-auto"
          style={{ transform: "scale(1,1)" }}
        />
      ) : useGetDrinkFailureOverviewResult.isError ? (
        <ErrorFallBack />
      ) : (
        <LineGraph
          data={useGetDrinkFailureOverviewResult.data?.drinkFailureOverview.last30DaysSummaries.map(
            (summary, index) => [index, summary.failurePercentage]
          )}
          labels={useGetDrinkFailureOverviewResult.data?.drinkFailureOverview.last30DaysSummaries.map(
            (summary) => [
              summary.recordedDate,
              `${summary.failurePercentage.toFixed(2)}%`,
              `${summary.failureCount} of ${summary.totalCount}`,
            ]
          )}
          scales={{
            xAxis: {
              min: 0,
              max: 31,
            },
          }}
          className="mt-auto"
          color={"#E95D50"}
        />
      )}
    </Card>
  );
}
