import { RentFee } from "containers/contract/hooks/useGetLocationFee";
import { GetLocationByIdQuery, ServiceFeeType } from "gql/generated";
import * as moment from 'moment';

export function mapDataToRentFeeType (rentFee: GetLocationByIdQuery["locationAggregate"]["rentFee"]): RentFee {
  if(!rentFee) {
    return {
      type: "FIXED",
      value: "",
      payWithCode: false
    };
  }

  if(rentFee.type === ServiceFeeType.FixedFee) {
    return {
      type: "FIXED",
      value: rentFee.fixedFee.toString(),
      payWithCode: rentFee.payWithCode,
    };
  }

  if(rentFee.type === ServiceFeeType.PercentageFee) {
    return {
      type: "PERCENTAGE",
      value: {
        minimumAmount: rentFee.percentageFee.minimumAmount.toString(),
        segments: rentFee.percentageFee.ladderPercentages.map(ladderPercentage => ({
          lower: ladderPercentage.lowerSales.toString(),
          upper: ladderPercentage.upperSales.toString(),
          percentage: ladderPercentage.percentage?.toString() || "0",
        }))
      },
      payWithCode: rentFee.payWithCode
    };
  }

  if(rentFee.type === ServiceFeeType.Steps) {
    return {
      type: "LADDER",
      value: rentFee.steps.map(step => ({
        from: moment(step.period.start),
        to: moment(step.period.end),
        rentalPrice: step.stepFee.amount.toString()
      })),
      payWithCode: rentFee.payWithCode
    };
  }
}
