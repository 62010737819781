import TextButton from "common/components/textbutton/TextButton";

import Button from "components/Button/Button";
import ResponsiveModal, { Title } from "components/ResponsiveModal";

import { useTranslation } from "react-i18next";
import {
  ContractDetails,
  contractByIdCache,
} from "containers/contract/hooks/useGetContractById";
import Typography from "components/Typography/Typography";

import TextField from "components/TextField";

import DatePicker from "components/DatePicker";
import { useNavigate } from "react-router-dom";
import { toasti18n } from "utils/toast";
import { contractRoutes } from "containers/contract/routes";
import { useDuplicateContractMutation } from "gql/generated";

import * as React from "react";
import * as moment from "moment";

export function DuplicateContractButton({
  contract,
}: {
  contract: ContractDetails;
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      <TextButton
        icon="CopySquare"
        translationKey="label_make_a_copy"
        onClick={() => setIsOpen(true)}
      />
      {isOpen ? (
        <DuplicateContractModal
          contract={contract}
          onClose={() => setIsOpen(false)}
        />
      ) : null}
    </>
  );
}

function DuplicateContractModal(props: {
  contract: ContractDetails;
  onClose: () => void;
}) {
  const navigate = useNavigate();

  const { mutate, isLoading } = useDuplicateContractMutation({
    onError: (err: Error) => {
      toasti18n.error(err);
    },
    onSuccess: async (data) => {
      toasti18n.success();
      const newContractId = data.duplicateContract;

      contractByIdCache.duplicateContract({
        contractId: props.contract.general.contractId,
        newContractId: newContractId,
        newContractFriendlyId: newContractFriendlyId,
        newContractPeriod: {
          endDate: contractEndDate,
          startDate: contractStartDate,
        },
      });
      navigate(contractRoutes.contractId(newContractId));
    },
  });

  function submit() {
    mutate({
      input: {
        id: props.contract.general.contractId,
        newFriendlyId: newContractFriendlyId,
        newContractPeriod: {
          end: contractEndDate.format("YYYY-MM-DD"),
          start: contractStartDate.format("YYYY-MM-DD"),
        },
      },
    });
  }

  const { t } = useTranslation();

  const [newContractFriendlyId, setNewContractFriendlyId] = React.useState("");
  const [contractStartDate, setContractStartDate] = React.useState(null);
  const [contractEndDate, setContractEndDate] = React.useState(null);

  const oneDayAfterContractEndDate = moment(
    props.contract.general.contractEndDate
  ).add(1, "d");

  return (
    <ResponsiveModal
      onClose={props.onClose}
      open={true}
      modalStyles={{
        maxWidth: "466px",
        heightStyles: {
          flex: "0 0 auto",
        },
      }}
    >
      <Title>{`${t("label_create_a_new_contract_from_contract")} ${
        props.contract.general.contractFriendlyId
      }`}</Title>
      <div style={{ height: "14px" }}></div>

      <TextField
        required
        label="label_new_contract_id"
        value={newContractFriendlyId}
        onChange={(e) => {
          setNewContractFriendlyId(e.target.value);
        }}
      />
      <div style={{ height: "21px" }}></div>

      <Typography type="subtitle-1">
        {t(
          "label_select_new_contract_period_that_doesnt_overlap_with_the_original_contract"
        )}
      </Typography>

      <Typography type="body-3">
        {`${t(
          "label_the_original_contract_duration_is"
        )} ${props.contract.general.contractStartDate.format(
          "DD/MM/YYYY"
        )}  -  ${props.contract.general.contractEndDate.format("DD/MM/YYYY")}`}
      </Typography>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, minmax(0,1fr))",
          gap: "14px",
          marginTop: "15px",
        }}
      >
        <DatePicker
          label="label_from"
          shouldDisableDate={(date) => {
            return date.isBefore(oneDayAfterContractEndDate);
          }}
          defaultCalendarMonth={oneDayAfterContractEndDate}
          required
          value={contractStartDate}
          onChange={(newValue) => setContractStartDate(newValue)}
        />

        <DatePicker
          label="label_to"
          shouldDisableDate={(date) =>
            date.isBefore(oneDayAfterContractEndDate) ||
            date.isBefore(contractStartDate)
          }
          defaultCalendarMonth={
            contractStartDate || oneDayAfterContractEndDate
          }
          required
          value={contractEndDate}
          onChange={(newValue) => setContractEndDate(newValue)}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "29px",
        }}
      >
        <Button type="secondary" onClick={props.onClose} disabled={isLoading}>
          action_cancel
        </Button>
        <Button type="primary" onClick={submit} loading={isLoading}>
          action_create
        </Button>
      </div>
    </ResponsiveModal>
  );
}
