import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, useMatch } from "react-router-dom";
import { getServiceZoneQueryParam, routes } from "../../routes";
import { useErrorBoard } from "./hooks/useErrorBoard";

import * as styles from "./ErrorBoardNav.module.scss";
import { useServiceZoneQueryParam } from "./hooks/useServiceZoneQueryParam";

function sumObjectValues(obj: Record<string, number>) {
  return Object.values(obj).reduce((partialSum, a) => partialSum + a, 0);
}

export function ErrorBoardTabs() {
  const { t } = useTranslation("errorBoard");

  const { state } = useErrorBoard();
  const { summary } = state.data;

  const majorTroubleAmount = summary?.majorTrouble
    ? sumObjectValues(summary.majorTrouble)
    : 0;
  const materialsAmount = summary?.inventory
    ? sumObjectValues(summary.inventory)
    : 0;
  const transactionsAmount = summary?.transactions
    ? sumObjectValues(summary.transactions)
    : 0;

  return (
    <div className={styles.ErrorBoardNav}>
      <TabButton
        path={routes.majorTrouble.machineUnsellable}
        activePath={`${routes.majorTrouble.root}/*`}
        amount={majorTroubleAmount}
      >
        {t("majorTrouble")}
      </TabButton>
      <TabButton
        path={routes.inventory.milk}
        activePath={`${routes.inventory.root}/*`}
        amount={materialsAmount}
      >
        {t("inventory")}
      </TabButton>
      <TabButton
        path={routes.transactions.aaNoTransaction30Mins}
        activePath={`${routes.transactions.root}/*`}
        amount={transactionsAmount}
      >
        {t("transactionTx")}
      </TabButton>
    </div>
  );
}

function TabButton({
  amount = 0,
  children,
  activePath,
  path,
}: {
  amount?: number;
  children: React.ReactNode;
  path: string;
  activePath: string;
}) {
  const [zones] = useServiceZoneQueryParam();
  const search = getServiceZoneQueryParam(zones);
  const match = useMatch(activePath);
  const [isHovered, setIsHovered] = React.useState(false);
  return (
    <Link
      to={path + search}
      className={styles.NavLink}
      style={{
        backgroundColor: match ? "#2C2E78" : isHovered ? "#eeeef7" : "white",
        color: match ? "white" : "#2C2E78",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
      {amount > 0 ? (
        <span className={styles.Amount}>{amount > 99 ? "99+" : amount}</span>
      ) : null}
    </Link>
  );
}
