import * as React from 'react';
import { useNavigate } from 'react-router-dom';

// API
import { useGetMachinesWithExtraFields } from 'machines/hooks/useGetMachinesWithExtraFields';
// Hooks
import useQueryFilter from 'common/hooks/useQueryFilter';

import FilterBar, {
  IFilterMenuItem,
} from 'common/components/filterbar/FilterBar';
import Table, { IColumn } from 'common/components/table/Table';
import { StringCell, StatusCell } from 'common/components/table/cells/Cells';

import { IMachineDataWithExtraFields } from 'machines/interfaces';

import * as styles from './StandbyMachine.module.scss';
import { useIsAdmin, useIsAdminContract, useIsOperator, useIsSuperViewer } from 'utils/user-role';
import NoPermissionComponent from 'containers/noPermissionPage/NoPermission';

function StandbyMachine(): JSX.Element {
  const canViewContent = useIsAdmin() || useIsAdminContract() || useIsOperator() || useIsSuperViewer();
  if (canViewContent === false) {
    return <NoPermissionComponent />;
  }
  const { data: machineDataWithExtraFields = [], isLoading } =
    useGetMachinesWithExtraFields();
  const tableData = getTableDataMapped(machineDataWithExtraFields);
  const navigate = useNavigate();

  const filtered = useQueryFilter(tableData);

  const columns: IColumn[] = [
    {
      dataKey: 'machine',
      headerLabel: 'label_machine_id',
      cellRenderer: StringCell,
      align: 'flex-start',
      columnFlex: 0.8,
      showMobile: true,
      sortable: true,
    },
    {
      dataKey: 'deployed',
      cellRenderer: StatusCell,
      headerLabel: 'label_machine_state',
      columnFlex: 1,
      align: 'flex-start',
      showMobile: true,
    },
    {
      dataKey: 'organisation',
      cellRenderer: StringCell,
      headerLabel: 'label_organisation',
      columnFlex: 1,
      align: 'flex-start',
      showMobile: true,
      sortable: true,
    },
    {
      dataKey: 'name',
      cellRenderer: StringCell,
      headerLabel: 'label_name',
      columnFlex: 1.5,
      align: 'flex-start',
      showMobile: true,
    },
    {
      dataKey: 'note',
      headerLabel: 'label_notes',
      cellRenderer: StringCell,
      align: 'flex-start',
      columnFlex: 5,
      showMobile: true,
    },
  ];

  const filterbarMenu: IFilterMenuItem[] = [
    {
      title: 'label_name',
      queryKey: 'name',
      type: 'contains',
    },
    {
      queryKey: 'machine',
      title: 'label_machine',
      type: 'contains',
    },
    {
      queryKey: 'organisation',
      title: 'label_organisation',
      type: 'contains',
    },
    {
      queryKey: 'note',
      title: 'label_notes',
      type: 'contains',
    },
  ];

  function handleRowClick(rowData: any) {
    navigate(`/machines/detail/${rowData.machine}/inventory`);
  }

  return (
    <div className={styles.StandbyMachine}>
      <FilterBar menu={filterbarMenu} />
      <Table
        className={styles.table}
        data={filtered}
        loading={isLoading || filtered === undefined}
        onRowClick={handleRowClick}
        defaultSortDirection="asc"
        columns={columns}
        defaultSortColumn="machine"
      />
    </div>
  );
}

export default StandbyMachine;

function getTableDataMapped(
  machinesData: IMachineDataWithExtraFields[]
): Array<IStandbyTableData> {
  const mappedTableData = machinesData
    ?.filter((eachMachine) => eachMachine.deployed === false)
    .map((eachMachine) => ({
      machine: eachMachine.id,
      deployed: 'not_deployed',
      organisation: eachMachine.organisationName,
      name: eachMachine.name,
      note: eachMachine.additional?.notes,
    }));
  return mappedTableData;
}

interface IStandbyTableData {
  machine: string;
  deployed: string;
  organisation: string;
  name: string;
  note: string;
}
