import * as React from "react";
import { KittingModalState } from "./useKittingModalState";
import { useTranslation } from "react-i18next";
import * as styles from "./KittingModal.module.scss";
import { MachineKind } from "gql/generated";
import Typography from "components/Typography";
import Icon from "common/components/icon/Icon";
import { Link } from "react-router-dom";
import { clsx } from "clsx";
import * as moment from "moment";
import { InventoryTable } from "./InventoryTable";
import { Tooltip } from "@mui/material";

export function KittingTable({
  kittingModalState,
  selectedMachineId,
  onMachineRowSelect,
}: {
  kittingModalState: KittingModalState;
  selectedMachineId: string;
  onMachineRowSelect: (machineId: string) => void;
}) {
  const { t } = useTranslation("operation", {
    keyPrefix: "kitting"
  });
  const selectedMachine = kittingModalState.machines[selectedMachineId];
  const { machineValidity } = kittingModalState.form;

  const spiralMachineData = React.useMemo(() => {
    return selectedMachineId
      ? Object.values(kittingModalState.machines).filter(
          (machine) =>
            machine.parentId === selectedMachineId &&
            machine.kind === MachineKind.SpiralVending
        )
      : [];
  }, [selectedMachineId]);

  return (
    <div className={styles.KittingTableContainer}>
      <div className={styles.MachineIdContainer}>
        <div className={styles.Row}>
          <div className={styles.OrderColumn}>
            <Typography
              translate
              type="body-2"
              color="brainStormingBlackTint300"
            >
              menu_order
            </Typography>
          </div>
          <div className={styles.MachineIdColumn}>
            <Typography
              className="flex-1"
              type="body-2"
              translate
              color="brainStormingBlackTint300"
            >
              label_machine_id
            </Typography>
          </div>
        </div>
        <div className={styles.MachineIdRowsContainer}>
          {Object.values(kittingModalState.machines)
            .sort(
              (machineA, machineB) =>
                machineA.positionInPlan - machineB.positionInPlan
            )
            .map((machine) => (
              <div
                key={machine.machineId}
                className={
                  selectedMachineId === machine.machineId
                    ? styles.RowSelectableSelected
                    : machineValidity[machine.machineId] === "WARN"
                      ? styles.RowSelectableWarning
                      : machineValidity[machine.machineId] === "INVALID"
                        ? styles.RowSelectableError
                        : styles.RowSelectable
                }
                onClick={() => onMachineRowSelect(machine.machineId)}
              >
                <div className={styles.OrderColumn}>
                  <div className={styles.OrderCell}>
                    <Typography type="body-2" color="onSurfaceHigh">
                      {machine.positionInPlan}
                    </Typography>

                    {machine.refillOrder.critical ? (
                      <div className={styles.CriticalIcon}>
                        <Icon name="PriorityMajor" />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className={styles.MachineIdColumn}>
                  <div className="flex flex-1 items-center">
                    <div className="flex-1">
                      {machine.kind === MachineKind.SpiralVending ? (
                        <div>
                          <Typography
                            type="body-2"
                            translate
                            color="onSurfaceHigh"
                            className="leading-5"
                          >
                            {machine.machineId}
                          </Typography>
                          <p className="font-kanit text-caption leading-4 text-on-surface-disabled">
                            {machine?.parentId}
                          </p>
                        </div>
                      ) : (
                        <Typography
                          type="body-2"
                          translate
                          color="onSurfaceHigh"
                        >
                          {machine.machineId}
                        </Typography>
                      )}
                    </div>
                    <div className="w-20 flex justify-center">
                      {machine.kind === MachineKind.SpiralVending && (
                        <Icon name="Snack" color="primary" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className={styles.KittingArea}>
        <div
          className={clsx(
            styles.KittingAreaHeader,
            "!h-auto !block py-2 space-y-2"
          )}
        >
          <div className="flex gap-1">
            <div style={{ flexShrink: 0 }}>
              <Typography type="headline-6">
                {selectedMachine.machineId}
              </Typography>
            </div>

            <Typography type="headline-6">
              {selectedMachine.location.name}
            </Typography>
            {selectedMachine.tier && (
              
              <Tooltip placement="top"
              title={
                t("label_tooltip_tier", { day: selectedMachine.tier })
              }>
                <div className="px-2 py-1 rounded-full bg-primary text-white text-body2 font-bold whitespace-nowrap">
                  {t("label_tier")} {selectedMachine.tier}
                </div>
              </Tooltip>
            )}
            <div style={{ marginLeft: "auto", flexShrink: 0 }}>
              <Typography type="body-2" translate>
                {
                  kittingModalState.aggregates.numberOfSelectedSlots[
                    selectedMachineId
                  ]
                }
                {` `}
                label_selected
              </Typography>
            </div>
          </div>
          {selectedMachine.kind === MachineKind.BeverageVending &&
            spiralMachineData.length > 0 && (
              <div className="flex items-center gap-1">
                <Icon name="Snack" color="primary" />
                <Typography type="body-2" color="onSurfaceMedium">
                  {spiralMachineData?.length}
                </Typography>
                <Typography type="body-2" color="onSurfaceMedium" translate>
                  label_spiral_machine
                </Typography>
                <div>
                  {spiralMachineData?.map((spiralMachine) => (
                    <Link
                      target="_blank"
                      to={`/machines/detail/${spiralMachine?.machineId}`}
                      key={spiralMachine?.machineId}
                      className="font-medium underline text-body2 text-primary"
                    >
                      {spiralMachine?.machineId}
                    </Link>
                  ))}
                </div>
              </div>
            )}
          {selectedMachine.kind === MachineKind.SpiralVending && (
            <div className="flex items-center gap-1">
              <Icon name="Machine" color="primary" />
              <p className="text-body2 text-on-surface-medium">
                {t("label_taobin_machine")} :{" "}
              </p>
              <Link
                target="_blank"
                to={`/machines/detail/${selectedMachine?.parentId}`}
                className="font-medium underline text-body2 text-primary"
              >
                {selectedMachine?.parentId}
              </Link>
            </div>
          )}
        </div>
        <div className={styles.KittingTableScrollArea}>
          <div className={styles.KittingTableMinContentWrapper}>
            <InventoryTable
              isForecastingDisabled={kittingModalState.plan.isRoutingSkipped}
              inventory={selectedMachine.inventory}
              inventoryValidity={
                kittingModalState.form.inventoryValidity[selectedMachineId]
              }
              state={kittingModalState}
              tier={selectedMachine.tier}
            />
            <div className={styles.NotesContainer}>
              <div className={styles.NoteContainer}>
                <Typography translate type="caption" color="onSurfaceMedium">
                  label_cash_status
                </Typography>
                <div className={styles.NoteItems}>
                  {selectedMachine.cashStatus.isCoinFull ? (
                    <Typography
                      type="subtitle-1"
                      translate
                      color="onSurfaceHigh"
                    >
                      label_coin_full
                    </Typography>
                  ) : null}
                  {selectedMachine.cashStatus.isBankNotesFull ? (
                    <Typography
                      type="subtitle-1"
                      translate
                      color="onSurfaceHigh"
                    >
                      label_bank_full
                    </Typography>
                  ) : null}
                </div>
              </div>

              <div className={styles.NoteContainer}>
                <Typography translate type="caption" color="onSurfaceMedium">
                  label_error_detail
                </Typography>
                <div className={styles.NoteItems}>
                  {selectedMachine.error.recentErrorDetails.map(
                    (errorDetail, index) => (
                      <div key={index} className={styles.ErrorDetail}>
                        <Typography type="subtitle-1" color="onSurfaceHigh">
                          {errorDetail.detail}
                        </Typography>
                        <Typography type="subtitle-1" color="onSurfaceHigh">
                          {" "}
                        </Typography>
                        <Typography type="body-3" color="onSurfaceDisabled">
                          {moment(Number(errorDetail.createdAt)).format(
                            "DD/MM/YYYY H:mm"
                          )}
                        </Typography>
                      </div>
                    )
                  )}
                </div>
              </div>

              <div className={styles.NoteContainer}>
                <Typography translate type="caption" color="onSurfaceMedium">
                  label_note
                </Typography>
                <div>
                  {selectedMachine.refillOrder.criticalNote ? (
                    <Typography type="subtitle-1" color="error">
                      {selectedMachine.refillOrder.criticalNote}
                    </Typography>
                  ) : null}
                  {selectedMachine.refillOrder.locationNote ? (
                    <Typography type="subtitle-1" color="onSurfacehigh">
                      {(selectedMachine.refillOrder.criticalNote ? ", " : "") +
                        (selectedMachine.refillOrder.locationNote ?? "")}
                    </Typography>
                  ) : null}
                </div>
              </div>
            </div>
            <div className={styles.KittingTableScrollAreaSpacer}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
